import ActionTypes from '../../constants/ActionTypes';

interface ReducerState {
	completed?: boolean;
	pending?: boolean;
}

export default function saveReducer(state: ReducerState = {}, action: any): ReducerState {
	switch (action.type) {
		case ActionTypes.ChangeLocation: {
			if (action.payload.location.pathname === '/editor') {
				return {
					...state,
					completed: false,
				};
			}
			return state;
		}
		case ActionTypes.SaveReportPending:
		case ActionTypes.InsertReportPending:
		case ActionTypes.UpdateReportPending:
			return {
				...state,
				pending: true,
			};
		case ActionTypes.SaveReportRejected:
		case ActionTypes.InsertReportRejected:
		case ActionTypes.UpdateReportRejected:
			return {
				...state,
				pending: false,
			};
		case ActionTypes.SaveReportFulfilled:
		case ActionTypes.InsertReportFulfilled:
		case ActionTypes.UpdateReportFulfilled:
			return {
				...state,
				pending: false,
				completed: true,
			};
		default:
			return state;
	}
}
