import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import ActionDropdown from '../../Dropdown';

import Checkbox from '../../../common/Checkbox';
import RadioButton from '../../../common/RadioButton';
import { FeaturePermission } from '../../../roles/createRole/models';
import { toTitleCase } from '../../../timeline/utils';

interface FeaturePermissionsProps {
	featurePermissions: FeaturePermission[];
	allowDelete?: boolean;
	onSelectFeature?: (featurePermission: FeaturePermission) => void;
	onClearFeature?: () => void;
	onDeleteFeature?: () => void;
	anyFeatureSelected?: boolean;
	setAnyFeatureSelected?: (anyFeatureSelected: boolean) => void;
	onUpdatePermissions?: (permissions: string[]) => void;
	showAnyFeature?: boolean;
	initialSelectedFeature?: string;
	initialSelectedPermissions?: string[];
	editFeature?: FeaturePermission;
	id?: string;
	canEdit?: boolean;
}

const FeaturePermissions = ({
	featurePermissions,
	onSelectFeature,
	onClearFeature,
	onDeleteFeature,
	anyFeatureSelected,
	setAnyFeatureSelected,
	onUpdatePermissions,
	showAnyFeature,
	initialSelectedFeature = '',
	initialSelectedPermissions = [],
	allowDelete,
	editFeature,
	id,
	canEdit,
}: FeaturePermissionsProps) => {
	const [selectedFeature, setSelectedFeature] = React.useState<string>(initialSelectedFeature);

	const [selectedPermissions, setSelectedPermissions] = React.useState<string[]>(initialSelectedPermissions);

	const [featureDropdownOpen, setFeatureDropdownOpen] = React.useState<boolean>(false);
	const [permissionDropdownOpen, setPermissionDropdownOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (initialSelectedFeature !== selectedFeature) {
			setSelectedPermissions([]);
		} else {
			if (!initialSelectedPermissions || !initialSelectedPermissions.length) {
				setSelectedPermissions([]);
			}
		}
	}, [selectedFeature]);

	const sortedFeaturePermissions = React.useMemo(() => {
		return _.cloneDeep(featurePermissions).sort((a, b) => {
			if (a.featureName < b.featureName) {
				return -1;
			}
			if (a.featureName > b.featureName) {
				return 1;
			}
			return 0;
		});
	}, [featurePermissions]);

	const sortedFeaturePermissionActions = React.useMemo(() => {
		const selectedActions = sortedFeaturePermissions.find(f => f.featureName === selectedFeature);
		if (!selectedActions) return [];
		return selectedActions.actions.sort((a, b) => a.localeCompare(b));
	}, [selectedFeature]);

	React.useEffect(() => {
		onUpdatePermissions?.(selectedPermissions);
	}, [selectedPermissions]);

	const allPermissionsSelected = selectedPermissions.length === sortedFeaturePermissionActions.length;

	React.useEffect(() => {
		if (initialSelectedFeature) {
			setSelectedFeature(initialSelectedFeature);
		}
	}, [initialSelectedFeature]);

	React.useEffect(() => {
		if (selectedPermissions.length) {
			return;
		}
		if (initialSelectedPermissions) {
			setSelectedPermissions(initialSelectedPermissions);
		}
	}, [initialSelectedFeature]);

	return (
		<div className="feature-permissions">
			<div className="feature-permissions__feature">
				<ActionDropdown
					disabled={!canEdit}
					onClick={() => {
						setFeatureDropdownOpen(!featureDropdownOpen);
					}}
					onClearSelection={() => {
						setSelectedFeature('');
						onClearFeature?.();
						setAnyFeatureSelected?.(false);
					}}
					placeholder="Select a feature"
					buttonLarge
					allowCancel
					isOpen={featureDropdownOpen}
					onMouseLeaveDropdown={() => setFeatureDropdownOpen(false)}
					label={anyFeatureSelected ? 'Any' : toTitleCase(selectedFeature)}
				>
					<div className="filter-dropdown__list">
						<ul>
							{showAnyFeature && (
								<li key={'Any'}>
									<RadioButton
										onClick={() => {
											setAnyFeatureSelected?.(!anyFeatureSelected);
											setSelectedFeature('');
											onSelectFeature?.({
												scopes: ['*'],
												featureName: '*',
												actions: [],
												id,
											});
										}}
										checked={anyFeatureSelected}
									>
										Any
									</RadioButton>
								</li>
							)}
							{showAnyFeature && <div className="filter-dropdown__divider"></div>}
							{sortedFeaturePermissions?.map((f: FeaturePermission) => (
								<li
									key={f.featureName}
									className={classNames({
										'seemingly-disabled': anyFeatureSelected,
									})}
								>
									<RadioButton
										onClick={() => {
											setSelectedFeature(f.featureName);
											onSelectFeature?.({ ...f, id });
											setFeatureDropdownOpen(false);
											setPermissionDropdownOpen(true);
										}}
										disabled={anyFeatureSelected}
										checked={selectedFeature.includes(f.featureName)}
									>
										{toTitleCase(f.featureName)}
									</RadioButton>
								</li>
							))}
						</ul>
					</div>
				</ActionDropdown>
			</div>
			<div className="feature-permissions__permissions">
				<div className="feature-permissions__permissions__actions">
					<ActionDropdown
						allowCancel
						isOpen={permissionDropdownOpen && !anyFeatureSelected}
						onMouseLeaveDropdown={() => setPermissionDropdownOpen(false)}
						placeholder={anyFeatureSelected ? 'All' : 'Select a permission'}
						label={[...new Set(selectedPermissions.map(p => fromCamelToTitle(p.split('/')[1])))].join(', ')}
						onClearSelection={() => {
							setSelectedPermissions([]);
							onUpdatePermissions?.([]);
						}}
						onClick={() => {
							setPermissionDropdownOpen(!permissionDropdownOpen);
						}}
						disabled={!selectedFeature.length || !canEdit}
					>
						<div className="filter-dropdown__list">
							<ul>
								<li key={'All'}>
									<Checkbox
										onClick={() => {
											if (!allPermissionsSelected) {
												// setSelectedPermissions(['*']);
												const sf = sortedFeaturePermissions.find(
													f => f.featureName === selectedFeature
												);
												if (sf) {
													setSelectedPermissions(sf.actions);
												}
											} else {
												setSelectedPermissions([]);
											}
										}}
										checked={allPermissionsSelected}
									>
										All
									</Checkbox>
								</li>
								<div className="filter-dropdown__divider"></div>
								{sortedFeaturePermissionActions.map((action: string) => {
									if (!action) return null;
									return (
										<li key={action}>
											<Checkbox
												onClick={() => {
													if (selectedPermissions.includes(action)) {
														setSelectedPermissions(
															_.cloneDeep(selectedPermissions).filter(sp => sp !== action)
														);
													} else {
														setSelectedPermissions([...selectedPermissions, action]);
													}
												}}
												checked={selectedPermissions.includes(action)}
											>
												{fromCamelToTitle(action.split('/')[1])}
											</Checkbox>
										</li>
									);
								})}
							</ul>
						</div>
					</ActionDropdown>
				</div>
			</div>
			{allowDelete && canEdit && (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => {
						onDeleteFeature?.();
						setAnyFeatureSelected?.(false);
						setSelectedFeature('');
						setSelectedPermissions([]);
					}}
				>
					<MdDelete />
				</div>
			)}
		</div>
	);
};

export function fromCamelToTitle(str: string) {
	if (!str) return '';
	return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
		return str.toUpperCase().trim();
	});
}

export default FeaturePermissions;
