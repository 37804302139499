import axios from 'axios';
import { replace } from 'connected-react-router';
import camelCase from 'lodash/camelCase';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import qs from 'qs';
import * as uuid from 'uuid';

import ActionTypes from '../constants/ActionTypes';
import analysisTypes from '../constants/AnalysisTypes';
import Constants from '../constants/Constants';
import GridConstants from '../constants/GridConstants';
import Urls from '../constants/Urls';
import arrifyObject from '../lib/arrifyObject';
import errorHandler from '../lib/errorHandler';
import getDates from '../lib/getDates';
import getPeriod from '../lib/getPeriod';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { Template, TemplateGroup } from '../templates/models';
import { FilterSegment, Segment } from '../types';
import { getSegmentsFromV2Report } from './commonUtils';
import { getStateForGenerateMetricOverlayReport } from './reducerUtils';

export function getAllSegments({
	mainSegments,
	filterSegment,
	breakdownSegment,
	employeeAttribute,
}: {
	mainSegments: Segment[];
	filterSegment?: FilterSegment;
	breakdownSegment?: Segment;
	employeeAttribute?: any;
}) {
	const allSegments: (Segment | FilterSegment)[] = mainSegments.slice();
	if (filterSegment) allSegments.push(filterSegment);
	if (breakdownSegment) allSegments.push(breakdownSegment);
	if (employeeAttribute)
		allSegments.push({
			name: employeeAttribute,
			values: [],
		});

	return allSegments;
}

function getFoundByVariations({
	variations,
	filterLength,
	mainSegments,
	filterSegment,
	breakdownSegment,
	employeeAttribute,
}: {
	variations: any;
	filterLength: number;
	mainSegments: Segment[];
	filterSegment?: FilterSegment;
	breakdownSegment?: Segment;
	employeeAttribute?: string;
}) {
	let fieldObject: any = {};
	let found = false;
	for (let i = 0; i < variations.length; i++) {
		const variation = variations[i];
		const variationItems = variation.filter(Boolean);
		if (variationItems.length === filterLength) {
			fieldObject = {};
			found = true;
			variation.forEach((variationItem: any, index: number) => {
				if (variationItem && found) {
					if (index < 3) {
						const field = mainSegments.find(
							(f: any) => f.name.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase()
						);
						if (!field) {
							found = false;
						} else {
							const fieldProp = `field${index + 1}`;
							fieldObject[fieldProp] = {
								fieldName: variationItem,
								fieldValues: field.values,
							};
						}
					}
					if (found && index === 3) {
						const fourthSegmentObj = filterSegment || breakdownSegment;
						const fourthSegment = (fourthSegmentObj && fourthSegmentObj.name) || employeeAttribute;
						if (fourthSegment) {
							found = fourthSegment.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase();
							if (filterSegment) {
								fieldObject.field4 = {
									fieldName: variationItem,
									fieldValues: filterSegment.values,
								};
							} else if (breakdownSegment && breakdownSegment.values.length === 1) {
								fieldObject.field4 = {
									fieldName: variationItem,
									fieldValues: breakdownSegment.values,
								};
							}
						} else {
							found = variationItem === '';
						}
					}
					if (found && index === 4) {
						const fifthSegment = (breakdownSegment && breakdownSegment.name) || employeeAttribute;
						if (fifthSegment) {
							found = fifthSegment.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase();
							if (breakdownSegment && breakdownSegment.values.length === 1) {
								fieldObject.field5 = {
									fieldName: variationItem,
									fieldValues: breakdownSegment.values,
								};
							}
						} else {
							found = variationItem === '';
						}
					}
				}
			});
			if (found) break;
		}
	}
	return { fieldObject, found };
}

export async function generate(getState: any, dispatch: any, state: any, actionTypes: any) {
	const { enterpriseId, latestDataUpdate } = getState().account;
	const { dynamicPermissions } = getState().auth;
	const { mode } = getState().appState;

	const {
		analysisDisplayName,
		analysisName,
		attributes,
		benchmark,
		chartData,
		chartType,
		dashboardId,
		employeeAttribute,
		endDate,
		eventIds,
		indexType,
		intersectionAttributes,
		interval,
		intervalCount,
		isFilterApplied,
		reportId,
		startDate,
		subAnalyses,
		subtableChartType,
		yAxisType,
		showSubtable,
		overlayAnalysisName,
		overlayAnalysisType,
		overlayChartType,
		overlayColumnProps,
		overlayDisplay,
		overlayFilterNames,
		overlayFilterSegment,
		overlayIndexType,
		overlayMainNames,
		overlayMainSegments,
		overlayYAxisType,
	} = state;

	if (getState().reports[reportId]?.generatePending) {
		return;
	}

	let { breakdownSegment, date, filterSegment, mainSegments } = state;

	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	let isSubtable = (breakdownSegment && breakdownSegment.values.length > 1) || employeeAttribute;

	return dispatch(getTemplateFromAnalysisName(analysisName)).then(async (template: any) => {
		const {
			engineName,
			mainColumn,
			analysisType,
			variations,
			supportedGraphs,
			uniqueName,
			subAnalysisMapping,
			uiFieldMapping: { analysisMetaFields, displayFields, subAnalysisFields },
		} = template;

		const shouldGetPrimaryData =
			isSubtable && engineName === 'Percentage' && ['total', 'included'].includes(mainColumn);

		const allowYAxisToggle = ['total', 'included'].includes(mainColumn) && !employeeCountHidden;
		const allowIndexToggle = analysisType === analysisTypes.Index;

		let columnProps: any[] = [];
		Object.keys(analysisMetaFields).forEach(fieldKey => {
			columnProps.push({
				header: fieldKey,
				...analysisMetaFields[fieldKey],
			});
		});

		columnProps = sortBy(columnProps, columnProp => parseInt(columnProp.order));

		// allSegments for mixpanel tracking
		let allSegments = getAllSegments({
			mainSegments,
			filterSegment,
			breakdownSegment,
			employeeAttribute,
		});

		let filterLength = allSegments.length;

		let { fieldObject, found } = getFoundByVariations({
			variations,
			filterLength,
			mainSegments,
			filterSegment,
			breakdownSegment,
			employeeAttribute,
		});

		if (!found) {
			if (!isFilterApplied) {
				dispatch({
					type: actionTypes.invalidVariation,
					isSubtable,
					reportId,
					employeeAttribute,
				});
				return Promise.resolve();
			} else {
				dispatch({
					type: actionTypes.invalidAppliedFilterStatus,
					reportId,
				});

				const dashboard = getState().dashboards[dashboardId];

				const {
					attributes: oldAttributes,
					filter: oldFilter,
					breakdown: oldBreakdown,
				} = dashboard.reports.find((report: any) => report.reportId === reportId);

				({ mainSegments, filterSegment, breakdownSegment } = getSegmentsFromV2Report(
					oldAttributes,
					oldFilter,
					oldBreakdown
				));

				allSegments = getAllSegments({
					mainSegments,
					filterSegment,
					breakdownSegment,
					employeeAttribute,
				});
				filterLength = allSegments.length;
				isSubtable = (breakdownSegment && breakdownSegment.values.length > 1) || employeeAttribute;
				({ fieldObject } = getFoundByVariations({
					variations,
					filterLength,
					mainSegments,
					filterSegment,
					breakdownSegment,
					employeeAttribute,
				}));
			}
		}

		if (isSubtable) {
			dispatch({
				type: actionTypes.subtablePending,
				reportId,
				analysisName,
				employeeAttribute,
			});
		} else {
			dispatch({
				type: actionTypes.reportPending,
				reportId,
				analysisName,
			});
		}

		let segmentField;

		if (breakdownSegment && breakdownSegment.values.length > 1) {
			segmentField = {
				fieldName: breakdownSegment.name.replace(/ /g, ''),
				fieldValues: [],
			};
		}

		if (employeeAttribute) {
			segmentField = {
				fieldName: employeeAttribute,
				fieldValues: [],
			};
		}

		let dates: any;
		if (!isSubtable) {
			dates = getDates(latestDataUpdate, interval, intervalCount, startDate, endDate).map(d => {
				return moment.utc(d).format(Constants.ApiDateFormat);
			});
		}

		if (breakdownSegment && !date) {
			date = moment.utc(latestDataUpdate).format(Constants.ApiDateFormat);
		}

		return axios
			.post(`${Urls.ExploreApi}explore`, {
				enterpriseId,
				interval,
				dates: isSubtable || analysisType === 'Arithmetic' ? [date || dates[dates.length - 1]] : dates,
				analysisName,
				analysisType,
				uniqueName,
				...fieldObject,
				segmentField,
			})
			.then(async response => {
				const resultsProp = camelCase(analysisType) + 'Results';
				const results = response.data.values[resultsProp];

				if (results.length === 0) {
					return dispatch({
						type: actionTypes.noData,
						reportId,
						isSubtable,
						employeeAttribute,
						analysisType,
					});
				}

				let overlayProps: any = {};
				if (!!overlayAnalysisName && !!reportId) {
					const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);
					const template = await dispatch(getTemplateFromAnalysisName(overlayAnalysisName));

					const {
						mainColumn,
						analysisType: overlayAnalysisType,
						variations,
						uniqueName,
						uiFieldMapping: { analysisMetaFields, subAnalysisFields },
					} = template;
					const allowYAxisToggle = ['total', 'included'].includes(mainColumn) && !employeeCountHidden;

					let overlayColumnProps: any[] = [];
					Object.keys(analysisMetaFields).forEach(fieldKey => {
						overlayColumnProps.push({
							header: fieldKey,
							...analysisMetaFields[fieldKey],
						});
					});

					overlayColumnProps = sortBy(overlayColumnProps, columnProp => parseInt(columnProp.order));

					const allSegments = overlayMainSegments.slice();
					if (overlayFilterSegment) {
						allSegments.push(overlayFilterSegment);
					}

					const filterLength = allSegments.length;
					let fieldObject: any = {};
					let found = false;

					for (let i = 0; i < variations.length; i++) {
						const variation = variations[i];
						const variationItems = variation.filter((v: any) => !!v);
						if (variationItems.length === filterLength) {
							fieldObject = {};
							found = true;
							variation.forEach((variationItem: any, index: number) => {
								if (variationItem && found) {
									if (index < 3) {
										const field = overlayMainSegments.find(
											(f: any) =>
												f.name.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase()
										);
										if (!field) {
											found = false;
										} else {
											const fieldProp = `field${index + 1}`;
											fieldObject[fieldProp] = {
												fieldName: variationItem,
												fieldValues: field.values,
											};
										}
									}
									if (found && index === 3) {
										const fourthSegment = overlayFilterSegment && overlayFilterSegment.name;
										if (fourthSegment) {
											found =
												fourthSegment.replace(/ /g, '').toLowerCase() ===
												variationItem.toLowerCase();
											if (overlayFilterSegment) {
												fieldObject.field4 = {
													fieldName: variationItem,
													fieldValues: overlayFilterSegment.values,
												};
											}
										} else {
											found = variationItem === '';
										}
									}
									if (found && index === 4) {
										found = variationItem === '';
									}
								}
							});
							if (found) break;
						}
					}
					const dates = getDates(latestDataUpdate, interval, intervalCount, startDate, endDate).map(d =>
						moment.utc(d).format(Constants.ApiDateFormat)
					);

					const overlayResponse = await axios.post(`${Urls.ExploreApi}explore`, {
						enterpriseId,
						interval,
						dates: analysisType === 'Arithmetic' ? [date || dates[dates.length - 1]] : dates,
						analysisName: overlayAnalysisName,
						analysisType: overlayAnalysisType,
						uniqueName,
						...fieldObject,
					});

					const resultsProp = camelCase(overlayAnalysisType) + 'Results';
					const overlayResults = overlayResponse.data.values[resultsProp];
					if (results.length === 0) {
						return dispatch({
							type: actionTypes.noData,
							analysisType,
						});
					}

					const mainData = results.filter((r: any) => {
						return r.date === dates[0].replace(/-/g, '/');
					});

					overlayProps = getStateForGenerateMetricOverlayReport(
						{ data: mainData },
						{
							allowYAxisToggle,
							analysisName: overlayAnalysisName,
							analysisType: overlayAnalysisType,
							attributes,
							columnProps: overlayColumnProps,
							filterSegment: overlayFilterSegment,
							interval,
							mainSegments: overlayMainSegments,
							results: overlayResults,
							subAnalyses,
							subAnalysisFields,
						}
					);
				}
				if (isSubtable) {
					dispatch({
						type: actionTypes.subtableFulfilled,
						reportId,
						mode,
						results,
						analysisName,
						analysisType,
						mainSegments,
						filterSegment,
						breakdownSegment,
						attributes,
						date,
						interval,
						employeeAttribute,
						intersectionAttributes,
						chartData,
						subtableChartType,
						showSubtable,
						columnProps,
						shouldGetPrimaryData,
						mainColumn,
						yAxisType,
					});
					if (shouldGetPrimaryData) {
						return axios.post(`${Urls.ExploreApi}explore`, {
							enterpriseId,
							interval,
							dates: [date],
							analysisName,
							analysisType,
							uniqueName,
							...fieldObject,
						});
					}
				} else {
					if (!breakdownSegment) {
						if (actionTypes.setSections) {
							dispatch({
								type: actionTypes.setSections,
								mainSegments,
								filterSegment,
								variations,
								attributes,
								analysisType,
								mainColumn,
							});
						}
					} else {
						if (actionTypes.clearSections) {
							dispatch({
								type: actionTypes.clearSections,
							});
						}
					}

					dispatch({
						type: actionTypes.reportFulfilled,
						reportId,
						results,
						interval,
						analysisDisplayName,
						analysisName,
						subAnalyses,
						analysisType,
						mainSegments,
						filterSegment,
						breakdownSegment,
						attributes,
						startDate,
						endDate,
						intervalCount,
						chartType,
						columnProps,
						subAnalysisFields,
						displayFields,
						supportedGraphs,
						subAnalysisMapping,
						allowYAxisToggle,
						eventIds,
						yAxisType,
						indexType,
						allowIndexToggle,
						isFilterApplied,
						overlayAnalysisName,
						overlayAnalysisType,
						overlayChartType,
						overlayColumnProps,
						overlayDisplay,
						overlayFilterNames,
						overlayFilterSegment,
						overlayIndexType,
						overlayMainNames,
						overlayMainSegments,
						overlayYAxisType,
						...overlayProps,
					});

					if (reportId && benchmark) {
						dispatch({
							type: actionTypes.setBenchmark,
							reportId,
							benchmark,
						});

						return dispatch(fetchBenchmarkData(reportId, benchmark.query));
					}
				}
			})
			.then(response => {
				if (shouldGetPrimaryData && response && response.data) {
					const resultsProp = camelCase(analysisType) + 'Results';
					const results = response.data.values[resultsProp];
					dispatch({
						type: actionTypes.primaryDataFulfilled,
						reportId,
						results,
						interval,
						analysisName,
						analysisType,
						mainSegments,
						filterSegment,
						attributes,
						columnProps,
						date,
						employeeAttribute,
						isFilterApplied,
					});
				}
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				if (isSubtable) {
					dispatch({
						type: actionTypes.subtableRejected,
						reportId,
						employeeAttribute,
					});
				} else {
					dispatch({
						type: actionTypes.reportRejected,
						reportId,
						message: error.response?.data,
						status: error.response?.status,
					});
				}
			});
	});
}

export function generateOverlay(getState: any, dispatch: any, state: any, actionTypes: any) {
	const { enterpriseId, latestDataUpdate } = getState().account;
	const { dynamicPermissions } = getState().auth;

	const {
		analysisName,
		attributes,
		chartType,
		endDate,
		filterSegment,
		interval,
		intervalCount,
		mainSegments,
		startDate,
		subAnalyses,
	} = state;

	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	let { date } = state;

	return dispatch(getTemplateFromAnalysisName(analysisName)).then((template: any) => {
		const {
			analysisType,
			mainColumn,
			uniqueName,
			variations,
			uiFieldMapping: { analysisMetaFields, subAnalysisFields },
		} = template;

		const allowYAxisToggle = ['total', 'included'].includes(mainColumn) && !employeeCountHidden;

		let columnProps: any[] = [];
		Object.keys(analysisMetaFields).forEach(fieldKey => {
			columnProps.push({
				header: fieldKey,
				...analysisMetaFields[fieldKey],
			});
		});

		columnProps = sortBy(columnProps, columnProp => parseInt(columnProp.order));

		// allSegments for mixpanel tracking
		const allSegments = mainSegments.slice();
		if (filterSegment) {
			allSegments.push(filterSegment);
		}

		const filterLength = allSegments.length;
		let fieldObject: any = {};
		let found = false;

		for (let i = 0; i < variations.length; i++) {
			const variation = variations[i];
			const variationItems = variation.filter((v: any) => !!v);
			if (variationItems.length === filterLength) {
				fieldObject = {};
				found = true;
				variation.forEach((variationItem: any, index: number) => {
					if (variationItem && found) {
						if (index < 3) {
							const field = mainSegments.find(
								(f: any) => f.name.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase()
							);
							if (!field) {
								found = false;
							} else {
								const fieldProp = `field${index + 1}`;
								fieldObject[fieldProp] = {
									fieldName: variationItem,
									fieldValues: field.values,
								};
							}
						}
						if (found && index === 3) {
							const fourthSegment = filterSegment && filterSegment.name;
							if (fourthSegment) {
								found = fourthSegment.replace(/ /g, '').toLowerCase() === variationItem.toLowerCase();
								if (filterSegment) {
									fieldObject.field4 = {
										fieldName: variationItem,
										fieldValues: filterSegment.values,
									};
								}
							} else {
								found = variationItem === '';
							}
						}
						if (found && index === 4) {
							found = variationItem === '';
						}
					}
				});
				if (found) break;
			}
		}

		dispatch({
			type: actionTypes.reportPending,
			analysisName,
		});

		const dates = getDates(latestDataUpdate, interval, intervalCount, startDate, endDate).map(d =>
			moment.utc(d).format(Constants.ApiDateFormat)
		);

		return axios
			.post(`${Urls.ExploreApi}explore`, {
				enterpriseId,
				interval,
				dates: analysisType === 'Arithmetic' ? [date || dates[dates.length - 1]] : dates,
				analysisName,
				analysisType,
				uniqueName,
				...fieldObject,
			})
			.then(response => {
				const resultsProp = camelCase(analysisType) + 'Results';
				const results = response.data.values[resultsProp];
				if (results.length === 0) {
					return dispatch({
						type: actionTypes.noData,
						analysisType,
					});
				}

				dispatch({
					type: actionTypes.reportFulfilled,
					allowYAxisToggle,
					analysisName,
					analysisType,
					attributes,
					chartType,
					columnProps,
					filterSegment,
					interval,
					mainSegments,
					results,
					subAnalyses,
					subAnalysisFields,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: actionTypes.reportRejected,
					message: error.response?.data,
					status: error.response?.status,
				});
			});
	});
}

export function getTemplateFromAny(analysisName: string) {
	return (dispatch: any, getState: any) => {
		const { templates, groups } = getState().templates;

		const pending = templates.some((template: any) => template.pending);
		let mainNames: string[] = [];
		let filterNames: string[] = [];
		let breakdownNames: string[] = [];
		if (pending) {
			return new Promise(resolve => {
				setTimeout(() => {
					resolve(dispatch(getTemplateFromAny(analysisName)));
				}, 500);
			});
		}

		const anyTemplate = {
			analysisName: '*',
			analysisDisplayName: 'Any',
			variations: [],
			filterNames: [],
			breakdownNames: [],
		};

		groups.forEach((templateGroup: TemplateGroup) => {
			templateGroup?.templates?.forEach((template: Template) => {
				if (template.configurationVariables[0].additionalVariables) {
					mainNames = mainNames.concat(
						template.configurationVariables[0].additionalVariables.map((v: any) => {
							return v.originalName;
						})
					);
				}
				if (template.configurationVariables[0].controlVariables) {
					filterNames = filterNames.concat(
						template.configurationVariables[0].controlVariables.map((v: any) => {
							return v.originalName;
						})
					);
					breakdownNames = breakdownNames.concat(
						template.configurationVariables[0].controlVariables.map((v: any) => {
							return v.originalName;
						})
					);
				}
			});
		});

		// remove duplicates
		filterNames = [...new Set(filterNames)];
		breakdownNames = [...new Set(breakdownNames)];

		return Promise.resolve({
			...anyTemplate,
			filterNames,
			mainNames,
			breakdownNames,
			analysisType: '',
			uniqueName: '',
		});
	};
}

export function combineTemplateGroup(templateGroup?: TemplateGroup): Template | any[] {
	if (!templateGroup) return [];

	const [firstTemplate, ...restTemplates] = templateGroup?.templates || [];

	let combinedTemplate = {
		...firstTemplate,
		variations: [...(firstTemplate?.variations ?? [])],
		mainNames: [...(firstTemplate?.mainNames ?? [])],
		filterNames: [...(firstTemplate?.filterNames ?? [])],
		breakdownNames: [...(firstTemplate?.breakdownNames ?? [])],
	};

	if (restTemplates.length > 0) {
		restTemplates.forEach((template: Template) => {
			combinedTemplate.variations.push(...(template.variations ?? []));
			combinedTemplate.mainNames.push(...(template.mainNames ?? []));
			combinedTemplate.filterNames.push(...(template.filterNames ?? []));
			combinedTemplate.breakdownNames.push(...(template.breakdownNames ?? []));
		});
	}

	return {
		...combinedTemplate,
		analysisName: templateGroup?.name,
	};
}

export function getTemplateFromAnalysisName(analysisName: string) {
	return (dispatch: any, getState: any) => {
		const { groups } = getState().templates;
		if (analysisName === '*') {
			return dispatch(getTemplateFromAny(analysisName));
		}

		const group = groups.find((t: TemplateGroup) => t.name === analysisName);

		if (!group) return Promise.reject(new Error('Template not found'));

		const [firstTemplate, ...restTemplates] = group?.templates || [];

		let combinedTemplate = {
			...firstTemplate,
			variations: [...(firstTemplate?.variations ?? [])],
			mainNames: [...(firstTemplate?.mainNames ?? [])],
			filterNames: [...(firstTemplate?.filterNames ?? [])],
			breakdownNames: [...(firstTemplate?.breakdownNames ?? [])],
		};

		if (restTemplates.length > 0) {
			restTemplates.forEach((template: Template) => {
				combinedTemplate.variations.push(...(template.variations ?? []));
				combinedTemplate.mainNames.push(...(template.mainNames ?? []));
				combinedTemplate.filterNames.push(...(template.filterNames ?? []));
				combinedTemplate.breakdownNames.push(...(template.breakdownNames ?? []));
			});
		}

		if (!combinedTemplate) {
			// unknown error
			return Promise.reject();
		}
		const { variations, pending, analysisType, uniqueName } = combinedTemplate;
		if (variations) {
			return Promise.resolve({
				...combinedTemplate,
				analysisName: group?.name,
				analysisType,
				uniqueName,
			});
		} else if (pending) {
			return new Promise(resolve => {
				setTimeout(() => {
					resolve(dispatch(getTemplateFromAnalysisName(analysisName)));
				}, 500);
			});
		} else {
			return dispatch(getTemplateFromAnalysisName(analysisName));
		}
	};
}

export function getReportAttributes(
	mainSegments: Segment[],
	filterSegment: FilterSegment,
	breakdownSegment: Segment,
	allAttributes: any,
	employeeAttribute: any,
	rowFilter: string[] = [],
	columnFilter: string[] = []
) {
	const attributes = mainSegments.map((ms: any) => ({
		name: ms.name,
		value: ms.values.slice(),
	}));
	let filter;
	if (filterSegment && !!filterSegment.values?.length) {
		filter = {
			name: filterSegment.name,
			value: filterSegment.values[0],
		};
	}
	let breakdown;
	if (breakdownSegment) {
		breakdown = {
			name: breakdownSegment.name,
			value: breakdownSegment.values,
		};
	}

	if (employeeAttribute) {
		if (employeeAttribute) {
			const attribute = allAttributes.find(
				(a: any) => a.attributeName.replace(/ /g, '').toLowerCase() === employeeAttribute.toLowerCase()
			);
			breakdown = {
				name: attribute.attributeName,
				value: attribute.attributeValues.map((v: any) => v.value),
			};
			if (rowFilter.length) {
				breakdown.value = breakdown.value.filter((v: any) => rowFilter.includes(v));
			}
			if (columnFilter.length) {
				// eg, Female_Asian, Female_Black, Male_White

				const attributeValueSet: any[] = [];
				attributes.forEach(() => {
					attributeValueSet.push(new Set<string>());
				});
				columnFilter.forEach(filter => {
					const tokens = filter.split('_');
					tokens.forEach((token, i) => {
						attributeValueSet[i].add(token);
					});
				});
				attributes.forEach((attribute: any) => {
					let removed = false;
					attributeValueSet.forEach(set => {
						if (removed) {
							return;
						}
						const selectedAttributeValues = Array.from(set);
						let found = true;
						selectedAttributeValues.forEach(selectedAttributeValue => {
							found = found && attribute.value.includes(selectedAttributeValue);
						});
						if (found) {
							// This is the set and this is the attribute to filter
							const removableIndices: number[] = [];
							attribute.value.forEach((attributeValue: any, i: number) => {
								if (!selectedAttributeValues.includes(attributeValue)) {
									removableIndices.push(i);
								}
							});
							for (let i = removableIndices.length - 1; i >= 0; i--) {
								attribute.value.splice(removableIndices[i]);
							}
							removed = true;
						}
					});
				});
			}
		}
	}

	return {
		attributes,
		filter,
		breakdown,
	};
}

function getAdditionalConfig(config: any = {}) {
	return Object.keys(config).map(key => {
		return {
			name: key,
			value: typeof config[key] === 'undefined' ? '' : config[key].toString(),
		};
	});
}

export function getReportDef(params: any) {
	const {
		reportId = uuid.v4(),
		rowIndex,
		columnIndex,
		columnWidthPerc,
		rowHeight,
		analysisName,
		mainSegments,
		filterSegment,
		breakdownSegment,
		attributes: allAttributes,
		interval,
		intervalCount,
		startDate,
		endDate,
		employeeAttribute,
		rowFilter,
		columnFilter,
		date,
		reportName,
		benchmark,
		subAnalyses,
		additionalConfig,
		eventIds,
		length = GridConstants.ColumnCount,
		yAxisType,
		indexType,
		isFilterApplied,
		subtableChartType,
		showSubtable,
		overlayAnalysisName,
		overlayAnalysisDisplayName,
		overlayAnalysisType,
		overlayChartType,
		overlayColumnProps,
		overlayDisplay,
		overlayFilterNames,
		overlayFilterSegment,
		overlayIndexType,
		overlayMainNames,
		overlayMainSegments,
		overlayYAxisType,
	} = params;

	let { chartType } = params;

	const { attributes, filter, breakdown } = getReportAttributes(
		mainSegments,
		filterSegment,
		breakdownSegment,
		allAttributes,
		employeeAttribute,
		rowFilter,
		columnFilter
	);

	const isSubtable = !!employeeAttribute || (breakdownSegment && breakdownSegment.values.length > 1);

	const subAnalysesConverted = subAnalyses
		.map((s: any) => ({
			name: s.name,
			source: s.source,
		}))
		.map((subAnalysis: any) => ({
			configuration: arrifyObject(subAnalysis),
		}));

	let layoutConfiguration = [];

	if (rowIndex !== undefined && rowIndex !== null) {
		layoutConfiguration.push({
			name: 'rowIndex',
			value: rowIndex.toString(),
		});
	}

	if (columnIndex !== undefined && columnIndex !== null) {
		layoutConfiguration.push({
			name: 'columnIndex',
			value: columnIndex.toString(),
		});
	}

	if (columnWidthPerc !== undefined && columnWidthPerc !== null) {
		layoutConfiguration.push({
			name: 'columnWidthPerc',
			value: columnWidthPerc.toString(),
		});
	}

	if (rowHeight) {
		layoutConfiguration.push({
			name: 'rowHeightPx',
			value: rowHeight.toString(),
		});

		layoutConfiguration.push({
			name: 'layoutVersion',
			value: 'v2',
		});
	}

	const report = {
		reportId,
		version: '2',
		name: reportName,
		dateAdded: moment.utc().toISOString(),
		analysisName,
		analysis: subAnalysesConverted,
		attributes,
		filter,
		breakdown,
		isFilterApplied,
		eventIds: eventIds || [],
		configuration: [
			{
				name: 'chartType',
				value: chartType,
			},
			{
				name: 'length',
				value: length.toString(),
			},
			{
				name: 'interval',
				value: interval,
			},
			{
				name: 'yAxisType',
				value: yAxisType,
			},
			{
				name: 'indexType',
				value: indexType,
			},
			{
				name: 'subtableChartType',
				value: subtableChartType,
			},
			{
				name: 'showSubtable',
				value: showSubtable.toString(),
			},
			{
				name: 'overlayAnalysisName',
				value: overlayAnalysisName,
			},
			{
				name: 'overlayAnalysisDisplayName',
				value: overlayAnalysisDisplayName,
			},
			{
				name: 'overlayAnalysisType',
				value: overlayAnalysisType,
			},
			{
				name: 'overlayChartType',
				value: overlayChartType,
			},
			{
				name: 'overlayColumnProps',
				value: JSON.stringify(overlayColumnProps),
			},
			{
				name: 'overlayDisplay',
				value: overlayDisplay.toString(),
			},
			{
				name: 'overlayFilterNames',
				value: JSON.stringify(overlayFilterNames),
			},
			{
				name: 'overlayFilterSegment',
				value: JSON.stringify(overlayFilterSegment),
			},
			{
				name: 'overlayIndexType',
				value: overlayIndexType,
			},
			{
				name: 'overlayMainNames',
				value: JSON.stringify(overlayMainNames),
			},
			{
				name: 'overlayMainSegments',
				value: JSON.stringify(overlayMainSegments),
			},
			{
				name: 'overlayYAxisType',
				value: overlayYAxisType,
			},
			...layoutConfiguration,
			...getAdditionalConfig(additionalConfig),
		],
	};

	if (!isSubtable) {
		if (startDate) {
			report.configuration.push({
				name: 'startDate',
				value: startDate,
			});
			report.configuration.push({
				name: 'endDate',
				value: endDate,
			});
		} else {
			report.configuration.push({
				name: 'intervalCount',
				value: intervalCount.toString(),
			});
		}
	}

	if (benchmark) {
		const { benchmarkName, attributes, query, hidePie } = benchmark;
		const benchmarkConfig = {
			benchmarkName,
			attributes,
			query,
			hidePie,
		};
		report.configuration.push({
			name: 'benchmark',
			value: JSON.stringify(benchmarkConfig),
		});
	}

	if (date) {
		report.configuration.push({
			name: 'date',
			value: date,
		});
	}

	return report;
}

export function fetchBenchmarkData(reportId: string, query: any) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.FetchBenchmarkDataForReportPending,
			reportId,
		});
		return axios
			.post(`${Urls.BenchmarkApi}benchmark/query`, query)
			.then(response => {
				const { data } = response;
				if (data.length === 0) {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataForReportNoData,
						reportId,
					});
				} else {
					dispatch({
						type: ActionTypes.FetchBenchmarkDataForReportFulfilled,
						reportId,
						data: data[0].level1Results,
					});
				}
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.FetchBenchmarkDataForReportRejected,
					reportId,
				});
			});
	};
}

export function updateUrlsQuery(key: string, value: any) {
	return (dispatch: any, getState: any) => {
		const { state, search } = getState().router.location;

		const queryObject = qs.parse(search, {
			ignoreQueryPrefix: true,
			// @ts-ignore
			arrayFormat: 'repeat',
			comma: false,
		});

		queryObject.filter = {
			...(queryObject.filter as qs.ParsedQs[]),
			[key]: value,
		};
		let url = '/explore?';
		const string = qs.stringify(queryObject, {
			arrayFormat: 'indices',
			encodeValuesOnly: true,
		});
		url += string;

		dispatch(replace(url, { source: state?.source || 'explore' }));
	};
}
