import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from './ButtonV2';
import Close from '../icons/Close';
import DropdownArrow from '../icons/DropdownArrow';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cn from '../lib/cn';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
	onClick: (option: any) => void;
	placeholder?: string;
	buttonLarge?: boolean;
	allowCancel?: boolean;
	disabled?: boolean;
	isOpen?: boolean;
	label?: string;
	children?: React.ReactNode;
	onClearSelection?: () => void;
	onMouseLeaveDropdown?: () => void;
	limitHeight?: number | string;
	alignDropdownRight?: boolean;
}

const Dropdown = React.forwardRef(
	(
		{
			onClick,
			placeholder,
			allowCancel,
			disabled,
			isOpen,
			label,
			onClearSelection,
			buttonLarge,
			children,
			onMouseLeaveDropdown,
			limitHeight,
			alignDropdownRight,
			...rest
		}: DropdownProps,
		ref?: any
	) => {
		const dropdownRef = React.useRef<HTMLDivElement>(null);
		const [open, setOpen] = React.useState(isOpen);

		const handleClear = (e: React.MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (!disabled) {
				onClearSelection?.();
			}
		};

		return (
			<DropdownMenu.Root open={open} onOpenChange={setOpen}>
				<div className={cn('relative z-[1] !overflow-visible max-w-full')} ref={ref} {...rest}>
					<DropdownMenu.Trigger asChild disabled={disabled}>
						<Button
							variant="secondary"
							size={buttonLarge ? 'md' : 'sm'}
							activated={open && 'default'}
							disabled={disabled}
							selected={!!label && !open && 'default'}
							className={cn('relative z-[1]')}
						>
							<div className={cn('flex items-center gap-[.4rem] w-full')}>
								<span className="long-text">{label || placeholder}</span>

								{allowCancel && label ? (
									<div className={cn('relative invisible flex-shrink-0')}>
										<Close width={12} height={12} />
									</div>
								) : (
									<div className={cn('invisible flex-shrink-0')}>
										<DropdownArrow width={18} height={18} />
									</div>
								)}
							</div>
						</Button>
					</DropdownMenu.Trigger>
					{/* Needs to be outside Trigger to work, therefore it's a copy  */}
					<Button
						variant="skeleton"
						size={buttonLarge ? 'md' : 'sm'}
						activated={open && 'default'}
						disabled={disabled}
						selected={!!label && !open && 'default'}
						className={cn(
							'absolute inset-0 z-0 !bg-[transparent] z-[2] pointer-events-none',
							disabled && '!text-ui-20'
						)}
						asChild
					>
						<div className={cn('flex items-center gap-[.4rem] w-full')}>
							<span className="long-text invisible ">{label || placeholder}</span>

							{allowCancel && label ? (
								<div
									className={cn(
										'relative pointer-events-auto cursor-pointer flex items-center flex-shrink-0'
									)}
									onClick={handleClear}
								>
									<Close width={12} height={12} />
								</div>
							) : (
								<div className={cn('flex-shrink-0 flex items-center')}>
									<DropdownArrow width={18} height={18} />
								</div>
							)}
						</div>
					</Button>
				</div>
				<DropdownMenu.Portal>
					<DropdownMenu.Content
						side={alignDropdownRight ? 'right' : 'bottom'}
						align="start"
						sideOffset={16}
						avoidCollisions={true}
						collisionPadding={16}
						asChild
					>
						<div
							className={cn(
								'bg-shade-h4 px-[1.6rem] pt-[1.6rem] pb-[.8rem] rounded-[2rem] h-auto',
								'flex flex-col overflow-y-auto',
								'min-w-[220px]'
							)}
							data-test={`dropdown-menu-${placeholder}`}
							onMouseLeave={onMouseLeaveDropdown}
							ref={dropdownRef}
							style={{
								zIndex: 10000,
								maxHeight: limitHeight
									? `min(var(--radix-dropdown-menu-content-available-height), ${limitHeight})`
									: 'var(--radix-dropdown-menu-content-available-height)',
							}}
						>
							{children}
						</div>
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		);
	}
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
