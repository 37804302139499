import { useEffect, useState } from 'react';

import WithPermissions from '../../../common/WithPermissions';
import MixPanel from '../../../constants/MixPanel';
import RbacActions from '../../../constants/RbacActions';
import cn from '../../../lib/cn';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { navigateToReport } from '../../actions';
import Search from './Search/Search';

interface HighlightedIndex {
	section?: number;
	thumbnail?: number;
}

const SearchLibrary = () => {
	const dispatch = useAppDispatch();
	const isEmptyState = !useAppSelector(state => state.explore.filter?.analysisName);
	const { searches, sortCriteria } = useAppSelector(state => state.explore.searches);

	const [highlightedIndex, setHighlightedIndex] = useState<HighlightedIndex | null>(null);
	const [allHighlighted, setAllHighlighted] = useState(true);
	const [mouseOutside, setMouseOutside] = useState(true);

	const librarySections = [{ title: 'Saved Searches', charts: searches }];

	useEffect(() => {
		if (!highlightedIndex || mouseOutside) setAllHighlighted(true);
		else setAllHighlighted(false);
	}, [highlightedIndex, mouseOutside]);

	const handleNavigate = (chart: any) => {
		setAllHighlighted(false);
		setMouseOutside(true);
		setHighlightedIndex(null);
		dispatch(navigateToReport(chart));

		track(MixPanel.Events.ExploreSavedSearchReportClick, {
			'Report Name': chart.name,
		});
	};

	if (!isEmptyState) {
		return null;
	}

	return (
		<WithPermissions actions={[RbacActions['Dashboards/ViewAll']]}>
			<div className={cn('thumbnail-library__container', 'w-full')}>
				{librarySections.map((section, sectionIndex) => (
					<>
						{section.charts.length > 0 && (
							<h3 className="thumbnail-library__section__title">{section.title}</h3>
						)}
						<LibrarySection setMouseOutside={setMouseOutside}>
							{section.charts
								.sort((a: any, b: any) => {
									const res = a[sortCriteria.columnName]?.localeCompare(b[sortCriteria.columnName]);
									return sortCriteria.ascending ? res : -res;
								})
								.map((chart: any, index: number) => {
									return (
										<Search
											type={chart.configuration[0]?.value?.toLowerCase()}
											reportId={chart.reportId}
											key={chart.reportId}
											title={chart.name}
											count={chart.attributes[0].value.length}
											onClick={() => {
												handleNavigate(chart);
											}}
											highlighted={
												allHighlighted ||
												mouseOutside ||
												(highlightedIndex &&
													highlightedIndex.section === sectionIndex &&
													highlightedIndex.thumbnail === index) ||
												false
											}
											hovered={
												(highlightedIndex &&
													highlightedIndex.section === sectionIndex &&
													highlightedIndex.thumbnail === index) ||
												false
											}
											onMouseEnter={() =>
												setHighlightedIndex({
													section: sectionIndex,
													thumbnail: index,
												})
											}
											onMouseLeave={() => setHighlightedIndex(null)}
										></Search>
									);
								})}
						</LibrarySection>
					</>
				))}
			</div>
		</WithPermissions>
	);
};

interface LibrarySectionProps extends React.HTMLProps<HTMLDivElement> {
	setMouseOutside: (mouseOutside: boolean) => void;
}

const LibrarySection = ({ setMouseOutside, ...props }: LibrarySectionProps) => {
	return (
		<div
			className="thumbnail-library__section"
			onMouseLeave={() => setMouseOutside(true)}
			onMouseOver={() => setMouseOutside(false)}
			{...props}
		>
			{props.children}
		</div>
	);
};

export default SearchLibrary;
