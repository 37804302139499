import React from 'react';
import qs from 'qs';
import { MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Button from '../../../common/Button';
import Checkbox from '../../../common/Checkbox';
import Spinner from '../../../common/SpinnerDefault';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';
import RbacActions from '../../../constants/RbacActions';
import usePermissions from '../../../hooks/usePermissions';
import { FeaturePermission as FeaturePermissionModel } from '../../../roles/createRole/models';
import { createBlankAnalysisPermission, createBlankFeaturePermission } from '../Components/helpers';
import {
	addFeaturePermission,
	clearFeaturePermission,
	createAnalysisPermission,
	removeFeaturePermission,
	setHideEmployeeCount,
	setName as setStoreName,
} from '../../../roles/createRole/reducer';
import Forbidden from '../../../routes/Forbidden';
import { useAppSelector } from '../../../store/hooks';
import { fetchPermissions } from '../../permissions/reducer';
import AnalysisPermission from '../Components/AnalysisPermission';
import FeaturePermission from '../Components/FeaturePermission';
import { copyRole } from '../../../roles/createRole/actions';

const CreateRole = () => {
	const dispatch = useDispatch();
	const {
		analysisPermissions,
		featurePermissions,
		name: stateRoleName,
		hideEmployeeCount,
		id,
	} = useAppSelector(state => state.roles.newRole);

	const { staticPermissionsByFeature, staticPermissions } = useAppSelector<any>(state => state.admin.permissions);
	const { loaded: templatesLoaded } = useAppSelector<any>(state => state.templates);

	const { search } = useAppSelector(state => state.router.location);
	const query = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	const { copyRoleId, initialName }: any = query;
	const [anyFeatureSelected, setAnyFeatureSelected] = React.useState<boolean>(false);

	const { allRoles } = useAppSelector(state => state.admin.roles);
	const nameExists = allRoles.some(role => role.name === stateRoleName);
	const canView = usePermissions({ actions: [RbacActions['Roles/View']] });
	const canEdit = usePermissions({ actions: [RbacActions['Roles/Edit']] });
	const userAllowed = canView || canEdit;

	function handleNameChange(name: string) {
		dispatch(setStoreName(name));
	}

	React.useEffect(() => {
		if (!copyRoleId) {
			return;
		}

		if (copyRoleId) {
			dispatch(
				copyRole({
					copyRoleId,
					name: initialName,
				})
			);
		}
	}, [copyRoleId]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		if (!staticPermissions || staticPermissions.length === 0) {
			dispatch(fetchPermissions());
		}
		if (!featurePermissions || featurePermissions.length === 0) {
			const featurePermissions = createBlankFeaturePermission();
			dispatch(addFeaturePermission(featurePermissions));
		}
	}, []);

	if (!userAllowed) {
		return <Forbidden />;
	}

	return (
		<div className="create-role">
			{templatesLoaded ? (
				<form className="create-role__form">
					{canEdit && (
						<div className="create-role__form__row">
							<label className="create-role__form__label">Name</label>
							<TextField
								type="text"
								value={stateRoleName}
								onChange={e => handleNameChange(e.target.value)}
								placeholder="Enter role name"
								classes={['create-role__form__input']}
								showClose
								onClose={() => handleNameChange('')}
							/>
							{nameExists && !id && (
								<span className="create-role__form__label-small create-role__form__label-small__error">
									A role with that name already exists
								</span>
							)}
						</div>
					)}
					<div className="create-role__form__section">
						<div className="create-role__form__header">Feature Permissions</div>
					</div>
					<div className="create-role__form__section">
						<div className="create-role__form__labels">
							<span className="create-role__form__label">Feature</span>
							<span className="create-role__form__label" style={{ flex: 3 }}>
								Actions
							</span>
						</div>
						{featurePermissions.map((fp, index) => (
							<div className="create-role__form__row" key={fp.id || index}>
								<FeaturePermission
									id={fp.id}
									canEdit={canEdit}
									initialSelectedFeature={fp?.featureName || ''}
									initialSelectedPermissions={fp?.actions || []}
									allowDelete={index !== 0}
									editFeature={id ? fp : undefined}
									onSelectFeature={(feature: FeaturePermissionModel) => {
										if (!feature.featureName || !feature.id) return;
										dispatch(
											addFeaturePermission({
												scopes: feature.scopes,
												featureName: feature.featureName,
												id: feature.id,
											})
										);
									}}
									onUpdatePermissions={(permissions: string[]) => {
										dispatch(
											addFeaturePermission({
												...fp,
												actions: permissions,
											})
										);
									}}
									onClearFeature={() => dispatch(clearFeaturePermission(id ? id : index.toString()))}
									onDeleteFeature={() => {
										dispatch(
											removeFeaturePermission({
												id: fp.id,
											})
										);
										setAnyFeatureSelected(false);
									}}
									featurePermissions={staticPermissionsByFeature}
									anyFeatureSelected={anyFeatureSelected}
									setAnyFeatureSelected={setAnyFeatureSelected}
								/>
							</div>
						))}

						{canEdit && (
							<div className="create-role__form__section">
								<Button
									style={{ minWidth: 64 }}
									onClick={() => {
										const featurePermission = createBlankFeaturePermission();
										dispatch(addFeaturePermission(featurePermission));
									}}
									componentType={ButtonTypes.type.SECONDARY}
									disabled={
										featurePermissions[featurePermissions.length - 1]?.featureName === '' ||
										anyFeatureSelected
									}
								>
									<MdAdd />
								</Button>
							</div>
						)}
						<div className="create-role__form__row">
							{canEdit && (
								<span className="create-role__form__label-small">
									*Please define Analysis Permissions below in order for this role to have access to
									any reporting, including within Dashboards, Explore, and Goals
								</span>
							)}
						</div>
						<div className="create-role__form__divider"></div>
						<div className="create-role__form__section"></div>
						<div className="create-role__form__section">
							<div className="create-role__form__row">
								<span>Analysis Permissions</span>
								<div className="create-role__form__row"></div>
								{analysisPermissions.length > 0 && (
									<div className="create-role__form__row">
										<div className="create-role__form__labels">
											<span className="create-role__form__label">Analysis</span>
											<span className="create-role__form__label">Segments</span>
											<span className="create-role__form__label">Filters</span>
											<span className="create-role__form__label">Breakdowns</span>
										</div>
									</div>
								)}
								{analysisPermissions.map((analysisPermission, index) => {
									return (
										<div key={analysisPermission.id} className="create-role__form__row--small">
											<AnalysisPermission index={index} editMode={!!copyRoleId} />
										</div>
									);
								})}
								{canEdit && (
									<div className="create-role__form__section">
										<Button
											style={{ minWidth: 64 }}
											onClick={() => {
												const analysisPermission = createBlankAnalysisPermission();
												dispatch(createAnalysisPermission(analysisPermission));
											}}
											componentType={ButtonTypes.type.SECONDARY}
										>
											<MdAdd />
										</Button>
									</div>
								)}
								{analysisPermissions.length === 0 && !canEdit && (
									<span className="create-role__form__label-small">
										No analysis permissions selected for this role
									</span>
								)}
							</div>
						</div>
						<div className="create-role__form__divider"></div>
						<div className="create-role__form__section"></div>
						<div className="create-role__form__section">
							<div className="create-role__form__row">
								<Checkbox
									style={{ marginLeft: -16 }}
									onClick={() => dispatch(setHideEmployeeCount(!hideEmployeeCount))}
									disabled={!canEdit}
									checked={hideEmployeeCount}
								>
									Hide employee count
								</Checkbox>
							</div>
						</div>
					</div>
				</form>
			) : (
				<div
					style={{
						width: '100%',
						height: '500px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner size={20} />
				</div>
			)}
		</div>
	);
};

export default CreateRole;
