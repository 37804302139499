import React from 'react';
import Button from '../../common/Button';
import DropdownArrow from '../../icons/DropdownArrow';
import Close from '../../icons/Close';
import ButtonTypes from '../../constants/ButtonTypes';

interface FilterButtonProps {
	label: string;
	placeholder: string;
	onClick: () => void;
	disabled?: boolean;
	isOpen: boolean;
	isSecondary?: boolean;
	allowCancel: boolean;
	onClearSelection: () => void;
}

export default function FilterButton(props: FilterButtonProps) {
	const { label, placeholder, onClick, disabled, isOpen, isSecondary, allowCancel, onClearSelection } = props;

	return (
		<Button
			type="button"
			componentType={isSecondary ? ButtonTypes.type.SECONDARY : ButtonTypes.type.PRIMARY}
			onClick={onClick}
			activated={isOpen}
			disabled={disabled}
			selected={!!label}
		>
			<span className="truncate">{label || placeholder}</span>
			{allowCancel && label ? (
				<Close width={18} height={12} onClick={onClearSelection} />
			) : (
				<DropdownArrow width={18} height={18} />
			)}
		</Button>
	);
}
