import { useEffect, useReducer, useState } from 'react';

import BarChartOverlayWrapper from '../../common/BarChartOverlayWrapper';
import BarChartWrapper from '../../common/BarChartWrapper';
import BenchmarkChartWrapper from '../../common/BenchmarkChartWrapper';
import ChartContainer from '../../common/ChartContainer';
import DashboardChartHeader from '../../common/DashboardChartHeader';
import FunnelChartWrapper from '../../common/FunnelChartWrapper';
import GenericTableWrapper from '../../common/GenericTableWrapper';
import HorizontalBarChartWrapper from '../../common/HorizontalBarChartWrapper';
import LineChartOverlayWrapper from '../../common/LineChartOverlayWrapper';
import LineChartWrapper from '../../common/LineChartWrapper';
import MatrixWrapper from '../../common/MatrixWrapper';
import PieChartWrapper from '../../common/PieChartWrapper';
import ScrollableChartArea from '../../common/ScrollableChartArea';
import SingleTimeChartWrapper from '../../common/SingleTimeChartWrapper';
import StackedBarChartOverlayWrapper from '../../common/StackedBarChartOverlayWrapper';
import StackedBarChartWrapper from '../../common/StackedBarChartWrapper';
import StatsChartWrapper from '../../common/StatsChartWrapper';
import TableWrapper from '../../common/TableWrapper';
import useInteractiveChart from '../../common/useInteractiveChart';
import ChartTypes from '../../constants/ChartTypes';
import { DashboardReport, Report } from '../../dashboard/models';
import TextChart from './TextChart';
import { textChartMachine } from './TextChartMachine';

interface IChartPickerProps {
	dashboard: any;
	report: Report;
	dashboardReport: DashboardReport;
	isLocked?: boolean;
	isHovered: boolean;
}

export default function ChartPicker(props: IChartPickerProps) {
	const { dashboard, report, dashboardReport } = props;
	const [eventsHidden, setEventsHidden] = useState(false);

	const { metricOverlay, overlayChartType } = report as any;

	const [textChartState, textChartDispatch] = useReducer(textChartMachine, {
		status: 'idle',
	});

	const { dates } = report;
	const chartType = dashboardReport?.configuration?.find((c: any) => c.name === 'chartType')?.value;
	const subtableChartType = dashboardReport?.configuration?.find((c: any) => c.name === 'subtableChartType')?.value;
	const showSubtable = dashboardReport?.configuration?.find((c: any) => c.name === 'showSubtable')?.value === 'true';

	const interactiveChart = useInteractiveChart({
		lockedSelection: chartType === ChartTypes.Funnel,
		mode: 'standalone',
	});

	const isSubtable = showSubtable || !!report?.breakdownSegment;

	const benchmark = report?.benchmark;

	if (isSubtable) {
		if (subtableChartType === ChartTypes.Bar) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<BarChartWrapper reportId={dashboardReport?.reportId} {...interactiveChart} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.Matrix || subtableChartType === ChartTypes.Matrix) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<MatrixWrapper {...interactiveChart} reportId={dashboardReport?.reportId} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.HorizontalBar || subtableChartType === ChartTypes.HorizontalBar) {
			return (
				<ChartLayout>
					<DashboardChartHeader {...props} eventsHidden={eventsHidden} setEventsHidden={setEventsHidden} />
					<HorizontalBarChartWrapper reportId={dashboardReport?.reportId} {...interactiveChart} />
				</ChartLayout>
			);
		}
	}

	if (!!metricOverlay?.data.length && overlayChartType !== chartType) {
		if (chartType === ChartTypes.Line) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<LineChartOverlayWrapper {...interactiveChart} reportId={dashboardReport?.reportId} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.Bar && overlayChartType === ChartTypes.Line) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>

					<BarChartOverlayWrapper {...interactiveChart} reportId={dashboardReport?.reportId} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.StackedBar) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<StackedBarChartOverlayWrapper {...interactiveChart} reportId={dashboardReport?.reportId} />
				</ChartLayout>
			);
		}
	}

	if (chartType === ChartTypes.Text || chartType === ChartTypes.InsightsText) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<ScrollableChartArea>
					<TextChart
						reportId={dashboardReport?.reportId}
						textChartDispatch={textChartDispatch}
						textChartState={textChartState}
						isInsights={chartType === ChartTypes.InsightsText}
					/>
				</ScrollableChartArea>
			</ChartLayout>
		);
	}
	//
	if (chartType === ChartTypes.Line) {
		if (dates.length === 1) {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<SingleTimeChartWrapper
						{...interactiveChart}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
					/>
				</ChartLayout>
			);
		} else {
			return (
				<ChartLayout>
					<DashboardChartHeader
						{...props}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
						textChartDispatch={textChartDispatch}
					/>
					<LineChartWrapper
						reportId={dashboardReport?.reportId}
						dashboardId={dashboard.id}
						{...interactiveChart}
						eventsHidden={eventsHidden}
						setEventsHidden={setEventsHidden}
					/>
				</ChartLayout>
			);
		}
	}
	if (chartType === ChartTypes.Bar) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<BarChartWrapper reportId={dashboardReport?.reportId} {...interactiveChart} />
			</ChartLayout>
		);
	}

	if (chartType === ChartTypes.Pie && !benchmark) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<PieChartWrapper
					reportId={dashboardReport?.reportId}
					{...interactiveChart}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
				/>
			</ChartLayout>
		);
	}
	if (chartType === ChartTypes.StackedBar && !benchmark) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<StackedBarChartWrapper reportId={dashboardReport?.reportId} {...interactiveChart} />
			</ChartLayout>
		);
	}
	if (benchmark) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<BenchmarkChartWrapper {...interactiveChart} reportId={dashboardReport?.reportId} />
			</ChartLayout>
		);
	}
	if (chartType === ChartTypes.Table) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>

				<TableWrapper reportId={dashboardReport?.reportId} {...interactiveChart} />
			</ChartLayout>
		);
	}

	if (chartType === ChartTypes.Stats) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<StatsChartWrapper
					reportId={dashboardReport?.reportId}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
				/>
			</ChartLayout>
		);
	}
	//
	if (chartType === ChartTypes.ArithmeticTable) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<GenericTableWrapper
					reportId={dashboardReport?.reportId}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					{...interactiveChart}
				/>
			</ChartLayout>
		);
	}

	if (chartType === ChartTypes.Funnel) {
		return (
			<ChartLayout>
				<DashboardChartHeader
					{...props}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					textChartDispatch={textChartDispatch}
				/>
				<FunnelChartWrapper
					reportId={dashboardReport?.reportId}
					eventsHidden={eventsHidden}
					setEventsHidden={setEventsHidden}
					{...interactiveChart}
				/>
			</ChartLayout>
		);
	}

	return <></>;
}

const ChartLayout = ({ children, dashboard, ...rest }: any) => {
	return <ChartContainer {...rest}>{children}</ChartContainer>;
};
