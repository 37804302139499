import { push } from 'connected-react-router';
import qs from 'qs';
import ActionTypes from '../../constants/ActionTypes';
import ChartTypes from '../../constants/ChartTypes';
import Constants from '../../constants/Constants';
import { generate, generateOverlay, getTemplateFromAnalysisName, updateUrlsQuery } from '../../store/actionUtils';
import { Template } from '../../templates/models';
import { FilterSegment, Segment, YAxisType } from '../../types';
import { ExploreChartUrlState } from '../chart/types';
import { DashboardReportUrlState, setTitle } from '../dashboardReport/reducer';
import { fetchBySegment } from '../detail/actions';
import { ExploreSubtableUrlState } from '../subtable/reducer';
import { ExploreFilterUrlState } from './reducer';

export function clearFilters(refresh = true) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.ClearFilters,
		});
		if (refresh) {
			dispatch(push('/explore'));
		}
	};
}

export function clearOverlayFilters() {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.ClearOverlayFilters,
		});
	};
}

export function generateInExplore() {
	return (dispatch: any, getState: any) => {
		const state = {
			...getState().explore.chart,
			...getState().explore.subtable,
			...getState().explore.filter,
			// intervalCount,
			// startDate,
			// endDate,
			// date,
			// analysisDisplayName,
			// analysisName,
			// subAnalyses,
			// mainSegments,
			// filterSegment,
			// breakdownSegment,
			// attributes,
			// showSubtable,
			// eventIds: [],
			// filterNames,
			// breakdownNames,
			// mainNames,
			// ...query.filter,
			// ...query.subtable,
			// ...query.chart,
		};

		const actionTypes = {
			reportPending: ActionTypes.GeneratePending,
			subtablePending: ActionTypes.GenerateSubtablePending,
			reportRejected: ActionTypes.GenerateRejected,
			invalidVariation: ActionTypes.GenerateInvalidVariation,
			noData: ActionTypes.GenerateNoData,
			subtableFulfilled: ActionTypes.GenerateSubtableFulfilled,
			reportFulfilled: ActionTypes.GenerateFulfilled,
			setSections: ActionTypes.SetDynamicSections,
			clearSections: ActionTypes.ClearDynamicSections,
			primaryDataFulfilled: ActionTypes.GeneratePrimaryDataFulfilledInExplore,
		};

		return generate(getState, dispatch, state, actionTypes).then(() => {
			// dispatch(getCommentCount());

			const noData = getState().explore.chart.generateNoData;
			getState().explore.detail.dynamic.sections.forEach((section: any) => {
				const { open, employeeAttribute } = section;
				if (open) {
					if (noData) {
						dispatch({
							type: ActionTypes.CloseSegment,
							employeeAttribute: employeeAttribute,
						});
					} else {
						dispatch(fetchBySegment(employeeAttribute));
					}
				}
			});
		});
	};
}

export function generateOverlayInExplore() {
	return (dispatch: any, getState: any) => {
		const {
			attributes,
			endDate,
			interval,
			intervalCount,
			overlayAnalysisName,
			overlayChartType,
			overlayFilterSegment,
			overlayMainSegments,
			startDate,
		} = getState().explore.filter;

		const state = {
			analysisName: overlayAnalysisName,
			attributes,
			chartType: overlayChartType,
			endDate,
			filterSegment: overlayFilterSegment,
			interval,
			intervalCount,
			mainSegments: overlayMainSegments,
			startDate,
		};

		const actionTypes = {
			reportPending: ActionTypes.GenerateMetricOverlayPending,
			reportRejected: ActionTypes.GenerateMetricOverlayRejected,
			noData: ActionTypes.GenerateMetricOverlayNoData,
			reportFulfilled: ActionTypes.GenerateMetricOverlayFulfilled,
		};

		return generateOverlay(getState, dispatch, state, actionTypes).then(() => {
			const noData = getState().explore.chart.generateNoData;
			getState().explore.detail.dynamic.sections.forEach((section: any) => {
				const { open, employeeAttribute } = section;
				if (open) {
					if (noData) {
						dispatch({
							type: ActionTypes.CloseSegment,
							employeeAttribute: employeeAttribute,
						});
					} else {
						dispatch(fetchBySegment(employeeAttribute));
					}
				}
			});
		});
	};
}

export interface ExploreUrlState {
	filter: ExploreFilterUrlState;
	chart: ExploreChartUrlState;
	dashboard: DashboardReportUrlState;
	subtable: ExploreSubtableUrlState;
}

export function navigate() {
	return (dispatch: any, getState: any) => {
		const {
			analysisName,
			breakdownSegment,
			endDate,
			filterSegment,
			interval,
			intervalCount,
			mainSegments,
			overlayAnalysisName,
			overlayChartType,
			overlayFilterSegment,
			overlayMainSegments,
			overlaySubAnalyses,
			overlayIndexType,
			overlayYAxisType,
			startDate,
			subAnalyses,
			date,
			analysisDisplayName,
			isSavedSearch,
			overlayAnalysisDisplayName,
			overlayAnalysisType,
			overlayDisplay,
			overlayFilterNames,
			overlayColumnProps,
			overlayMainNames,
			showOverlaySubtable,
		} = getState().explore.filter;

		const {
			yAxisType,
			currentIndex,
			indexType,
			chartType,
			eventIds,
			columnProps,
			allowIndexToggle,
			allowYAxisToggle,
		} = getState().explore.chart;
		const {
			subtableChartType,
			analysisType: subtableAnalysisType,
			date: subtableDate,
			columnProps: subtableColumnProps,
			interval: subtableInterval,
			allowChartToggle,
		} = getState().explore.subtable;

		const queryObject: ExploreUrlState = {
			filter: {
				analysisName,
				analysisDisplayName,
				breakdownSegment,
				showSubtable: !!breakdownSegment,
				date,
				endDate,
				filterSegment,
				interval,
				intervalCount,
				isSavedSearch,
				overlayAnalysisDisplayName,
				overlayAnalysisName,
				overlayAnalysisType,
				overlayChartType,
				overlayDisplay,
				overlayFilterNames,
				overlayColumnProps,
				overlayFilterSegment,
				overlayIndexType,
				overlayYAxisType,
				overlayMainNames,
				overlayMainSegments,
				showOverlaySubtable,
				overlaySubAnalyses,
				startDate,
				subAnalyses,
				mainSegments,
			},
			chart: {
				lifecycle: analysisName,
				yAxisType,
				indexType,
				chartType,
				allowIndexToggle,
				allowYAxisToggle,
				eventIds,
				columnProps,
				currentIndex,
			},
			subtable: {
				subtableChartType,
				analysisType: subtableAnalysisType,
				date: subtableDate,
				columnProps: subtableColumnProps,
				interval: subtableInterval,
				allowChartToggle,
			},
			dashboard: {
				...getState().explore.dashboardReport,
			},
		};

		if (startDate) {
			queryObject.filter.startDate = startDate;
			queryObject.filter.endDate = endDate;
		} else if (intervalCount !== Constants.DefaultIntervalCount) {
			queryObject.filter.intervalCount = intervalCount;
		}

		if (interval !== Constants.DefaultInterval) {
			queryObject.filter.interval = interval;
		}

		let url = '/explore?';

		const queryString = qs.stringify(queryObject, {
			encodeValuesOnly: true,
		});

		url += queryString;
		dispatch(push(url));
	};
}

export function setAnalysisName({ analysisDisplayName, analysisName, subAnalyses }: any) {
	return (dispatch: any) => {
		if (!analysisName) {
			return dispatch(clearFilters());
		}

		return dispatch(getTemplateFromAnalysisName(analysisName)).then((template: Template) => {
			const { mainNames, filterNames, breakdownNames, variations, analysisType, supportedGraphs } = template;
			dispatch({
				type: ActionTypes.SetAnalysisName,
				analysisDisplayName,
				analysisName,
				analysisType,
				subAnalyses,
				mainNames,
				filterNames,
				breakdownNames,
				variations,
				supportedGraphs,
			});
		});
	};
}

export function setOverlayAnalysisName({ analysisDisplayName, analysisName, subAnalyses }: any) {
	return (dispatch: any) => {
		if (!analysisName) {
			return dispatch(clearOverlayFilters());
		}

		return dispatch(getTemplateFromAnalysisName(analysisName)).then((template: any) => {
			const { analysisType, filterNames, mainNames } = template;

			dispatch({
				type: ActionTypes.SetOverlayAnalysisName,
				overlayAnalysisDisplayName: analysisDisplayName,
				overlayAnalysisName: analysisName,
				overlayAnalysisType: analysisType,
				overlayFilterNames: filterNames,
				overlayMainNames: mainNames,
				overlaySubAnalyses: subAnalyses,
			});
		});
	};
}

export function setOverlayChartType(overlayChartType: ChartTypes) {
	return (dispatch: any, getState: any) => {
		const { overlayAnalysisName, overlayMainSegments } = getState().explore.filter;

		if (!overlayAnalysisName || !overlayMainSegments?.length) {
			dispatch(updateUrlsQuery('overlayChartType', overlayChartType));
		}

		dispatch({
			type: ActionTypes.SetOverlayChartType,
			overlayChartType,
		});
	};
}

export function updateMainSegments(fields: Segment[]) {
	return {
		type: ActionTypes.UpdateMainSegments,
		fields,
	};
}

export function updateOverlayMainSegments(fields: Segment[]) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.UpdateOverlayMainSegments,
			fields,
		});
	};
}

export function updateFilterSegment(fields: FilterSegment[]) {
	return {
		type: ActionTypes.UpdateFilterSegment,
		segment: fields.length ? fields[0] : undefined,
	};
}

export function updateOverlayFilterSegment(fields: FilterSegment[]) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.UpdateOverlayFilterSegment,
			segment: fields.length ? fields[0] : undefined,
		});
	};
}

export function updateBreakdownSegment(fields: Segment[]) {
	return {
		type: ActionTypes.UpdateBreakdownSegment,
		segment: fields.length ? fields[0] : undefined,
	};
}

export function setOverlayIndexType(overlayIndexType: YAxisType) {
	return (dispatch: any, getState: any) => {
		const { overlayAnalysisName, overlayMainSegments } = getState().explore.filter;

		if (!overlayAnalysisName || !overlayMainSegments?.length) {
			dispatch(updateUrlsQuery('overlayIndexType', overlayIndexType));
		}

		dispatch({
			type: ActionTypes.SetOverlayIndexType,
			overlayIndexType,
		});
	};
}

export function setOverlayYAxisType(overlayYAxisType: YAxisType) {
	return (dispatch: any, getState: any) => {
		const { overlayAnalysisName, overlayMainSegments } = getState().explore.filter;

		if (!overlayAnalysisName || !overlayMainSegments?.length) {
			dispatch(updateUrlsQuery('overlayYAxisType', overlayYAxisType));
		}

		dispatch({
			type: ActionTypes.SetOverlayYAxisType,
			overlayYAxisType,
		});
	};
}

export function syncExploreFilterState(filters: Partial<ExploreFilterUrlState>) {
	return async (dispatch: any, getState: any) => {
		const { subAnalyses, analysisName } = filters;

		let analysisDisplayName: any =
			subAnalyses?.length > 1
				? `${analysisName} (${subAnalyses.length})`
				: subAnalyses?.length === 1
				? subAnalyses[0].name
				: analysisName;

		if (analysisName !== getState().explore.filter.analysisName) {
			dispatch(
				setAnalysisName({
					analysisDisplayName,
					analysisName,
					subAnalyses: subAnalyses || [],
				})
			);
		}

		return dispatch({
			type: ActionTypes.SyncExploreFilterState,
			payload: filters,
		});
	};
}

export function setShowSubtable(showSubtable: boolean) {
	return (dispatch: any) => {
		dispatch({
			type: ActionTypes.SetShowSubtable,
			showSubtable,
		});
	};
}
