import ChartTypes from '../constants/ChartTypes';
import BarChartWrapper from './BarChartWrapper';
import LineChartWrapper from './LineChartWrapper';
import SingleTimeChartWrapper from './SingleTimeChartWrapper';
import StackedBarChartWrapper from './StackedBarChartWrapper';
import PieChartWrapper from './PieChartWrapper';
import GenericTableWrapper from './GenericTableWrapper';
import FunnelChartWrapper from './FunnelChartWrapper';
import BenchmarkChartWrapper from './BenchmarkChartWrapper';
import StackedBarChartOverlayWrapper from './StackedBarChartOverlayWrapper';
import BarChartOverlayWrapper from './BarChartOverlayWrapper';
import LineChartOverlayWrapper from './LineChartOverlayWrapper';
import SubtableWrapper from './SubtableWrapper';
import StatsChartWrapper from './StatsChartWrapper';
import ChartContainer from './ChartContainer';
import ChartHeader from './ChartHeader/ChartHeader';
import HorizontalBarChartWrapper from './HorizontalBarChartWrapper';
import useInteractiveChart from './useInteractiveChart';
import { useAppSelector } from '../store/hooks';
import TableWrapper from './TableWrapper';

const ChartPicker = (props: any) => {
	const { height } = props;

	const { overlayChartType, showSubtable } = useAppSelector(state => state.explore.filter);
	const { metricOverlay, benchmark, chartType, dates } = useAppSelector(state => state.explore.chart);
	const { subtableChartType } = useAppSelector(state => state.explore.subtable);

	const interactiveChart = useInteractiveChart({
		lockedSelection: false,
		mode: 'explore',
	});

	if (height === 0) {
		return <div className="explore-chart__empty"></div>;
	}

	if (showSubtable) {
		if (subtableChartType === ChartTypes.Bar) {
			return (
				<ChartLayout>
					<BarChartWrapper {...interactiveChart} />
				</ChartLayout>
			);
		} else if (subtableChartType === ChartTypes.Matrix) {
			return (
				<ChartLayout>
					<SubtableWrapper {...interactiveChart} />
				</ChartLayout>
			);
		} else if (subtableChartType === ChartTypes.HorizontalBar) {
			return (
				<ChartLayout>
					<HorizontalBarChartWrapper {...interactiveChart} />
				</ChartLayout>
			);
		}
	}

	if (dates.length === 1 && chartType === ChartTypes.Line) {
		return (
			<ChartLayout
				style={{
					minHeight: '250px',
				}}
			>
				<SingleTimeChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	}
	if (!!metricOverlay?.data.length && overlayChartType !== chartType) {
		if (chartType === ChartTypes.Line) {
			return (
				<ChartLayout
					style={{
						minHeight: '250px',
					}}
				>
					<LineChartOverlayWrapper {...interactiveChart} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.Bar && overlayChartType === ChartTypes.Line) {
			return (
				<ChartLayout>
					<BarChartOverlayWrapper {...interactiveChart} />
				</ChartLayout>
			);
		} else if (chartType === ChartTypes.StackedBar && !benchmark) {
			return (
				<ChartLayout>
					<StackedBarChartOverlayWrapper {...interactiveChart} />
				</ChartLayout>
			);
		}
	}

	if (chartType === ChartTypes.Line) {
		return (
			<ChartLayout>
				<LineChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.Bar) {
		return (
			<ChartLayout>
				<BarChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.Pie && !benchmark) {
		return (
			<ChartLayout>
				<PieChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.StackedBar && !benchmark) {
		return (
			<ChartLayout>
				<StackedBarChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.Stats) {
		return (
			<ChartLayout>
				<StatsChartWrapper />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.ArithmeticTable) {
		return (
			<ChartLayout>
				<GenericTableWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.Funnel) {
		return (
			<ChartLayout>
				<FunnelChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (chartType === ChartTypes.Table) {
		return (
			<ChartLayout>
				<TableWrapper {...interactiveChart} />
			</ChartLayout>
		);
	} else if (benchmark) {
		return (
			<ChartLayout>
				<BenchmarkChartWrapper {...interactiveChart} />
			</ChartLayout>
		);
	}

	return <></>;
};

const ChartLayout = ({ children, ...rest }: any) => {
	return (
		<ChartContainer
			style={{
				overflow: 'hidden',
			}}
			{...rest}
		>
			<ChartHeader />
			{children}
		</ChartContainer>
	);
};

export default ChartPicker;
