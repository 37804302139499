import * as Tooltip from '@radix-ui/react-tooltip';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import produce from 'immer';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import {
	ARROW_CIRCLE_RADIUS,
	AXIS_FONT_SIZE,
	barTypes,
	LARGE_TEXT_FONT_SIZE,
	PADDING,
	TEXT_FONT_SIZE,
	X_AXIS_PADDING,
	Y_SMALL_PADDING,
} from '../editor/chart/constants';
import {
	getBarOpacity,
	getCenterY,
	getFormattedDate,
	getIsFaded,
	getLeftArrowPoints,
	getLeftCenterX,
	getRightArrowPoints,
	getRightCenterX,
	getStackedTextOpacity,
	getStackedYAxisValues,
	getTopBarHeight,
	getTopBarOpacity,
	getYAxisRangeValues,
	getYAxisValues,
	transformStackedData,
} from '../lib/chartUtils';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { useAppSelector } from '../store/hooks';
import { IndexType, ISegmentData, YAxisType } from '../types';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 24;
const MIN_GROUP_SPACING = 80;
const RADIUS = 4;

const pageSizes = [0, 6, 4, 2, 1];

interface IStackedBarChartOverlayProps {
	analysisType?: any;
	onHoverEnd?: any;
	columnProps?: any;
	currentIndex?: any;
	dashboardName?: any;
	data?: ISegmentData[];
	dataOverlayProp?: any;
	dataProp?: any;
	dates?: Date[];
	dateSelection?: any;
	employeeCountHidden?: any;
	height?: any;
	indexType?: any;
	interval?: any;
	metricOverlayLifecycle: string;
	overlayData?: ISegmentData[];
	overlayIndexType: IndexType;
	overlayYAxisType: YAxisType;
	reportId?: any;
	selectCurrentTime?: any;
	onHover?: any;
	title?: any;
	onToggle?: any;
	width?: any;
	yAxisType: YAxisType;
	hoveredItem?: string;
	selection: string[];
}

export default function StackedBarChartOverlay(props: IStackedBarChartOverlayProps) {
	const {
		analysisType,
		onHoverEnd,
		columnProps,
		currentIndex,
		dashboardName,
		dataOverlayProp,
		dataProp,
		dates,
		dateSelection,
		employeeCountHidden,
		height,
		indexType,
		interval,
		metricOverlayLifecycle,
		overlayData = [],
		overlayIndexType,
		overlayYAxisType,
		reportId,
		selectCurrentTime,
		onHover,
		title,
		onToggle,
		width,
		yAxisType,
		hoveredItem,
		selection,
	} = props;
	let { data = [] } = props;

	const { overlayChartType } = useAppSelector(state => state.explore.filter);
	const { metricOverlay } = useAppSelector(state => state.explore.chart);

	const overlayAnalysisType = metricOverlay?.analysisType;
	const overlayColumnProps = metricOverlay?.columnProps ?? [];

	if (!data?.length || !overlayData.length || !dates?.length) {
		return null;
	}

	const isActive = [...data, ...overlayData].some((d: any) => {
		const isActive = d.label === hoveredItem;
		const isSelected = selection.includes(d.label);
		return isActive || isSelected;
	});
	const isLinearRegression =
		analysisType === AnalysisTypes.LinearRegression || overlayAnalysisType === AnalysisTypes.LinearRegression;
	const isIndexed = analysisType === AnalysisTypes.Index || overlayAnalysisType === AnalysisTypes.Index;
	const showIndexLine = isActive && (isLinearRegression || isIndexed);

	const isFaded = (item: any) => getIsFaded(item, isActive, selection, hoveredItem);

	const data2 = useMemo(() => {
		return data.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex);
	}, [data]);
	const overlayData2: ISegmentData[] = useMemo(() => {
		return overlayData
			.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex)
			.map(d => ({
				...d,
				series: d.series.filter(s =>
					dates.some(date => new Date(date).toDateString() === new Date(s.date).toDateString())
				),
			}));
	}, [dates, overlayData]);

	const [barType, setBarType] = useState<string>();
	const [barWidth, setBarWidth] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [startIndex, setStartIndex] = useState<number>(0);
	const [hoverLabel, setHoverLabel] = useState<string>();
	const [hoverIndex, setHoverIndex] = useState<number>();
	const [barHovered, setBarHovered] = useState(false);
	const firstLoadRef = useRef({ firstLoad: true });

	useEffect(() => {
		const barCount = data2.length;
		const availableWidth = width - 2 * PADDING;
		let configFound = false;
		pageSizes.forEach(ps => {
			if (configFound) return;
			if (ps > dates.length) return;
			barTypes.forEach((bt, j) => {
				if (configFound) return;
				const groupCount = ps === 0 ? dates.length : ps;
				const maxGraphWidth = bt.max * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				const minGraphWidth = bt.min * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				let barWidth;
				if (j === 0 && maxGraphWidth < availableWidth) {
					barWidth = bt.max;
					configFound = true;
				} else if (minGraphWidth <= width && maxGraphWidth > availableWidth) {
					barWidth = (availableWidth - (groupCount - 1) * MIN_GROUP_SPACING) / (barCount * groupCount);
					configFound = true;
				}
				if (configFound && barWidth) {
					barWidth && setBarWidth(barWidth);
					setBarType(bt.type);
					setPageSize(ps);
				}
			});
		});
	}, [width, dates.length, data2.length]);

	useEffect(() => {
		if (barWidth > 0) {
			firstLoadRef.current.firstLoad = false;
		}
	}, [barWidth]);

	function handleToggle(label: any) {
		track(MixPanel.Events.DashboardReportStackedBarChartSegmentClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		onToggle(label);
	}

	function handleTimeClick(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		selectCurrentTime(index);
		track(MixPanel.Events.DashboardReportStackedBarChartXAxisClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handlePrevious(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (startIndex === 0) return;
		setStartIndex(startIndex - 1);
		track(MixPanel.Events.DashboardReportStackedBarChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handleNext(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (!dates?.length) return;
		if (startIndex + pageSize >= dates.length) return;
		setStartIndex(startIndex + 1);
		track(MixPanel.Events.DashboardReportStackedBarChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handleMouseOverOnPoint(label: string, index: number) {
		setHoverIndex(index);
		setHoverLabel(label);
	}

	function handleMouseOutOnPoint() {
		setHoverIndex(undefined);
		setHoverLabel(undefined);
	}

	function handleClickOnPoint(_: any, index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (index !== currentIndex) {
			track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
				'Dashboard Name': dashboardName,
				'Report Name': title,
			});

			setHoverIndex(undefined);
			setHoverLabel(undefined);
			selectCurrentTime(index);
		}
	}

	const { paginatedData, paginatedOverlayData } = useMemo(() => {
		let paginatedData = data2;
		if (pageSize !== 0) {
			paginatedData = produce(paginatedData, (draftState: any) => {
				draftState.forEach((item: any) => {
					item.series = item.series.slice(startIndex, startIndex + pageSize);
				});
			});
		}

		if (yAxisType === YAxisType.Count) {
			paginatedData = produce(paginatedData, (draftState: any) => {
				draftState.forEach((item: any, i: number) => {
					item.series.forEach((s: any, j: number) => {
						const total = paginatedData.reduce((sum: number, curr: any) => {
							sum += curr.series[j][dataProp];
							return sum;
						}, 0);

						if (i > 0) {
							s.previous = draftState[i - 1].series[j].previous + draftState[i - 1].series[j][dataProp];
							s.total = total;
						} else {
							s.previous = 0;
							s.total = total;
						}
						s.color = item.color;
					});
				});
			});
		} else {
			paginatedData = produce(paginatedData, (draftState: any) => {
				draftState.forEach((item: any, i: number) => {
					item.series.forEach((s: any, j: number) => {
						if (i > 0) {
							s.previous = draftState[i - 1].series[j].previous + draftState[i - 1].series[j].percentage;
							s.total = s.previous + s.percentage;
						} else {
							s.previous = 0;
							s.total = s.percentage;
						}
						s.color = item.color;
					});
				});
			});
		}

		paginatedData = transformStackedData(paginatedData);

		let paginatedOverlayData = overlayData2;

		if (pageSize !== 0) {
			paginatedOverlayData = produce(overlayData2, (draftState: any) => {
				draftState.forEach((item: any) => {
					item.series = item.series.slice(startIndex, startIndex + pageSize);
				});
			});
		}

		return { paginatedData, paginatedOverlayData };
	}, [data2, overlayData2, pageSize, startIndex]);

	let paginatedDates = dates.slice();
	if (pageSize !== 0) {
		paginatedDates = dates.slice(startIndex, startIndex + pageSize);
	}

	const { xScale, yScale, overlayYScale, maxValue, yAxisRangeValues, overlayYAxisRangeValues } = useMemo(() => {
		const xScale = scaleBand()
			.domain(paginatedDates as any as string[])
			.range([PADDING * 2.5, width - PADDING * 2.5]);

		let maxArray: any[] = [];

		if (yAxisType === YAxisType.Count) {
			maxArray = Array.from({ length: data[0].series.length }).map((_, i) => {
				let countByDate = 0;

				data.forEach((d: any) => {
					countByDate += d.series[i][dataProp];
				});

				return countByDate;
			});
		} else {
			data.forEach((d: any) => {
				maxArray.push(max(d.series, (s: any) => s[dataProp]));
			});
		}
		maxArray = maxArray.filter((d: any) => d !== Constants.InvalidValue);
		const maxValue = max(maxArray);

		let overlayYAxisValues: [number, number] = [0, 0];

		const overlayMinArray = overlayData.map((d: any) =>
			min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
		);
		const overlayMaxArray = overlayData.map((d: any) =>
			max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
		);
		const overlayMinValue = Math.floor(min(overlayMinArray as any as number[]) as number);
		const overlayMaxValue = Math.ceil(max(overlayMaxArray as any as number[]) as number);

		const yAxisValues = getStackedYAxisValues(maxValue, yAxisType);

		if (
			analysisType === overlayAnalysisType &&
			((overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType === yAxisType) ||
				(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === indexType))
		) {
			const maxOverallValue = max([maxValue, overlayMaxValue]);

			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: maxOverallValue!,
				minValue: 0,
				yAxisType: overlayYAxisType,
			});
		} else {
			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: overlayMaxValue,
				minValue: overlayMinValue,
				yAxisType: overlayYAxisType,
			});
		}

		const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType);

		const range = [height - PADDING - AXIS_PADDING, barType === 'small' ? Y_SMALL_PADDING : PADDING];

		const yScale = scaleLinear().domain(yAxisValues).range(range);

		const overlayYAxisRangeValues = getYAxisRangeValues(overlayYAxisValues, overlayYAxisType);

		const overlayYScale = scaleLinear().domain(overlayYAxisValues).range(range);

		return { xScale, yScale, overlayYScale, maxValue, yAxisRangeValues, overlayYAxisRangeValues };
	}, [
		barType,
		data,
		dataOverlayProp,
		dataProp,
		height,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayData,
		overlayIndexType,
		overlayYAxisType,
		paginatedDates,
		width,
	]);

	const avgValue = useMemo(() => {
		const minArray: any[] = [];
		const maxArray: any[] = [];
		paginatedOverlayData.forEach((d: any) => {
			minArray.push(min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
			maxArray.push(max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
		});
		const minValue = min(minArray);
		const maxValue = max(maxArray);

		return (minValue + maxValue) / 2;
	}, [dataOverlayProp, paginatedOverlayData]);

	const selectedDate = getFormattedDate(dates[currentIndex], interval ?? 'Month');

	if (barWidth === 0 || height === 0) {
		return null;
	}

	const overlayLineFn =
		line()
			.x((d: any) => xScale(d.date)! + xScale.bandwidth() / 2)
			.y((d: any) => overlayYScale(precisionRound(d[dataOverlayProp], 1)))
			.curve(curveMonotoneX) || undefined;

	const getYScale = (scale: number) =>
		analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index
			? yScale(scale)
			: overlayYScale(scale);
	return (
		<Tooltip.Provider>
			<svg
				className={cn('barchart', dashboardName && 'absolute inset-0')}
				width={width}
				height={height}
				id={reportId}
				data-export-type="stackedbar"
			>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				{isLinearRegression && (
					<rect
						className="barchart__linear-regression-background"
						x={0}
						y={getYScale(0)}
						width={width}
						height={height - getYScale(0)}
					/>
				)}
				{isIndexed && (
					<rect
						className="barchart__index-background"
						x={0}
						y={getYScale(1)}
						width={width}
						height={height - getYScale(1)}
						fill="#1f1f21"
					/>
				)}
				{paginatedData.map((d: any) => (
					<g key={d.label} className="barchart__bar">
						<NodeGroup
							keyAccessor={d2 => `${d.label}_${d2.date}`}
							data={d.series.slice()}
							start={d2 => {
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}
								return {
									x,
									y: height - PADDING - AXIS_PADDING,
									height: 0,
									topBarHeight: 0,
									barOpacity: 1,
									topBarOpacity: 0,
									textOpacity: 0,
								};
							}}
							enter={(d2, i) => {
								const delay = firstLoadRef.current.firstLoad ? 0 : Constants.AnimationDuration;
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}

								const barValue = precisionRound(d2[dataProp], 1);
								let barHeight;
								let startY;
								const isValueZero = barValue === 0;

								if (yAxisType === YAxisType.Count) {
									startY = yScale(maxValue - d2.previous - (maxValue - d2.total));
									barHeight = isValueZero ? 1 : height - yScale(barValue) - PADDING - AXIS_PADDING;
								} else {
									startY = yScale(100 - d2.previous);
									barHeight = yScale(100 - barValue) - PADDING;
									if (barType === 'small') {
										barHeight = barHeight - AXIS_PADDING;
									}
								}

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										topBarOpacity: [getTopBarOpacity(d, data2, selection, hoveredItem)],
										timing: {
											delay: delay,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
									{
										textOpacity: [
											getStackedTextOpacity({
												item: d,
												currentIndex: i,
												data,
												dataProp,
												height,
												yAxisType,
												yScale,
												barType,
												hoveredItem,
												selection,
											}),
										],
										timing: {
											delay: Constants.AnimationDuration,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							update={(d2, i) => {
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}

								const barValue = precisionRound(d2[dataProp], 1);
								let barHeight;
								let startY;
								const isValueZero = barValue === 0;

								if (yAxisType === YAxisType.Count) {
									startY = yScale(maxValue - d2.previous - (maxValue - d2.total));
									barHeight = isValueZero ? 1 : height - yScale(barValue) - PADDING - AXIS_PADDING;
								} else {
									startY = yScale(100 - d2.previous);
									barHeight = yScale(100 - barValue) - PADDING;
									if (barType === 'small') {
										barHeight = barHeight - AXIS_PADDING;
									}
								}

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										barOpacity: [getBarOpacity(d, data2, selection, hoveredItem)],
										topBarOpacity: [getTopBarOpacity(d, data2, selection, hoveredItem)],
										textOpacity: [
											getStackedTextOpacity({
												item: d,
												currentIndex: i,
												data,
												dataProp,
												height,
												yAxisType,
												yScale,
												barType,
												hoveredItem,
												selection,
											}),
										],
										timing: {
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<Fragment>
									{nodes
										.slice(0)
										.reverse()
										.map(({ state, data, key }) => {
											if (
												d.isEqualityIndex ||
												d.isAttritionIndex ||
												data[dataProp] === Constants.InvalidValue
											) {
												return null;
											}

											let formattedText;
											let xText;
											let yText;
											let showText = true;
											const isLinearRegression = analysisType === AnalysisTypes.LinearRegression;
											const isValueNegative = data[dataProp] < 0;

											formattedText =
												yAxisType === YAxisType.Count
													? data.totalCount || data.includedCount
													: getFormattedValue(analysisType, data[dataProp]);

											xText =
												isLinearRegression && isValueNegative
													? state.x + barWidth - 2
													: state.x + 2;

											let startY;
											let barHeight;
											const barValue = precisionRound(data[dataProp], 1);

											if (yAxisType === YAxisType.Count) {
												startY = yScale(maxValue - data.previous - (maxValue - data.total));

												barHeight = height - yScale(barValue);

												yText = startY + barHeight / 2 - PADDING;
											} else {
												startY = yScale(100 - data.previous);

												barHeight = yScale(100 - barValue);

												yText =
													barType === 'small'
														? startY + barHeight / 2 - PADDING
														: startY + barHeight / 2 - 4;
											}

											if (yText > height - 12 - PADDING - AXIS_PADDING) {
												yText = height - 12 - PADDING - AXIS_PADDING;
											}

											const barCount = data2.length;
											let groupWidth = (barWidth - PADDING + 8) * barCount;

											return (
												<Fragment key={key}>
													{state.height > 0 && (
														<rect
															x={state.x}
															y={state.y}
															width={groupWidth}
															height={state.height}
															fill={data.color}
															opacity={state.barOpacity}
														/>
													)}

													<Tooltip.Root
														delayDuration={0}
														open={barHovered && d.label === hoveredItem && state.active}
													>
														<Tooltip.Trigger asChild>
															<g>
																<rect
																	width={groupWidth}
																	x={state.x}
																	y={state.y}
																	height={state.topBarHeight}
																	fill={data.color}
																	opacity={state.topBarOpacity}
																/>
																{showText && !employeeCountHidden && (
																	<circle
																		cx={xText + groupWidth + 10}
																		r={3}
																		cy={yText - 4}
																		height={10}
																		fill={data.color}
																		opacity={state.textOpacity}
																		cursor="pointer"
																		onMouseOver={() => {
																			onHover(d.label);
																			setBarHovered(true);
																		}}
																		onMouseOut={() => {
																			onHoverEnd(null, d.label);
																			setBarHovered(false);
																		}}
																		onClick={() => handleToggle(d.label)}
																	/>
																)}

																<rect
																	width={groupWidth}
																	x={state.x}
																	y={state.y}
																	height={state.height}
																	fill="transparent"
																	cursor="pointer"
																	onMouseOver={() => {
																		onHover(d.label);
																		setBarHovered(true);
																	}}
																	onMouseOut={() => {
																		onHoverEnd(d.label);
																		setBarHovered(false);
																	}}
																	onClick={() => handleToggle(d.label)}
																/>
															</g>
														</Tooltip.Trigger>
														<Tooltip.Portal>
															<Tooltip.Content side="bottom" sideOffset={16}>
																<ChartTooltip
																	dataPoint={d.label === 'OtherXYZ' ? undefined : d}
																	seriesItem={data}
																	columnProps={columnProps}
																	interval={interval}
																/>
															</Tooltip.Content>
														</Tooltip.Portal>
													</Tooltip.Root>

													{showText && !employeeCountHidden && (
														<text
															width={barWidth}
															x={xText + groupWidth + 20}
															y={yText}
															textAnchor={'start'}
															height={state.height}
															style={{
																fontFamily: FontFamilies.Regular,
																fontSize:
																	barType === 'large'
																		? LARGE_TEXT_FONT_SIZE
																		: TEXT_FONT_SIZE,
															}}
															fill="var(--color-ui-100)"
															opacity={state.textOpacity}
															cursor="pointer"
															onMouseOver={() => onHover(d.label)}
															onMouseOut={() => onHoverEnd()}
															onClick={() => handleToggle(d.label)}
														>
															{formattedText}
														</text>
													)}
												</Fragment>
											);
										})}
								</Fragment>
							)}
						</NodeGroup>
					</g>
				))}
				{!!overlayData2.length && overlayChartType === ChartTypes.Line && (
					<>
						<NodeGroup
							keyAccessor={(d: any) => d.label}
							data={paginatedOverlayData}
							start={(d: any) => {
								const initial = d.series.map(({ date }: { date: Date }) => ({
									[dataProp]: avgValue,
									date,
								}));
								return {
									opacity: 1,
									series: initial,
								};
							}}
							enter={(d: any) => {
								return {
									series: [d.series],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							update={(d: any) => {
								return {
									series: [d.series],
									opacity: [isFaded(d) ? 0.05 : 1],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<g className="linechart__lines">
									{nodes.map(
										({ state, data, key }) =>
											!data.isEqualityIndex &&
											!data.isAttritionIndex && (
												<g data-label="line" key={key}>
													<path
														className="linechart__lines__line"
														d={overlayLineFn?.(state.series) as string | undefined}
														strokeOpacity={state.opacity}
														stroke={data.color}
														clipPath={`url(#${reportId}_main)`}
														onMouseOver={() => onHover(data.label)}
														onMouseOut={() => onHoverEnd()}
														onClick={() => handleToggle(data.label)}
														data-label="line-primary"
													/>
													<path
														className="linechart__lines__line"
														d={overlayLineFn?.(state.series) as string | undefined}
														strokeOpacity={0.05}
														stroke={data.color}
														clipPath={`url(#${reportId}_fade)`}
														data-label="line-secondary"
													/>
												</g>
											)
									)}
								</g>
							)}
						</NodeGroup>
						<NodeGroup
							keyAccessor={(d: any) => d.label}
							data={paginatedOverlayData}
							start={(d: any) => {
								const initial = d.series.map(({ date }: { date: Date }) => ({
									[dataProp]: avgValue,
									date,
								}));
								return {
									opacityCircle: 0,
									opacityText: 0,
									series: initial,
								};
							}}
							enter={(d: any) => {
								return {
									opacityCircle: [1],
									opacityText: [1],
									series: [d.series],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							update={(d: any) => {
								return {
									opacityCircle: isFaded(d) ? [0.05] : [1],
									opacityText: isFaded(d) ? [0.24] : [1],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<g className="linechart__circles">
									{nodes.map(({ state, data, key }) => {
										if (data.isEqualityIndex || data.isAttritionIndex) {
											return null;
										}
										const currentDate = getFormattedDate(dates[currentIndex], interval);
										const item = state.series.find(
											(s: any) => getFormattedDate(s.date, interval) === currentDate
										);
										let anotherItem;
										if (currentIndex !== dates.length - 1) {
											const lastDate = getFormattedDate(dates[dates.length - 1], interval);
											anotherItem = state.series.find(
												(s: any) => getFormattedDate(s.date, interval) === lastDate
											);
										}
										const cx = (item: any) => xScale(item.date)! + xScale.bandwidth() / 2;

										return (
											<Fragment key={key}>
												{item && (
													<g data-label="data-point-current-index">
														<circle
															className="linechart__circles__circle"
															stroke="var(--color-shade-h4)"
															fill="var(--color-shade-h4)"
															style={{
																pointerEvents: 'none',
															}}
															cx={cx(item)}
															cy={overlayYScale(precisionRound(item[dataOverlayProp], 1))}
															r={RADIUS}
															data-label="data-point-mask"
														/>
														<circle
															className="linechart__circles__circle"
															fill={data.color}
															cx={cx(item)}
															cy={overlayYScale(precisionRound(item[dataOverlayProp], 1))}
															r={RADIUS}
															// cursor="pointer"
															opacity={state.opacityCircle}
															style={{
																pointerEvents: 'none',
															}}
															data-label="data-point-primary"
														/>
														<Tooltip.Root
															delayDuration={0}
															open={
																data.label === hoverLabel && hoverIndex === currentIndex
															}
														>
															<Tooltip.Trigger asChild>
																<circle
																	className="linechart__circles__circle"
																	fill={data.color}
																	cx={cx(item)}
																	cy={overlayYScale(
																		precisionRound(item[dataOverlayProp], 1)
																	)}
																	r={RADIUS * 2}
																	cursor="pointer"
																	opacity={0}
																	onMouseOver={() => {
																		handleMouseOverOnPoint(
																			data.label,
																			currentIndex
																		);
																		onHover(data.label);
																	}}
																	onMouseOut={() => {
																		handleMouseOutOnPoint();
																		onHoverEnd();
																	}}
																	onClick={handleToggle.bind(null, data.label)}
																	data-label="data-point-hover-trigger"
																/>
															</Tooltip.Trigger>
															<Tooltip.Portal>
																<Tooltip.Content side="bottom" sideOffset={16}>
																	<ChartTooltip
																		dataPoint={data}
																		seriesItem={item}
																		columnProps={overlayColumnProps}
																		interval={interval}
																	/>
																</Tooltip.Content>
															</Tooltip.Portal>
														</Tooltip.Root>
													</g>
												)}
												{anotherItem && (
													<g data-label="data-point-another-item">
														<circle
															className="linechart__circles__circle"
															fill="var(--color-shade-h4)"
															cx={cx(anotherItem)}
															cy={overlayYScale(
																precisionRound(anotherItem[dataOverlayProp], 1)
															)}
															r={RADIUS}
														/>
														<circle
															className="linechart__circles__circle"
															stroke={data.color}
															strokeWidth={1}
															fill="var(--color-shade-h4)"
															cx={cx(anotherItem)}
															cy={overlayYScale(
																precisionRound(anotherItem[dataOverlayProp], 1)
															)}
															strokeOpacity={0.05}
															r={RADIUS}
															cursor="pointer"
															onClick={handleToggle.bind(null, data.label)}
														/>
													</g>
												)}
												{state.series.map((s: any, i: number) =>
													i !== currentIndex ? (
														<Tooltip.Root
															open={data.label === hoverLabel && hoverIndex === i}
															key={i}
														>
															<Tooltip.Trigger asChild>
																<g data-label="data-point-tooltip-trigger">
																	<circle
																		className="linechart__circles__circle"
																		data-label="tooltip-item-big"
																		stroke={data.color}
																		strokeWidth={1}
																		fill="var(--color-shade-h4)"
																		cx={xScale(s.date)! + xScale.bandwidth() / 2}
																		cy={overlayYScale(
																			precisionRound(s[dataOverlayProp], 1)
																		)}
																		r={RADIUS * 2}
																		cursor="pointer"
																		opacity={0}
																		onMouseOver={() => {
																			handleMouseOverOnPoint(data.label, i);
																			onHover(data.label);
																		}}
																		onMouseOut={() => {
																			handleMouseOutOnPoint();
																			onHoverEnd(data.label);
																		}}
																		onClick={e =>
																			handleClickOnPoint(data.label, i, e)
																		}
																	/>
																	<circle
																		className="linechart__circles__circle"
																		data-label="tooltip-item"
																		stroke={data.color}
																		strokeWidth={1}
																		fill="var(--color-shade-h4)"
																		cx={xScale(s.date)! + xScale.bandwidth() / 2}
																		cy={overlayYScale(
																			precisionRound(s[dataOverlayProp], 1)
																		)}
																		r={RADIUS}
																		cursor="pointer"
																		opacity={
																			data.label === hoverLabel &&
																			hoverIndex === i
																				? 1
																				: i > currentIndex
																				? 0.05
																				: state.opacityCircle
																		}
																		style={{
																			pointerEvents: 'none',
																		}}
																	/>
																</g>
															</Tooltip.Trigger>
															<Tooltip.Portal>
																<Tooltip.Content side="bottom" sideOffset={16}>
																	<ChartTooltip
																		dataPoint={data}
																		seriesItem={s}
																		columnProps={overlayColumnProps}
																		interval={interval}
																	/>
																</Tooltip.Content>
															</Tooltip.Portal>
														</Tooltip.Root>
													) : null
												)}
											</Fragment>
										);
									})}
								</g>
							)}
						</NodeGroup>
					</>
				)}
				<Animate
					show={showIndexLine}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={() => ({
						opacity: [0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
				>
					{state => {
						const indexText = isLinearRegression ? 'Index (0%)' : 'Index (1x)';
						const yIndex = isLinearRegression ? getYScale(0) : getYScale(1);
						const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;

						return (
							<Fragment>
								<text
									className="barchart__index-text"
									x={PADDING}
									y={yIndex + 3}
									opacity={state.opacity}
								>
									{indexText}
								</text>
								<line
									className="barchart__index-line"
									x1={xIndex}
									y1={yIndex}
									x2={width - PADDING}
									y2={yIndex}
									opacity={state.opacity}
								/>
							</Fragment>
						);
					}}
				</Animate>
				<line
					className="barchart__axis-line"
					x1={PADDING}
					y1={height - AXIS_PADDING - PADDING}
					x2={width - PADDING}
					y2={height - AXIS_PADDING - PADDING}
				/>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={paginatedDates}
					start={data => ({
						opacity: 0,
						x: xScale(data)! + xScale.bandwidth() / 2,
					})}
					enter={data => {
						const formattedDate = getFormattedDate(data, interval ?? 'Month');
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							timing: {
								delay: Constants.AnimationDuration,
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={data => {
						const formattedDate = getFormattedDate(data, interval ?? 'Month');
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							x: [xScale(data)! + xScale.bandwidth() / 2],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="barchart__x-labels">
							{nodes.map(({ state, data, key }, index) => {
								const formattedDate = getFormattedDate(data, interval ?? 'Month');
								const selected = dateSelection && selectedDate === formattedDate;
								const clickable = dateSelection && selectedDate !== formattedDate;

								return (
									<text
										className={cn('barchart__x-labels__text', {
											'barchart__x-labels__text--selected': selected,
										})}
										key={key}
										x={state.x}
										y={height - PADDING - 0.5 * AXIS_FONT_SIZE}
										textAnchor="middle"
										opacity={state.opacity}
										onClick={clickable ? e => handleTimeClick(index, e) : e => e.stopPropagation()}
									>
										{formattedDate}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<YAxisBar
					analysisType={analysisType}
					indexType={indexType}
					xScale={PADDING}
					yAxisRangeValues={yAxisRangeValues}
					yAxisType={yAxisType}
					yScale={yScale}
				/>
				{(analysisType !== overlayAnalysisType ||
					(overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType !== yAxisType) ||
					(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType !== indexType)) && (
					<YAxisBar
						analysisType={overlayAnalysisType!}
						indexType={overlayIndexType}
						xScale={width - PADDING - X_AXIS_PADDING}
						yAxisRangeValues={overlayYAxisRangeValues}
						yAxisType={
							overlayAnalysisType !== AnalysisTypes.Index ? overlayYAxisType : YAxisType.Percentage
						}
						yScale={overlayYScale}
					/>
				)}
				{pageSize > 0 && (
					<Fragment>
						<polygon
							points={getLeftArrowPoints(pageSize, height, width)}
							fill={startIndex === 0 ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getLeftCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex === 0 ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handlePrevious}
						/>
						<polygon
							points={getRightArrowPoints(pageSize, height, width)}
							fill={startIndex + pageSize === dates.length ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getRightCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex + pageSize === dates.length ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handleNext}
						/>
					</Fragment>
				)}
			</svg>
		</Tooltip.Provider>
	);
}
