import * as Tooltip from '@radix-ui/react-tooltip';
import { max } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import produce from 'immer';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import {
	ARROW_CIRCLE_RADIUS,
	AXIS_FONT_SIZE,
	barTypes,
	LARGE_TEXT_FONT_SIZE,
	PADDING,
	TEXT_FONT_SIZE,
	Y_SMALL_PADDING,
} from '../editor/chart/constants';
import {
	getBarOpacity,
	getCenterY,
	getFormattedDate,
	getLeftArrowPoints,
	getLeftCenterX,
	getRightArrowPoints,
	getRightCenterX,
	getStackedTextOpacity,
	getStackedYAxisValues,
	getTopBarHeight,
	getTopBarOpacity,
	getYAxisRangeValues,
	transformStackedData,
} from '../lib/chartUtils';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { YAxisType } from '../types';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 24;
const MIN_GROUP_SPACING = 80;

const pageSizes = [0, 6, 4, 2, 1];

interface IStackedBarChartProps {
	analysisType?: any;
	onHoverEnd?: any;
	columnProps?: any;
	currentIndex?: any;
	dashboardName?: any;
	data?: any;
	dataProp?: any;
	dates?: any;
	dateSelection?: any;
	employeeCountHidden?: any;
	height?: any;
	indexType?: any;
	interval?: any;
	lifecycle?: any;
	reportId?: any;
	selectCurrentTime?: any;
	onHover?: any;
	title?: any;
	onToggle?: any;
	width?: any;
	yAxisType: YAxisType;
	hoveredItem?: string;
	selection: string[];
}

export default function StackedBarChart(props: IStackedBarChartProps) {
	const {
		analysisType,
		onHoverEnd,
		columnProps,
		currentIndex,
		dashboardName,
		dataProp,
		dates,
		dateSelection,
		employeeCountHidden,
		height,
		interval,
		reportId,
		selectCurrentTime,
		onHover,
		title,
		onToggle,
		width,
		yAxisType,
		hoveredItem,
		selection,
	} = props;
	let { data } = props;
	const isActive = data.some((d: any) => {
		const isActive = d.label === hoveredItem;
		const isSelected = selection.includes(d.label);
		return isActive || isSelected;
	});
	const showIndexLine =
		isActive && (analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index);

	let data2 = data.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex);

	const [barType, setBarType] = useState<string>();
	const [barWidth, setBarWidth] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [startIndex, setStartIndex] = useState<number>(0);
	const [barHovered, setBarHovered] = useState(false);
	const firstLoadRef = useRef({ firstLoad: true });

	useEffect(() => {
		const barCount = data2.length;
		const availableWidth = width - 2 * PADDING;
		let configFound = false;
		pageSizes.forEach(ps => {
			if (configFound) return;
			if (ps > dates.length) return;
			barTypes.forEach((bt, j) => {
				if (configFound) return;
				const groupCount = ps === 0 ? dates.length : ps;
				const maxGraphWidth = bt.max * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				const minGraphWidth = bt.min * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				let barWidth;
				if (j === 0 && maxGraphWidth < availableWidth) {
					barWidth = bt.max;
					configFound = true;
				} else if (minGraphWidth <= width && maxGraphWidth > availableWidth) {
					barWidth = (availableWidth - (groupCount - 1) * MIN_GROUP_SPACING) / (barCount * groupCount);
					configFound = true;
				}
				if (configFound && barWidth) {
					barWidth && setBarWidth(barWidth);
					setBarType(bt.type);
					setPageSize(ps);
				}
			});
		});
	}, [width, dates.length, data2.length]);
	useEffect(() => {
		if (barWidth > 0) {
			firstLoadRef.current.firstLoad = false;
		}
	}, [barWidth]);

	function handleToggle(label: any) {
		track(MixPanel.Events.DashboardReportStackedBarChartSegmentClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		onToggle(label);
	}

	function handleTimeClick(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		selectCurrentTime(index);
		track(MixPanel.Events.DashboardReportStackedBarChartXAxisClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handlePrevious(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (startIndex === 0) return;
		setStartIndex(startIndex - 1);
		track(MixPanel.Events.DashboardReportStackedBarChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handleNext(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (startIndex + pageSize >= dates.length) return;
		setStartIndex(startIndex + 1);
		track(MixPanel.Events.DashboardReportStackedBarChartTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	let paginatedData = data2;
	let paginatedDates = dates.slice();
	if (pageSize !== 0) {
		paginatedData = produce(paginatedData, (draftState: any) => {
			draftState.forEach((item: any) => {
				item.series = item.series.slice(startIndex, startIndex + pageSize);
			});
		});
		paginatedDates = dates.slice(startIndex, startIndex + pageSize);
	}

	if (yAxisType === 'count') {
		paginatedData = produce(paginatedData, (draftState: any) => {
			draftState.forEach((item: any, i: number) => {
				item.series.forEach((s: any, j: number) => {
					const total = paginatedData.reduce((sum: number, curr: any) => {
						sum += curr.series[j][dataProp];
						return sum;
					}, 0);

					if (i > 0) {
						s.previous = draftState[i - 1].series[j].previous + draftState[i - 1].series[j][dataProp];
						s.total = total;
					} else {
						s.previous = 0;
						s.total = total;
					}
					s.color = item.color;
				});
			});
		});
	} else {
		paginatedData = produce(paginatedData, (draftState: any) => {
			draftState.forEach((item: any, i: number) => {
				item.series.forEach((s: any, j: number) => {
					if (i > 0) {
						s.previous = draftState[i - 1].series[j].previous + draftState[i - 1].series[j].percentage;
						s.total = s.previous + s.percentage;
					} else {
						s.previous = 0;
						s.total = s.percentage;
					}
					s.color = item.color;
				});
			});
		});
	}

	paginatedData = transformStackedData(paginatedData);

	const { maxValue, xScale, yScale, yAxisRangeValues } = useMemo(() => {
		const xScale = scaleBand()
			.domain(paginatedDates)
			.range([PADDING * 2.5, width - PADDING]);

		let maxArray: any[] = [];

		if (yAxisType === 'count') {
			maxArray = Array.from({ length: data[0].series.length }).map((_, i) => {
				let countByDate = 0;

				data.forEach((d: any) => {
					countByDate += d.series[i][dataProp];
				});

				return countByDate;
			});
		} else {
			data.forEach((d: any) => {
				maxArray.push(max(d.series, (s: any) => s[dataProp]));
			});
		}
		maxArray = maxArray.filter((d: any) => d !== Constants.InvalidValue);

		const maxValue = max(maxArray);
		const yAxisValues = getStackedYAxisValues(maxValue, yAxisType);

		const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType);

		const yScale = scaleLinear()
			.domain(yAxisValues)
			.range([height - PADDING - AXIS_PADDING, barType === 'small' ? Y_SMALL_PADDING : PADDING]);

		return { maxValue, xScale, yScale, yAxisRangeValues };
	}, [paginatedDates, data, width, height, barType, dataProp]);

	const selectedDate = getFormattedDate(dates[currentIndex], interval ?? 'Month');

	if (height === 0 || barWidth === 0) {
		return null;
	}

	return (
		<Tooltip.Provider>
			<svg
				className={cn('barchart', dashboardName && 'absolute inset-0')}
				width={width}
				height={height}
				id={reportId}
				data-export-type="stackedbar"
			>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				{analysisType === AnalysisTypes.LinearRegression && (
					<rect
						className="barchart__linear-regression-background"
						x={0}
						y={yScale(0)}
						width={width}
						height={height - yScale(0)}
					/>
				)}
				{analysisType === AnalysisTypes.Index && (
					<rect
						className="barchart__index-background"
						x={0}
						y={yScale(1)}
						width={width}
						height={height - yScale(1)}
						fill="#1f1f21"
					/>
				)}
				{paginatedData.map((d: any) => (
					<g key={d.label} className="barchart__bar">
						<NodeGroup
							keyAccessor={d2 => `${d.label}_${d2.date}`}
							data={d.series.slice()}
							start={d2 => {
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}
								return {
									x,
									y: height - PADDING - AXIS_PADDING,
									height: 0,
									topBarHeight: 0,
									barOpacity: 1,
									topBarOpacity: 0,
									textOpacity: 0,
								};
							}}
							enter={(d2, i) => {
								const delay = firstLoadRef.current.firstLoad ? 0 : Constants.AnimationDuration;
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}

								const barValue = precisionRound(d2[dataProp], 1);
								let barHeight;
								let startY;
								const isValueZero = barValue === 0;

								if (yAxisType === 'count') {
									startY = yScale(maxValue - d2.previous - (maxValue - d2.total));
									barHeight = isValueZero ? 1 : height - yScale(barValue) - PADDING - AXIS_PADDING;
								} else {
									startY = yScale(100 - d2.previous);
									barHeight = yScale(100 - barValue) - PADDING;
									if (barType === 'small') {
										barHeight = barHeight - AXIS_PADDING;
									}
								}

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										topBarOpacity: [getTopBarOpacity(d, data2, selection, hoveredItem)],
										timing: {
											delay: delay,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
									{
										textOpacity: [
											getStackedTextOpacity({
												item: d,
												currentIndex: i,
												data,
												dataProp,
												height,
												yAxisType,
												yScale,
												barType,
												hoveredItem,
												selection,
											}),
										],
										timing: {
											delay: Constants.AnimationDuration,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							update={(d2, i) => {
								let x = xScale(d2.date)! + xScale.bandwidth() / 2 - (data2.length * barWidth) / 2;
								if (isNaN(x)) {
									x = -100;
								}

								const barValue = precisionRound(d2[dataProp], 1);
								let barHeight;
								let startY;
								const isValueZero = barValue === 0;

								if (yAxisType === 'count') {
									startY = yScale(maxValue - d2.previous - (maxValue - d2.total));
									barHeight = isValueZero ? 1 : height - yScale(barValue) - PADDING - AXIS_PADDING;
								} else {
									startY = yScale(100 - d2.previous);
									barHeight = yScale(100 - barValue) - PADDING;
									if (barType === 'small') {
										barHeight = barHeight - AXIS_PADDING;
									}
								}

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										barOpacity: [getBarOpacity(d, data2, selection, hoveredItem)],
										topBarOpacity: [getTopBarOpacity(d, data2, selection, hoveredItem)],
										textOpacity: [
											getStackedTextOpacity({
												item: d,
												currentIndex: i,
												data,
												dataProp,
												height,
												yAxisType,
												yScale,
												barType,
												hoveredItem,
												selection,
											}),
										],
										timing: {
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<Fragment>
									{nodes
										.slice(0)
										.reverse()
										.map(({ state, data, key }) => {
											if (
												d.isEqualityIndex ||
												d.isAttritionIndex ||
												data[dataProp] === Constants.InvalidValue
											) {
												return null;
											}

											let formattedText;
											let xText;
											let yText;
											let showText = true;
											const isLinearRegression = analysisType === AnalysisTypes.LinearRegression;
											const isValueNegative = data[dataProp] < 0;

											formattedText =
												yAxisType === 'count'
													? data.totalCount || data.includedCount
													: getFormattedValue(analysisType, data[dataProp]);

											xText =
												isLinearRegression && isValueNegative
													? state.x + barWidth - 2
													: state.x + 2;

											let startY;
											let barHeight;
											const barValue = precisionRound(data[dataProp], 1);

											if (yAxisType === 'count') {
												startY = yScale(maxValue - data.previous - (maxValue - data.total));

												barHeight = height - yScale(barValue);

												yText = startY + barHeight / 2 - PADDING;
											} else {
												startY = yScale(100 - data.previous);

												barHeight = yScale(100 - barValue);

												yText =
													barType === 'small'
														? startY + barHeight / 2 - PADDING
														: startY + barHeight / 2 - 4;
											}

											if (yText > height - 12 - PADDING - AXIS_PADDING) {
												yText = height - 12 - PADDING - AXIS_PADDING;
											}

											const barCount = data2.length;
											let groupWidth = (barWidth - PADDING + 8) * barCount;

											return (
												<Fragment key={key}>
													{state.height > 0 && (
														<rect
															x={state.x}
															y={state.y}
															width={groupWidth}
															height={state.height}
															fill={data.color}
															opacity={state.barOpacity}
														/>
													)}

													<Tooltip.Root
														delayDuration={0}
														open={barHovered && d.label === hoveredItem && state.active}
													>
														<Tooltip.Trigger asChild>
															<g>
																<rect
																	width={groupWidth}
																	x={state.x}
																	y={state.y}
																	height={state.topBarHeight}
																	fill={data.color}
																	opacity={state.topBarOpacity}
																/>
																{showText && !employeeCountHidden && (
																	<circle
																		cx={xText + groupWidth + 10}
																		r={3}
																		cy={yText - 4}
																		height={10}
																		fill={data.color}
																		opacity={state.textOpacity}
																		cursor="pointer"
																		onMouseOver={() => {
																			onHover(d.label);
																			setBarHovered(true);
																		}}
																		onMouseOut={() => {
																			onHoverEnd();
																			setBarHovered(false);
																		}}
																		onClick={() => handleToggle(d.label)}
																	/>
																)}

																<rect
																	width={groupWidth}
																	x={state.x}
																	y={state.y}
																	height={state.height}
																	fill="transparent"
																	cursor="pointer"
																	onMouseOver={() => {
																		onHover(d.label);
																		setBarHovered(true);
																	}}
																	onMouseOut={() => {
																		onHoverEnd();
																		setBarHovered(false);
																	}}
																	onClick={() => handleToggle(d.label)}
																/>
															</g>
														</Tooltip.Trigger>
														<Tooltip.Portal>
															<Tooltip.Content side="bottom" sideOffset={16}>
																<ChartTooltip
																	dataPoint={d.label === 'OtherXYZ' ? undefined : d}
																	seriesItem={data}
																	columnProps={columnProps}
																	interval={interval}
																/>
															</Tooltip.Content>
														</Tooltip.Portal>
													</Tooltip.Root>

													{showText && !employeeCountHidden && (
														<text
															width={barWidth}
															x={xText + groupWidth + 20}
															y={yText}
															textAnchor={'start'}
															height={state.height}
															style={{
																fontFamily: FontFamilies.Regular,
																fontSize:
																	barType === 'large'
																		? LARGE_TEXT_FONT_SIZE
																		: TEXT_FONT_SIZE,
															}}
															fill="var(--color-ui-100)"
															opacity={state.textOpacity}
															cursor="pointer"
															onMouseOver={() => onHover(d.label)}
															onMouseOut={() => onHoverEnd()}
															onClick={() => handleToggle(d.label)}
														>
															{formattedText}
														</text>
													)}
												</Fragment>
											);
										})}
								</Fragment>
							)}
						</NodeGroup>
					</g>
				))}
				<Animate
					show={showIndexLine}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={() => ({
						opacity: [0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
				>
					{state => {
						const indexText = analysisType === AnalysisTypes.LinearRegression ? 'Index (0%)' : 'Index (1x)';
						const yIndex = analysisType === AnalysisTypes.LinearRegression ? yScale(0) : yScale(1);
						const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;

						return (
							<Fragment>
								<text
									className="barchart__index-text"
									x={PADDING}
									y={yIndex + 3}
									opacity={state.opacity}
								>
									{indexText}
								</text>
								<line
									className="barchart__index-line"
									x1={xIndex}
									y1={yIndex}
									x2={width - PADDING}
									y2={yIndex}
									opacity={state.opacity}
								/>
							</Fragment>
						);
					}}
				</Animate>
				<line
					className="barchart__axis-line"
					x1={PADDING}
					y1={height - AXIS_PADDING - PADDING}
					x2={width - PADDING}
					y2={height - AXIS_PADDING - PADDING}
				/>
				<YAxisBar
					analysisType={analysisType}
					xScale={PADDING}
					yAxisRangeValues={yAxisRangeValues}
					yAxisType={yAxisType}
					yScale={yScale}
				/>
				<NodeGroup
					keyAccessor={(d: any) => d}
					data={paginatedDates}
					start={data => ({
						opacity: 0,
						x: xScale(data)! + xScale.bandwidth() / 2,
					})}
					enter={data => {
						const formattedDate = getFormattedDate(data, interval ?? 'Month');
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							timing: {
								delay: Constants.AnimationDuration,
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={data => {
						const formattedDate = getFormattedDate(data, interval ?? 'Month');
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							x: [xScale(data)! + xScale.bandwidth() / 2],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="barchart__x-labels">
							{nodes.map(({ state, data, key }, index) => {
								const formattedDate = getFormattedDate(data, interval ?? 'Month');
								const selected = dateSelection && selectedDate === formattedDate;
								const clickable = dateSelection && selectedDate !== formattedDate;

								return (
									<text
										className={cn('barchart__x-labels__text', {
											'barchart__x-labels__text--selected': selected,
										})}
										key={key}
										x={state.x}
										y={height - PADDING - 0.5 * AXIS_FONT_SIZE}
										textAnchor="middle"
										opacity={state.opacity}
										onClick={clickable ? e => handleTimeClick(index, e) : e => e.stopPropagation()}
									>
										{formattedDate}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				{pageSize > 0 && (
					<Fragment>
						<polygon
							points={getLeftArrowPoints(pageSize, height, width)}
							fill={startIndex === 0 ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getLeftCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex === 0 ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handlePrevious}
						/>
						<polygon
							points={getRightArrowPoints(pageSize, height, width)}
							fill={startIndex + pageSize === dates.length ? 'rgba(242,242,242,0.24)' : '#f2f2f2'}
						/>
						<circle
							cx={getRightCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex + pageSize === dates.length ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handleNext}
						/>
					</Fragment>
				)}
			</svg>
		</Tooltip.Provider>
	);
}
