import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import Constants from '../../constants/Constants';
import parseBoolean from '../../lib/parseBoolean';
import { getAttributes, updateDisabledState, updateOverlayDisabledState } from '../../store/reducerUtils';
import { Attribute, Fields, FilterSegment, IndexType, Labels, Segment, SegmentName, YAxisType } from '../../types';

export function getInitialState(): ExploreFilterState {
	return {
		analysisName: '',
		analysisType: undefined,
		attributes: [],
		breakdownNames: [],
		breakdownSegment: undefined,
		filterNames: [],
		filterSegment: undefined,
		interval: Constants.DefaultInterval,
		intervalCount: Constants.DefaultIntervalCount,
		mainNames: [],
		mainSegments: [],
		overlayAnalysisName: '',
		overlayAnalysisType: undefined,
		overlayChartType: ChartTypes.Line,
		overlayColumnProps: [],
		overlayDisplay: false,
		overlayFilterNames: [],
		overlayFilterSegment: undefined,
		overlayIndexType: IndexType.Indexed,
		overlayMainNames: [],
		overlayMainSegments: [],
		overlayYAxisType: YAxisType.Percentage,
		variations: [],
	};
}

export interface ExploreFilterUrlState {
	analysisDisplayName?: string;
	analysisName: string;
	analysisType?: AnalysisTypes;
	breakdownSegment?: Segment;
	date?: any;
	endDate?: string;
	filterSegment?: FilterSegment;
	interval: string;
	intervalCount?: number;
	isSavedSearch?: boolean;
	mainSegments: Segment[];
	overlayAnalysisDisplayName?: string;
	overlayAnalysisName: string;
	overlayAnalysisType?: AnalysisTypes;
	overlayChartType: ChartTypes;
	overlayColumnProps: any[];
	overlayDisplay?: boolean;
	overlayFilterNames: SegmentName[];
	overlayFilterSegment?: Segment;
	overlayIndexType: IndexType;
	overlayMainNames: SegmentName[];
	overlayMainSegments: Segment[];
	overlaySubAnalyses?: any;
	overlayYAxisType: YAxisType;
	showOverlaySubtable?: boolean;
	showSubtable?: boolean;
	startDate?: string;
	subAnalyses?: any;
}

export interface ExploreFilterState extends ExploreFilterUrlState {
	attributes: Attribute[];
	breakdownNames: SegmentName[];
	fields?: Fields;
	fieldsLoaded?: boolean;
	filterNames: SegmentName[];
	generateDisabled?: boolean;
	generateOverlayDisabled?: boolean;
	labels?: Labels;
	labelsLoaded?: boolean;
	mainNames: SegmentName[];
	variations: any;
}
// TODO: move overlay variables into chart reducer

export default function filterReducer(state: ExploreFilterState = getInitialState(), action: any): ExploreFilterState {
	switch (action.type) {
		case ActionTypes.SetAnalysisName: {
			let {
				analysisDisplayName,
				analysisName,
				subAnalyses,
				analysisType,
				mainNames,
				filterNames,
				breakdownNames,
				variations,
			} = action;
			if (mainNames) {
				mainNames = mainNames.map((n: any) => ({ name: n }));
				filterNames = filterNames.map((n: any) => ({ name: n }));
				breakdownNames = breakdownNames.map((n: any) => ({ name: n }));

				return {
					...state,
					analysisName,
					analysisDisplayName,
					analysisType,
					subAnalyses,
					mainNames,
					filterNames,
					breakdownNames,
					variations,
					// filterSegment: undefined,
					overlayFilterSegment: undefined,
				};
			}

			return {
				...state,
				analysisDisplayName,
				analysisName,
				analysisType,
				subAnalyses,
			};
		}

		case ActionTypes.SetOverlayAnalysisName: {
			let {
				overlayAnalysisDisplayName,
				overlayAnalysisName,
				overlayAnalysisType,
				overlayFilterNames,
				overlayMainNames,
				overlaySubAnalyses,
			} = action;
			console.log(overlayMainNames, 'overlayMainNames');
			console.log(overlayFilterNames, 'overlayFilterNames');

			if (overlayMainNames) {
				overlayMainNames = overlayMainNames.map((n: any) => ({
					name: n,
				}));
				overlayFilterNames = overlayFilterNames.map((n: any) => ({
					name: n,
				}));

				return {
					...state,
					generateOverlayDisabled: false,
					overlayAnalysisDisplayName,
					overlayAnalysisName,
					overlayAnalysisType,
					overlayFilterNames,
					overlayMainNames,
					overlaySubAnalyses,
					overlayFilterSegment: undefined,
				};
			}

			return {
				...state,
				generateOverlayDisabled: false,
				overlayAnalysisDisplayName,
				overlayAnalysisName,
				overlayAnalysisType,
				overlaySubAnalyses,
			};
		}
		case ActionTypes.SetOverlayChartType:
			return {
				...state,
				overlayChartType: action.overlayChartType,
			};
		case ActionTypes.SetOverlayIndexType:
			return {
				...state,
				overlayIndexType: action.overlayIndexType,
			};
		case ActionTypes.SetOverlayYAxisType:
			return {
				...state,
				overlayYAxisType: action.overlayYAxisType,
			};
		case ActionTypes.SetFilterLabels: {
			const { labels } = action;
			const { fieldsLoaded, fields } = state;
			let { attributes } = state;
			if (fieldsLoaded) {
				attributes = getAttributes(fields!, action.labels);
			}

			return {
				...state,
				labels,
				attributes,
				labelsLoaded: true,
			};
		}
		case ActionTypes.GetFieldsFulfilled: {
			const { labelsLoaded, labels } = state;
			let { attributes } = state;
			if (labelsLoaded) {
				attributes = getAttributes(action.payload, labels!);
			}
			return {
				...state,
				fields: action.payload,
				attributes,
				fieldsLoaded: true,
			};
		}
		case ActionTypes.UpdateMainSegments:
			return updateDisabledState(state, action.fields, state.filterSegment, state.breakdownSegment);
		case ActionTypes.UpdateOverlayMainSegments:
			return updateOverlayDisabledState(state, action.fields, state.overlayFilterSegment);
		case ActionTypes.UpdateFilterSegment:
			return updateDisabledState(state, state.mainSegments, action.segment, state.breakdownSegment);
		case ActionTypes.UpdateOverlayFilterSegment:
			return updateOverlayDisabledState(state, state.overlayMainSegments, action.segment);
		case ActionTypes.UpdateBreakdownSegment:
			return updateDisabledState(state, state.mainSegments, state.filterSegment, action.segment);
		case ActionTypes.ClearFilters: {
			return {
				...state,
				analysisDisplayName: '',
				analysisName: '',
				analysisType: undefined,
				breakdownNames: [],
				breakdownSegment: undefined,
				date: undefined,
				filterNames: [],
				filterSegment: undefined,
				generateOverlayDisabled: true,
				interval: Constants.DefaultInterval,
				intervalCount: Constants.DefaultIntervalCount,
				mainNames: [],
				mainSegments: [],
				overlayAnalysisDisplayName: '',
				overlayAnalysisName: '',
				overlayAnalysisType: undefined,
				overlayChartType: ChartTypes.Line,
				overlayColumnProps: [],
				overlayFilterNames: [],
				overlayFilterSegment: undefined,
				overlayMainNames: [],
				overlayMainSegments: [],
				overlaySubAnalyses: [],
				showSubtable: false,
				subAnalyses: [],
				variations: [],
			};
		}
		case ActionTypes.ClearOverlayFilters: {
			return {
				...state,
				generateOverlayDisabled: true,
				overlayAnalysisDisplayName: '',
				overlayAnalysisName: '',
				overlayAnalysisType: undefined,
				overlayChartType: ChartTypes.Line,
				overlayColumnProps: [],
				overlayDisplay: false,
				overlayFilterNames: [],
				overlayFilterSegment: undefined,
				overlayMainNames: [],
				overlayMainSegments: [],
				overlaySubAnalyses: [],
			};
		}
		// from Secondary analysis GENERATE
		case ActionTypes.GenerateMetricOverlayFulfilled:
			return {
				...state,
				generateOverlayDisabled: true,
				overlayChartType: action.chartType,
			};
		case ActionTypes.GenerateOverlayInExplore: {
			return {
				...state,
				overlayDisplay: true,
			};
		}
		case ActionTypes.GeneratePending: {
			return {
				...state,
				showSubtable: false,
			};
		}
		case ActionTypes.GenerateMetricOverlayPending: {
			return {
				...state,
				showOverlaySubtable: false,
			};
		}
		case ActionTypes.GenerateFulfilled: {
			return {
				...state,
				generateDisabled: true,
			};
		}
		case ActionTypes.GenerateSubtablePending:
			return {
				...state,
				showSubtable: true,
			};
		case ActionTypes.GenerateSubtableFulfilled:
			return {
				...state,
				generateDisabled: true,
			};
		case ActionTypes.SelectIntervalInExplore:
			return {
				...state,
				interval: action.interval,
				intervalCount: Constants.DefaultIntervalCount,
				startDate: undefined,
				endDate: undefined,
			};
		case ActionTypes.SelectIntervalCountInExplore:
			return {
				...state,
				intervalCount: action.intervalCount,
				startDate: undefined,
				endDate: undefined,
			};
		case ActionTypes.SelectIntervalRangeInExplore:
			return {
				...state,
				startDate: action.startDate,
				endDate: action.endDate,
				intervalCount: undefined,
			};
		case ActionTypes.SelectDateInExplore:
			return {
				...state,
				date: action.date,
			};
		case ActionTypes.SyncExploreFilterState:
			return {
				...state,
				...action.payload,
				showSubtable: parseBoolean(action.payload?.showSubtable),
				generateOverlayDisabled: parseBoolean(action.payload?.generateOverlayDisabled),
				generateDisabled: parseBoolean(action.payload?.generateDisabled),
				overlayDisplay: parseBoolean(action.payload?.overlayDisplay),
				intervalCount: Number(action.payload?.intervalCount),
				showOverlaySubtable: parseBoolean(action.payload?.showOverlaySubtable),
				isSavedSearch: parseBoolean(action.payload?.isSavedSearch),
			};

		case ActionTypes.SetShowSubtable: {
			return {
				...state,
				showSubtable: action.showSubtable,
			};
		}

		case ActionTypes.ChangeLocation: {
			const pathname = window.location.pathname;
			if (pathname !== '/explore') {
				return {
					...state,
					analysisName: '',
					analysisType: undefined,
					breakdownNames: [],
					breakdownSegment: undefined,
					filterNames: [],
					filterSegment: undefined,
					interval: Constants.DefaultInterval,
					intervalCount: Constants.DefaultIntervalCount,
					mainNames: [],
					mainSegments: [],
					overlayAnalysisName: '',
					overlayAnalysisType: undefined,
					overlayChartType: ChartTypes.Line,
					overlayColumnProps: [],
					overlayDisplay: false,
					overlayFilterNames: [],
					overlayFilterSegment: undefined,
					overlayIndexType: IndexType.Indexed,
					overlayMainNames: [],
					overlayMainSegments: [],
					overlayYAxisType: YAxisType.Percentage,
				};
			}
			return state;
		}

		default:
			return state;
	}
}
