import cloneDeep from 'lodash/cloneDeep';

import AnalysisTypes from '../constants/AnalysisTypes';
import { ARROW_WIDTH, BarTypes, PADDING } from '../editor/chart/constants';
import { IndexType, YAxisType } from '../types';
import getPeriod from './getPeriod';
import precisionRound from './precisionRound';

const STACKED_AXIS_PADDING = 24;
const TOP_BAR_EMPTY_HEIGHT = 0;
const ARROW_HEIGHT = 8;
const OTHER_LABEL = 'OtherXYZ';

export function getIsFaded(item: any, isActive: boolean, selection: string[], hoveredItem?: string) {
	if (isActive) {
		const isHovered = hoveredItem === item.label;
		const isSelected = selection.includes(item.label);
		return !(isHovered || isSelected || item.isEqualityIndex || item.isAttritionIndex);
	}
	return false;
}

export function getMainColumn(state: any) {
	const analysisName = state.editor.chart?.lifecycle;
	if (!analysisName) return;

	const template = state.templates.dynamicTemplates.find((t: any) => t.analysisName === analysisName);

	return template?.mainColumn;
}

export function getYAxisValues({
	analysisType,
	indexType,
	lifecycle,
	maxValue,
	minValue,
	yAxisType,
}: {
	analysisType?: AnalysisTypes;
	indexType?: IndexType;
	lifecycle: string;
	maxValue: number;
	minValue: number;
	yAxisType: YAxisType;
}): [number, number] {
	let dev = maxValue - minValue;
	if (dev === 0) {
		if (minValue === 0) {
			dev = 0.1;
		} else {
			dev = minValue * 0.1;
		}
	} else {
		dev = dev * 0.2;
	}
	let adjMinValue = minValue - dev;
	if (lifecycle !== 'Compensation' && analysisType !== AnalysisTypes.LinearRegression) {
		if (adjMinValue < 0) {
			adjMinValue = 0;
		}
	}
	let adjMaxValue = maxValue + dev;
	if (yAxisType === YAxisType.Percentage || indexType === IndexType.Percentage) {
		if (adjMaxValue > 100) {
			adjMaxValue = 100;
		} else {
			adjMaxValue = Math.ceil(adjMaxValue);
		}
		adjMinValue = Math.floor(adjMinValue);
	} else {
		adjMaxValue = Math.ceil(maxValue / 100) * 100;
	}

	return [adjMinValue, adjMaxValue];
}

export function getFormattedDate(date: Date, interval: string, showCondensed?: boolean) {
	return getPeriod(date, interval.toLowerCase(), showCondensed);
}

export function getYAxisRangeValues(valueRange: [number, number], yAxisType: YAxisType, indexType?: IndexType) {
	const [minValue, maxValue] = valueRange;
	const precision = yAxisType === YAxisType.Count ? 0 : 1;
	const diff = maxValue - minValue;

	let stepSize = diff / 3;
	let adjustedMax = maxValue;

	if (minValue === 0) {
		if (maxValue < 4) {
			stepSize = maxValue / 4;
			return [
				+stepSize.toFixed(precision),
				+(stepSize * 2).toFixed(precision),
				+(stepSize * 3).toFixed(precision),
				+maxValue.toFixed(precision),
			];
		}
		stepSize = adjustedMax / 4;
	}

	if (!(yAxisType === YAxisType.Percentage || indexType === IndexType.Percentage) && diff > 10) {
		if (maxValue < 100 || diff < 100) {
			adjustedMax = Math.ceil(maxValue / 10) * 10;
		} else {
			adjustedMax = Math.ceil(maxValue / 100) * 100;
		}
	}

	return minValue === 0
		? [
				+stepSize.toFixed(precision),
				+(stepSize * 2).toFixed(precision),
				+(stepSize * 3).toFixed(precision),
				+adjustedMax.toFixed(precision),
		  ]
		: [
				+minValue.toFixed(precision),
				+(adjustedMax - 2 * stepSize).toFixed(precision),
				+(adjustedMax - stepSize).toFixed(precision),
				+adjustedMax.toFixed(precision),
		  ];
}

export function getBarOpacity(item: any, data: any, selection: string[], hoveredItem?: string) {
	const isActive = data?.some((pd: any) => {
		const isActive = pd.label === hoveredItem;
		const isSelected = selection.includes(pd.label);
		return isActive || isSelected;
	});
	if (isActive && item.label !== hoveredItem && !selection.includes(item.label)) {
		return 0.2;
	}
	return 1;
}

export function getTopBarOpacity(item: any, data: any, selection: string[], hoveredItem?: string) {
	const isActive = data.some((pd: any) => {
		const isActive = pd.label === hoveredItem;
		const isSelected = selection.includes(pd.label);
		return isActive || isSelected;
	});
	if (isActive && item.label !== hoveredItem && !selection.includes(item.label)) {
		return 0.15;
	}
	return 1;
}

export function getTextOpacity(item: any, data: any, selection: string[], hoveredItem?: string) {
	const isActive = data.some((pd: any) => {
		const isActive = pd.label === hoveredItem;
		const isSelected = selection.includes(pd.label);
		return isActive || isSelected;
	});
	if (isActive && item.label !== hoveredItem && !selection.includes(item.label)) {
		return 0;
	}
	return 1;
}

export function getStackedTextOpacity({
	item,
	currentIndex,
	data,
	dataProp,
	height,
	yAxisType,
	yScale,
	barType,
	hoveredItem,
	selection,
}: {
	item: any;
	currentIndex: number;
	data: any;
	dataProp: any;
	height: number;
	yAxisType: YAxisType;
	yScale: any;
	barType?: string;
	hoveredItem?: string;
	selection: string[];
}) {
	const isActive = data.some((pd: any) => {
		const isActive = pd.label === hoveredItem;
		const isSelected = selection.includes(pd.label);
		return isActive || isSelected;
	});

	const index = currentIndex || 0;
	const barValue = precisionRound(item.series[index][dataProp], 1);
	let barHeight;
	let threshold = 12;
	if (yAxisType === 'count') {
		barHeight = barValue === 0 ? 1 : height - yScale(barValue) - PADDING - STACKED_AXIS_PADDING;
	} else {
		barHeight = yScale(100 - barValue) - PADDING;
		if (barType === BarTypes.Small) {
			barHeight = barHeight - STACKED_AXIS_PADDING;
		}
	}

	if (
		(isActive && item.label !== hoveredItem && !selection.includes(item.label)) ||
		(barHeight < threshold && !item.active && !item.selected)
	) {
		return 0;
	}
	return 1;
}

export function getTopBarHeight(item: any, height: number) {
	if (item.active || item.selected) {
		return height;
	}

	return TOP_BAR_EMPTY_HEIGHT;
}

function getArrowPoints(x: number, y: number, isRight?: boolean) {
	const x2 = isRight ? x - ARROW_WIDTH : x + ARROW_WIDTH;
	return `${x},${y} ${x2},${y - ARROW_HEIGHT / 2} ${x2},${y + ARROW_HEIGHT / 2}`;
}

export function getLeftArrowPoints(pageSize: number, height: number, width: number) {
	const x = pageSize !== 1 ? PADDING : width / 2 - 60;
	const y = height - PADDING - 10;
	return getArrowPoints(x, y);
}

export function getRightArrowPoints(pageSize: number, height: number, width: number) {
	const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
	const y = height - PADDING - 10;
	return getArrowPoints(x, y, true);
}

export function getLeftCenterX(pageSize: number, width: number) {
	const x = pageSize !== 1 ? PADDING : width / 2 - 60;
	return x + ARROW_WIDTH / 2;
}

export function getRightCenterX(pageSize: number, width: number) {
	const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
	return x - ARROW_WIDTH / 2;
}

export function getCenterY(height: number) {
	return height - PADDING - 10;
}

export function getStackedYAxisValues(maxValue: number, yAxisType: YAxisType): [number, number] {
	if (yAxisType === YAxisType.Count) {
		return [0, maxValue];
	}
	return [0, 100];
}

export function transformStackedData(data: any) {
	let data2 = cloneDeep(data);
	let totalArr = Array(data2[0]?.series?.length).fill(0);
	let dateArr = Array(data2[0]?.series?.length).fill(0);
	data2 = data2.map((d: any) => {
		d.series.forEach((s: any, i: number) => {
			totalArr[i] += s.percentage;
			dateArr[i] = s.date;
		});
		return d;
	});
	let thereIsNon100 = false;

	totalArr.forEach(t => {
		if (t < 100) {
			thereIsNon100 = true;
		}
	});
	if (thereIsNon100) {
		let newSeries: any[] = [];

		totalArr.forEach((t, i) => {
			let newSeriesItem = {
				date: dateArr[i],
				percentage: 100 - t,
				total: 100,
				previous: t,
				color: '#3a3a3d',
			};
			newSeries.push(newSeriesItem);
		});
		data2.push({
			label: OTHER_LABEL,
			series: newSeries,
		});
	}

	return data2;
}
