import hash from 'object-hash';
import qs from 'qs';
import { useEffect, useState } from 'react';

import Button from '../common/Button';
import Dropdown from '../common/Dropdown';
import Stack from '../common/Stack';
import AnalysisTypes from '../constants/AnalysisTypes';
import ButtonTypes from '../constants/ButtonTypes';
import ChartTypes from '../constants/ChartTypes';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';
import BarChartVertical from '../icons/BarChartVertical';
import BarSelected from '../icons/BarSelected';
import Line from '../icons/Line';
import LineSelected from '../icons/LineSelected';
import StackedBar from '../icons/StackedBar';
import Trash from '../icons/Trash';
import cn from '../lib/cn';
import getMainColumn from '../lib/getMainColumn';
import getMixPanelObjectForFilters from '../lib/getMixPanelObjectForFilters';
import { track } from '../lib/segment';
import { capitalize } from '../measure/utils';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { FilterSegment, Segment } from '../types';
import { clearMetricOverlay } from './chart/actions';
import AnalysisDropdown from './filter/AnalysisDropdown';
import { getInitialAnalysisNames } from './filter/Filters';
import SegmentDropdown from './filter/SegmentDropdown';
import {
	clearOverlayFilters,
	generateOverlayInExplore,
	navigate,
	setOverlayAnalysisName,
	setOverlayChartType,
	setOverlayIndexType,
	setOverlayYAxisType,
	updateOverlayFilterSegment,
	updateOverlayMainSegments,
} from './filter/actions';
import { TemplateGroup } from '../templates/models';

const overlayCharts = [
	{
		chartType: ChartTypes.Line,
		activeIcon: LineSelected,
		inactiveIcon: Line,
	},
	{
		chartType: ChartTypes.Bar,
		activeIcon: BarSelected,
		inactiveIcon: BarChartVertical,
	},
	{
		chartType: ChartTypes.StackedBar,
		activeIcon: StackedBar,
		inactiveIcon: StackedBar,
	},
];

function MetricsOverlay() {
	const dispatch = useAppDispatch();
	const [showOverlayMetricsBlock, setShowOverlayMetricsBlock] = useState(false);
	const [shouldOpen, setShouldOpen] = useState(false);

	const { dynamicTemplates, dynamicGroups } = useAppSelector(state => state.templates);
	const { analysisNamesTree } = useAppSelector(state => state.account);
	const mainColumn = useAppSelector(state => getMainColumn(state, state.explore.filter.overlayAnalysisName));
	const {
		attributes,
		generateOverlayDisabled,
		overlayAnalysisDisplayName,
		overlayAnalysisName,
		overlayAnalysisType,
		overlayChartType,
		overlayFilterNames,
		overlayFilterSegment,
		overlayIndexType,
		overlayMainNames,
		overlayMainSegments,
		overlaySubAnalyses,
		overlayYAxisType,
		showSubtable,
	} = useAppSelector(state => state.explore.filter);
	const { chartType, generateMetricOverlayPending, metricOverlay } = useAppSelector(state => state.explore.chart);
	const { subtableChartType } = useAppSelector(state => state.explore.subtable);

	useEffect(() => {
		if (!!overlayAnalysisName && !!overlayMainSegments) {
			setShowOverlayMetricsBlock(true);
		}
	}, []);

	useEffect(() => {
		if (location.search) {
			const query: any = qs.parse(location.search, {
				ignoreQueryPrefix: true,
				arrayLimit: 10000,
			});

			const newOverlayAnalysisName = query?.filter?.overlayAnalysisName;
			const newOverlayChartType = query?.filter?.overlayChartType;
			const newOverlayFilterSegment = query?.filter?.overlayFilterSegment;
			const newOverlayMainSegments = query?.filter?.overlayMainSegments;
			const newOverlaySubAnalyses = query?.filter?.overlaySubAnalyses;
			const newOverlayIndexType = query?.filter?.overlayIndexType;
			const newOverlayYAxisType = query?.filter?.overlayYAxisType;

			if (!newOverlayMainSegments) {
				dispatch(clearMetricOverlay());
				dispatch(clearOverlayFilters());
				return;
			}

			const newHash = hash([
				newOverlayAnalysisName,
				newOverlayChartType,
				newOverlayFilterSegment,
				newOverlayIndexType,
				newOverlayMainSegments,
				newOverlaySubAnalyses,
				newOverlayYAxisType,
			]);
			const oldHash = hash([
				overlayAnalysisName,
				overlayChartType,
				overlayFilterSegment,
				overlayIndexType,
				overlayMainSegments,
				overlaySubAnalyses,
				overlayYAxisType,
			]);
			if (newHash !== oldHash) {
				let newOverlayAnalysisDisplayName: any;

				if (newOverlaySubAnalyses?.length) {
					newOverlayAnalysisDisplayName =
						(newOverlaySubAnalyses as any).length > 1
							? `${overlaySubAnalyses} (${newOverlaySubAnalyses.length})`
							: (newOverlaySubAnalyses as any)[0].name;
				} else {
					newOverlayAnalysisDisplayName = newOverlayAnalysisName;
				}

				dispatch(
					setOverlayAnalysisName({
						analysisDisplayName: newOverlayAnalysisDisplayName,
						analysisName: newOverlayAnalysisName,
						subAnalyses: newOverlaySubAnalyses || [],
					})
				).then(() => {
					setShowOverlayMetricsBlock(true);
					dispatch(updateOverlayMainSegments(newOverlayMainSegments));
					dispatch(setOverlayChartType(newOverlayChartType));
					dispatch(setOverlayIndexType(newOverlayIndexType));
					dispatch(setOverlayYAxisType(newOverlayYAxisType));
					if (newOverlayFilterSegment) {
						dispatch(updateOverlayFilterSegment([newOverlayFilterSegment as any]));
					} else {
						dispatch(updateOverlayFilterSegment([]));
					}
					dispatch(generateOverlayInExplore());
				});
			} else {
				dispatch(generateOverlayInExplore());
			}
		}
	}, [location.search]);

	const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree!, dynamicGroups);

	const disabled = !usePermissions({
		actions: [RbacActions['Explore/Generate']],
	});
	const mainChartType = showSubtable ? subtableChartType : chartType;

	const currentTemplate = dynamicGroups.find((t: TemplateGroup) => t.name === overlayAnalysisName)?.templates?.[0];

	function handleClear() {
		track(MixPanel.Events.ExploreClear);
		dispatch(clearMetricOverlay());
		dispatch(clearOverlayFilters());
	}

	function handleDelete() {
		track(MixPanel.Events.ExploreClear);
		dispatch(clearMetricOverlay());
		dispatch(clearOverlayFilters());
		dispatch(navigate());
		setShowOverlayMetricsBlock(false);
	}

	function handleGenerate() {
		const segments = overlayMainSegments.slice();
		if (overlayFilterSegment) {
			segments.push(overlayFilterSegment);
		}
		const mixpanelObj = getMixPanelObjectForFilters(overlayAnalysisName, segments, attributes);
		dispatch(generateOverlayInExplore());
		dispatch(navigate());
		track(MixPanel.Events.ExploreMetricOverlayGenerate, mixpanelObj);
	}

	function handleShowOverlayMetricsBlock() {
		setShowOverlayMetricsBlock(true);
	}

	function handleAnalysisChange() {
		dispatch(updateOverlayMainSegments([]));
		dispatch(updateOverlayFilterSegment([]));
	}

	if (!showOverlayMetricsBlock) {
		return (
			<Button componentType={ButtonTypes.type.SECONDARY} onClick={handleShowOverlayMetricsBlock}>
				Add analysis
			</Button>
		);
	}

	return (
		<div className={cn('explore-settings-panel__content transition-all duration-150 ease-in-out')}>
			<Stack flexDirection="column" gap=".5rem">
				<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
					<span className={cn('font-text leading-[1]')} style={{ color: 'var(--color-ui-100)' }}>
						Secondary analysis
					</span>
					<Button componentType={ButtonTypes.type.TERTIARY} circle={true} onClick={handleDelete}>
						<Trash width={24} />
					</Button>
				</Stack>
				<div>
					<AnalysisDropdown
						initialAnalysisNames={initialAnalysisNames}
						analysisDisplayName={overlayAnalysisDisplayName!}
						analysisName={overlayAnalysisName}
						analysisType={overlayAnalysisType}
						disabled={disabled}
						shouldOpen={false}
						setAnalysisName={(props: any) => dispatch(setOverlayAnalysisName(props))}
						isSecondary
						onValueChange={handleAnalysisChange}
						setShouldOpen={setShouldOpen}
					/>
					<SegmentDropdown
						placeholder="Segment"
						attributes={attributes}
						dynamicPermissionsFilter={(currentTemplate?.mainNames as any) || []}
						availableSegmentNames={overlayMainNames}
						selection={overlayMainSegments}
						update={segments => dispatch(updateOverlayMainSegments(segments as Segment[]))}
						disabled={!overlayAnalysisName || disabled}
						shouldOpen={shouldOpen}
						setShouldOpen={setShouldOpen}
						isSecondary
					/>
					<SegmentDropdown
						placeholder="Filter"
						attributes={attributes}
						dynamicPermissionsFilter={(currentTemplate?.filterNames as any) || []}
						availableSegmentNames={overlayFilterNames}
						selection={overlayFilterSegment ? [overlayFilterSegment] : []}
						update={segments => dispatch(updateOverlayFilterSegment(segments as FilterSegment[]))}
						disabled={
							disabled ||
							(!overlayFilterSegment && currentTemplate?.filterNames?.length === 0) ||
							!overlayAnalysisName ||
							overlayMainSegments.length === 0
						}
						useRadioOnSegment
						useRadioOnValue
						isSecondary
					/>
					{overlayAnalysisName && !!overlayMainSegments?.length && (
						<>
							<div className="explore-settings-panel__content__item">
								<span className="explore-settings-panel__header__subtitle">Visualization</span>
								<Dropdown
									buttonType={ButtonTypes.type.SECONDARY}
									selectedOption={overlayChartType ?? overlayCharts[0].chartType}
									onClick={(chartType: ChartTypes) => dispatch(setOverlayChartType(chartType))}
									options={overlayCharts.map((ot: any) => {
										const isStackedBar = ot.chartType === ChartTypes.StackedBar;

										const option = {
											disabled: false,
											icon: <></>,
											label: ot.displayName ? ot.displayName : ot.chartType,
											value: ot.chartType,
										};

										if (isStackedBar) {
											option.disabled =
												!(
													overlayAnalysisType === AnalysisTypes.Percentage &&
													(mainColumn === 'total' || mainColumn === 'included')
												) ||
												mainChartType === ChartTypes.Bar ||
												mainChartType === ChartTypes.StackedBar;
										} else if (ot.chartType === ChartTypes.Bar) {
											option.disabled = mainChartType === ChartTypes.StackedBar;
										}

										option.icon =
											ot.chartType === overlayChartType ? ot.activeIcon : ot.inactiveIcon;

										return option;
									})}
									showIcon
								/>
							</div>
							{overlayAnalysisType === AnalysisTypes.Index && (
								<div className="explore-settings-panel__content__item">
									<span className="explore-settings-panel__header__subtitle">Metric</span>
									<Dropdown
										buttonType={ButtonTypes.type.SECONDARY}
										selectedOption={capitalize(overlayIndexType)}
										onClick={(val: any) => {
											dispatch(setOverlayIndexType(val.toLowerCase()));
										}}
										options={[
											{ label: 'Indexed', value: 'indexed' },
											{
												label: 'Percentage',
												value: 'percentage',
											},
										]}
									/>
								</div>
							)}
							{overlayAnalysisType !== AnalysisTypes.Index && metricOverlay?.allowYAxisToggle && (
								<div className="explore-settings-panel__content__item">
									<span className="explore-settings-panel__header__subtitle">Metric</span>
									<Dropdown
										buttonType={ButtonTypes.type.SECONDARY}
										selectedOption={capitalize(overlayYAxisType)}
										onClick={(val: any) => {
											dispatch(setOverlayYAxisType(val.toLowerCase()));
										}}
										options={[
											{ label: 'Count', value: 'count' },
											{
												label: 'Percentage',
												value: 'percentage',
											},
										]}
									/>
								</div>
							)}
						</>
					)}
				</div>
				{!disabled && (
					<Stack
						flexDirection="row"
						justifyContent="end"
						gap="1rem"
						style={{
							marginTop: '2rem',
						}}
					>
						<Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
							Clear
						</Button>
						<Button
							disabled={
								!overlayAnalysisName ||
								!overlayMainSegments ||
								!overlayMainSegments?.length ||
								generateOverlayDisabled
							}
							onClick={handleGenerate}
							loading={!!generateMetricOverlayPending}
							testId="generate"
						>
							<span style={{ opacity: generateMetricOverlayPending ? 0 : 1 }}>Generate</span>
						</Button>
					</Stack>
				)}
			</Stack>
		</div>
	);
}

export default MetricsOverlay;
