import { ScaleLinear } from 'd3-scale';

import AnalysisTypes from '../constants/AnalysisTypes';
import FontFamilies from '../constants/FontFamilies';
import decimalFormatter from '../lib/decimalFormatter';
import precisionRound from '../lib/precisionRound';
import { IndexType, YAxisType } from '../types';

function getFormattedYAxisValue(
	analysisType: AnalysisTypes,
	indexType: IndexType | undefined,
	value: number,
	yAxisType: YAxisType
) {
	value = precisionRound(value, 1);
	if (yAxisType === YAxisType.Count) {
		return value;
	}

	if ((indexType === IndexType.Percentage ? AnalysisTypes.Percentage : analysisType) === AnalysisTypes.Index) {
		return decimalFormatter(value) + 'x';
	}
	if (analysisType === AnalysisTypes.LinearRegression) {
		if (value > 0) {
			return '+' + decimalFormatter(value) + '%';
		}
	}
	if (yAxisType === YAxisType.Percentage) {
		return decimalFormatter(value) + '%';
	}

	return value;
}

interface IYAxisBarProps {
	analysisType: AnalysisTypes;
	indexType?: IndexType;
	xScale: number;
	yAxisRangeValues: any;
	yAxisType: YAxisType;
	yScale: ScaleLinear<number, number, never>;
}

export default function YAxisBar(props: IYAxisBarProps) {
	const { analysisType, indexType, xScale, yAxisRangeValues, yAxisType, yScale } = props;

	return (
		<g>
			{yAxisRangeValues.map((value: any, index: number) => (
				<g key={index}>
					<text
						key={`${value}${index}`}
						x={xScale}
						y={yScale(value)}
						textAnchor="start"
						fill="var(--color-ui-50)"
						cursor="pointer"
						style={{
							fontFamily: FontFamilies.Regular,
							fontSize: 12,
						}}
					>
						{getFormattedYAxisValue(
							indexType === IndexType.Percentage ? AnalysisTypes.Percentage : analysisType,
							indexType,
							yAxisRangeValues[index],
							yAxisType
						)}
					</text>
				</g>
			))}
		</g>
	);
}
