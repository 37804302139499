import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import cn from '../lib/cn';

const secondaryStyles = cn(
	'bg-shade-4 text-ui-75 rounded-[2rem]',
	'hover:bg-shade-5 active:bg-shade-6 focus-visible:bg-transparent focus-visible:text-ui-100 focus-visible:ring-1 focus-visible:ring-ui-100'
);

const skeletonStyles = cn('!bg-[transparent] text-ui-75 rounded-[2rem]', 'focus-visible:text-ui-100');

const baseStyles = cn(
	'inline-flex items-center justify-center whitespace-nowrap disabled:!bg-shade-3 disabled:cursor-not-allowed disabled:!text-ui-20 transition-all duration-175 ease-in-out',
	'max-w-avail'
);

const buttonVariants = cva(baseStyles, {
	variants: {
		variant: {
			ai: cn(
				'rounded-[2rem] pl-[.8rem] pr-[1rem] relative overflow-hidden z-[2] after:absolute after:inset-0  after:z-[-1] hover:after:bg-hoverOverlay text-dark-ui-100',
				'hover:shadow-default data-[activated=true]:shadow-default ',
				'transition-all duration-175 ease-in-out'
			),
			secondary: secondaryStyles,
			skeleton: skeletonStyles,
		},
		size: {
			sm: `h-[2.4rem] px-[1rem]`,
			md: `h-[3.5rem] px-[1.6rem]`,
		},
		selected: {
			default: '!bg-ui-100 !text-shade-h2 disabled',
		},
		activated: {
			default: 'bg-[transparent] !text-ui-100 ring-1 ring-ui-100 disabled:ring-0',
		},
	},
	defaultVariants: {
		variant: 'ai',
		size: 'md',
	},
});

export interface ButtonProps {
	className?: string;
	selected?: 'default';
	activated?: 'default';
	variant: 'ai' | 'secondary';
	size: 'sm' | 'md';
	asChild?: boolean;
	[key: string]: any;
}

const Button = React.forwardRef(
	({ className, selected, activated, variant = 'ai', size, asChild = false, ...props }: ButtonProps, ref?: any) => {
		const Comp = asChild ? Slot : 'button';

		return (
			<Comp
				className={cn(
					buttonVariants({
						variant,
						size,
						className,
						selected,
						activated,
					})
				)}
				data-selected={selected}
				data-activated={activated}
				ref={ref}
				{...props}
			/>
		);
	}
);
Button.displayName = 'Button';

export { buttonVariants };
export default Button;
