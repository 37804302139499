import { selectCurrentTime } from '../explore/actions';
import { selectCurrentTime as selectCurrentTimeInReport } from '../dashboard/view/actions';
import cn from '../lib/cn';
import getChartData from '../lib/getChartData';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Events from './Events';
import Legend from './Legend';
import ResponsiveContainer from './ResponsizeContainer';
import ScrollableChartArea from './ScrollableChartArea';
import StackedBarChart from './StackedBarChart';

const StackedBarChartWrapper = ({
	reportId,
	events,
	onHover,
	onHoverEnd,
	onToggle,
	hoveredItem,
	selection,
	status,
}: {
	reportId?: string;
	events?: any;
	onHover?: any;
	onHoverEnd?: any;
	onToggle?: any;
	hoveredItem?: any;
	selection?: any;
	status?: any;
}) => {
	const dispatch = useAppDispatch();
	const { dynamicPermissions } = useAppSelector(state => state.auth);

	const {
		analysisName,
		analysisType,
		columnProps,
		currentIndex,
		data,
		dates,
		indexType,
		interval,
		lifecycle,
		yAxisType,
	} = useAppSelector(state => getChartData(state, reportId));

	const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);
	const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

	function handleSelectCurrentTime(currentIndex: number) {
		if (reportId) {
			dispatch(selectCurrentTimeInReport(reportId, currentIndex));
		} else {
			dispatch(selectCurrentTime(currentIndex));
		}
	}

	return (
		<div className={cn('flex-1 flex flex-col h-full justify-between')}>
			<Legend
				data={data?.map((d: any) => ({
					...d,
					analysisName: analysisName,
				}))}
				hoveredItem={hoveredItem}
				onHover={onHover}
				onHoverEnd={onHoverEnd}
				onToggle={onToggle}
				selection={selection}
				status={status}
			/>
			<ScrollableChartArea>
				<div className={cn('flex-1 relative')}>
					<div className={cn('relative w-full h-[250px] wide:h-[360px]')}>
						<ResponsiveContainer className="explore-chart__main">
							{({ width, height }: { width: number; height: number }) => (
								<StackedBarChart
									reportId={reportId || 'explore-chart'}
									width={width}
									height={height}
									data={data}
									dates={dates}
									interval={interval}
									lifecycle={lifecycle}
									analysisType={analysisType}
									currentIndex={currentIndex}
									selectCurrentTime={handleSelectCurrentTime}
									onHover={onHover}
									onHoverEnd={onHoverEnd}
									onToggle={onToggle}
									dataProp={dataProp}
									yAxisType={yAxisType}
									dateSelection
									employeeCountHidden={employeeCountHidden}
									columnProps={columnProps}
									hoveredItem={hoveredItem}
									selection={selection}
								/>
							)}
						</ResponsiveContainer>
					</div>
				</div>
				{events && <Events {...events} />}
			</ScrollableChartArea>
			{/*
				<div>X AXIS</div>
        */}
		</div>
	);
};

export default StackedBarChartWrapper;
