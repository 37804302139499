import * as Tooltip from '@radix-ui/react-tooltip';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import produce from 'immer';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import {
	ARROW_CIRCLE_RADIUS,
	AXIS_FONT_SIZE,
	barTypes,
	LARGE_TEXT_FONT_SIZE,
	PADDING,
	TEXT_FONT_SIZE,
	X_AXIS_PADDING,
	Y_SMALL_PADDING,
} from '../editor/chart/constants';
import {
	getBarOpacity,
	getCenterY,
	getFormattedDate,
	getIsFaded,
	getLeftArrowPoints,
	getLeftCenterX,
	getRightArrowPoints,
	getRightCenterX,
	getTextOpacity,
	getTopBarHeight,
	getTopBarOpacity,
	getYAxisRangeValues,
	getYAxisValues,
} from '../lib/chartUtils';
import cn from '../lib/cn';
import getFormattedValue from '../lib/getFormattedValue';
import getInterpolator from '../lib/getInterpolator';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { useAppSelector } from '../store/hooks';
import { IndexType, ISegmentData, YAxisType } from '../types';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 24;
const MIN_GROUP_SPACING = 48;
const RADIUS = 4;

const pageSizes = [0, 6, 4, 2, 1];

interface IBarChartOverlayProps {
	analysisType?: any;
	onHoverEnd?: any;
	columnProps?: any;
	currentIndex?: any;
	dashboardName?: any;
	data?: ISegmentData[];
	dataOverlayProp?: any;
	dataProp?: any;
	dates?: Date[];
	dateSelection?: any;
	employeeCountHidden?: any;
	height?: any;
	indexType?: IndexType;
	interval?: any;
	lifecycle: string;
	lightMode: any;
	metricOverlayLifecycle: string;
	overlayData?: ISegmentData[];
	overlayIndexType: IndexType;
	overlayYAxisType: YAxisType;
	reportId?: any;
	selectCurrentTime?: any;
	onHover?: any;
	title?: any;
	onToggle?: any;
	width?: any;
	yAxisType: YAxisType;
	hoveredItem?: any;
	selection: any[];
	overlayColumnProps: any[];
	overlayChartType?: ChartTypes;
}

export default function BarChartOverlay(props: IBarChartOverlayProps) {
	const {
		overlayChartType,
		analysisType,
		onHoverEnd,
		columnProps,
		currentIndex,
		dashboardName,
		data,
		dataOverlayProp,
		dataProp,
		dates,
		dateSelection,
		employeeCountHidden,
		height = 256,
		indexType,
		interval,
		lifecycle,
		lightMode,
		metricOverlayLifecycle,
		overlayData = [],
		overlayIndexType,
		overlayYAxisType,
		reportId,
		selectCurrentTime,
		onHover,
		title,
		onToggle,
		width = 600,
		yAxisType,
		hoveredItem,
		selection,
		overlayColumnProps,
	} = props;

	const { metricOverlay } = useAppSelector(state => state.explore.chart);

	const [internalHoverId, setInternalHoverId] = useState<string>('');

	const overlayAnalysisType = metricOverlay?.analysisType;

	const isChartActive = hoveredItem || selection?.length;

	if (!data?.length || !overlayData.length || !dates?.length) {
		return null;
	}

	const isActive = [...data, ...overlayData].some(d => {
		const isActive = d.label === hoveredItem;
		const isSelected = selection.includes(d.label);
		return isActive || isSelected;
	});
	const isLinearRegression =
		analysisType === AnalysisTypes.LinearRegression || overlayAnalysisType === AnalysisTypes.LinearRegression;
	const isIndexed = analysisType === AnalysisTypes.Index || overlayAnalysisType === AnalysisTypes.Index;
	const isIndexType = indexType === IndexType.Indexed || overlayIndexType === IndexType.Indexed;
	const showIndexLine =
		isActive &&
		(isLinearRegression || isIndexed) &&
		((analysisType === AnalysisTypes.Index && indexType === IndexType.Indexed) ||
			(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === IndexType.Indexed));

	const isFaded = (item: any) => getIsFaded(item, isActive, selection, hoveredItem);

	// optimize?
	const data2 = useMemo(() => {
		return data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
	}, [data]);
	const overlayData2 = useMemo(() => {
		return overlayData
			.filter(d => !d.isEqualityIndex && !d.isAttritionIndex)
			.map(d => ({
				...d,
				series: d.series.filter(s =>
					dates.some(date => new Date(date).toDateString() === new Date(s.date).toDateString())
				),
			}));
	}, [dates, overlayData]);

	const mergedData = useMemo(() => [...data2, ...overlayData2], [data2, overlayData2]);

	const [barType, setBarType] = useState<string>();
	const [hoverIndex, setHoverIndex] = useState<number>();
	const [barWidth, setBarWidth] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(0);
	const [hoverLabel, setHoverLabel] = useState<string>();
	const [startIndex, setStartIndex] = useState<number>(0);
	const firstLoadRef = useRef({ firstLoad: true });

	// optimize?
	useEffect(() => {
		const barCount = data2.length;
		const availableWidth = width - 2 * PADDING;
		let configFound = false;
		pageSizes.forEach(ps => {
			if (configFound) return;
			if (ps > dates.length) return;
			barTypes.forEach((bt, j) => {
				if (configFound) return;
				const groupCount = ps === 0 ? dates.length : ps;
				const maxGraphWidth = bt.max * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				const minGraphWidth = bt.min * barCount * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
				let barWidth;
				if (j === 0 && AnalysisTypes.LinearRegression === analysisType && maxGraphWidth * 2 < availableWidth) {
					barWidth = (availableWidth - (groupCount - 1) * (MIN_GROUP_SPACING * 2)) / (barCount * groupCount);
					if (groupCount === 1) {
						barWidth = barWidth / 2;
					}

					configFound = true;
				} else if (j === 0 && maxGraphWidth < availableWidth) {
					barWidth = bt.max;
					configFound = true;
				} else if (minGraphWidth <= width && maxGraphWidth > availableWidth) {
					barWidth = (availableWidth - (groupCount - 1) * MIN_GROUP_SPACING) / (barCount * groupCount);
					configFound = true;
				}
				if (configFound) {
					barWidth && setBarWidth(barWidth);
					setBarType(bt.type);
					setPageSize(ps);
				}
			});
		});
	}, [width, dates.length, data2.length]);

	useEffect(() => {
		if (barWidth > 0) {
			firstLoadRef.current.firstLoad = false;
		}
	}, [barWidth]);

	function handleToggle(label: string, e?: React.MouseEvent<SVGGElement, MouseEvent>) {
		track(MixPanel.Events.DashboardReportColumnGraphColumnSegmentClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
		onToggle(label);
	}

	function handleTimeClick(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		selectCurrentTime(index);
		track(MixPanel.Events.DashboardReportColumnGraphXAxisClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handlePrevious(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (startIndex === 0) return;
		setStartIndex(startIndex - 1);
		track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handleNext(e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (!dates?.length) return;
		if (startIndex + pageSize >= dates.length) return;
		setStartIndex(startIndex + 1);
		track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
			'Dashboard Name': dashboardName,
			'Report Name': title,
		});
	}

	function handleMouseOverOnPoint(label: string, index: number) {
		setHoverIndex(index);
		setHoverLabel(label);
	}

	function handleMouseOutOnPoint() {
		setHoverIndex(undefined);
		setHoverLabel(undefined);
	}

	function handleClickOnPoint(_: any, index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
		e.stopPropagation();
		if (index !== currentIndex) {
			track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
				'Dashboard Name': dashboardName,
				'Report Name': title,
			});

			setHoverIndex(undefined);
			setHoverLabel(undefined);
			selectCurrentTime(index);
		}
	}

	const arrowColor = lightMode ? '#111112' : '#f2f2f2';
	const arrowColorDisabled = lightMode ? 'rgba(17,17,18,0.24)' : 'rgba(242,242,242,0.24)';

	const paginatedData = useMemo(() => {
		if (pageSize === 0) return data2;
		return produce(data2, (draftState: any) => {
			draftState.forEach((item: any) => {
				item.series = item.series.slice(startIndex, startIndex + pageSize);
			});
		});
	}, [data2, startIndex, pageSize]);

	const paginatedOverlayData = useMemo(() => {
		if (pageSize === 0) return overlayData2;
		return produce(overlayData2, (draftState: any) => {
			draftState.forEach((item: any) => {
				item.series = item.series.slice(startIndex, startIndex + pageSize);
			});
		});
	}, [overlayData2, startIndex, pageSize]);

	const paginatedDates = useMemo(() => {
		if (pageSize === 0) return dates;
		return dates.slice(startIndex, startIndex + pageSize);
	}, [dates, startIndex, pageSize]);

	const { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues } = useMemo(() => {
		const xScale = scaleBand()
			.domain(paginatedDates as any as string[])
			.range([PADDING * 2.5, width - PADDING * 2.5]);

		let minArray: any[] = [];
		let maxArray: any[] = [];
		paginatedData.forEach(d => {
			minArray.push(min(d.series, (s: any) => s[dataProp]));
			maxArray.push(max(d.series, (s: any) => s[dataProp]));
		});
		minArray = minArray.filter(d => d !== Constants.InvalidValue);
		maxArray = maxArray.filter(d => d !== Constants.InvalidValue);
		const minValue = Math.floor(min(minArray));
		const maxValue = Math.ceil(max(maxArray));

		let yAxisValues: [number, number] = [0, 0];
		let overlayYAxisValues: [number, number] = [0, 0];

		const overlayMinArray = paginatedOverlayData.map(d =>
			min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
		);
		const overlayMaxArray = paginatedOverlayData.map(d =>
			max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
		);
		const overlayMinValue = Math.floor(min(overlayMinArray as any as number[]) as number);
		const overlayMaxValue = Math.ceil(max(overlayMaxArray as any as number[]) as number);

		if (
			analysisType === overlayAnalysisType &&
			((overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType === yAxisType) ||
				(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === indexType))
		) {
			const minOverallValue = min([minValue, overlayMinValue]);
			const maxOverallValue = max([maxValue, overlayMaxValue]);

			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue: maxOverallValue!,
				minValue: minOverallValue!,
				yAxisType,
			});

			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: maxOverallValue!,
				minValue: minOverallValue!,
				yAxisType: overlayYAxisType,
			});
		} else {
			yAxisValues = getYAxisValues({
				analysisType,
				indexType,
				lifecycle,
				maxValue,
				minValue,
				yAxisType,
			});

			overlayYAxisValues = getYAxisValues({
				analysisType: overlayAnalysisType,
				indexType: overlayIndexType,
				lifecycle: metricOverlayLifecycle,
				maxValue: overlayMaxValue,
				minValue: overlayMinValue,
				yAxisType: overlayYAxisType,
			});
		}

		const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType);

		const range = [height - PADDING - AXIS_PADDING, barType === 'small' ? Y_SMALL_PADDING : PADDING];

		const yScale = scaleLinear().domain(yAxisValues).range(range);

		const overlayYAxisRangeValues = getYAxisRangeValues(overlayYAxisValues, overlayYAxisType);

		const overlayYScale = scaleLinear().domain(overlayYAxisValues).range(range);

		return { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues };
	}, [
		barType,
		dataOverlayProp,
		dataProp,
		height,
		lifecycle,
		metricOverlayLifecycle,
		overlayAnalysisType,
		overlayIndexType,
		overlayYAxisType,
		paginatedData,
		paginatedDates,
		paginatedOverlayData,
		width,
	]);

	const avgValue = useMemo(() => {
		const minArray: any[] = [];
		const maxArray: any[] = [];
		paginatedOverlayData.forEach(d => {
			minArray.push(min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
			maxArray.push(max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1)));
		});
		const minValue = min(minArray);
		const maxValue = max(maxArray);

		return (minValue + maxValue) / 2;
	}, [paginatedOverlayData, dataOverlayProp]);

	const selectedDate = getFormattedDate(dates[currentIndex], interval);

	if (barWidth === 0 || height === 0) {
		return null;
	}

	const overlayLineFn =
		line()
			.x((d: any) => xScale(d.date)! + xScale.bandwidth() / 2)
			.y((d: any) => overlayYScale(precisionRound(d[dataOverlayProp], 1)))
			.curve(curveMonotoneX) || undefined;

	const getYScale = (scale: number) =>
		analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index
			? yScale(scale)
			: overlayYScale(scale);

	return (
		<Tooltip.Provider>
			<svg
				className={cn('dataOverlayPropbarchart ', dashboardName && 'absolute inset-0')}
				data-export-type="barchart"
				width={width}
				height={height}
				id={reportId}
			>
				<defs>
					<style type="text/css">{inlineFont}</style>
				</defs>
				{isLinearRegression && (
					<rect
						className="barchart__linear-regression-background"
						x={0}
						y={getYScale(0)}
						width={width}
						height={height - getYScale(0)}
					/>
				)}
				{isIndexed && isIndexType && (
					<rect
						className="barchart__index-background"
						x={0}
						y={getYScale(1)}
						width={width}
						height={height - getYScale(1)}
						fill="#1f1f21"
					/>
				)}
				{paginatedData.map((d: any, i: number) => (
					<g key={d.label} className="barchart__bar">
						<NodeGroup
							keyAccessor={d2 => `${d.label}_${d2.date}`}
							data={d.series.slice()}
							start={d2 => {
								let x =
									xScale(d2.date)! +
									xScale.bandwidth() / 2 -
									(data2.length * barWidth) / 2 +
									i * barWidth;
								if (isNaN(x)) {
									x = -100;
								}
								return {
									x,
									y: isLinearRegression ? yScale(0) : height - PADDING - AXIS_PADDING,
									height: 0,
									topBarHeight: 0,
									barOpacity: 1,
									topBarOpacity: 0,
									textOpacity: 0,
								};
							}}
							enter={d2 => {
								const delay = firstLoadRef.current.firstLoad ? 0 : Constants.AnimationDuration;
								let x =
									xScale(d2.date)! +
									xScale.bandwidth() / 2 -
									(data2.length * barWidth) / 2 +
									i * barWidth;
								if (isNaN(x)) {
									x = -100;
								}
								const barValue = precisionRound(d2[dataProp], 1);

								const isValueNegative = barValue < 0;
								const isValueZero = barValue === 0;

								const startY = isValueZero
									? yScale(0) - 1
									: isLinearRegression
									? isValueNegative
										? yScale(0)
										: yScale(barValue)
									: yScale(barValue);

								const barHeight = isValueZero
									? 1
									: isLinearRegression
									? isValueNegative
										? yScale(barValue) - yScale(0)
										: yScale(0) - yScale(barValue)
									: height - PADDING - AXIS_PADDING - startY;

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
										timing: {
											delay: delay,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
									{
										textOpacity: [getTextOpacity(d, mergedData, selection, hoveredItem)],
										timing: {
											delay: Constants.AnimationDuration,
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							update={d2 => {
								let x =
									xScale(d2.date)! +
									xScale.bandwidth() / 2 -
									(data2.length * barWidth) / 2 +
									i * barWidth;
								if (isNaN(x)) {
									x = -100;
								}
								const barValue = precisionRound(d2[dataProp], 1);

								const isValueNegative = barValue < 0;
								const isValueZero = barValue === 0;

								const startY = isValueZero
									? yScale(0) - 1
									: isLinearRegression
									? isValueNegative
										? yScale(0)
										: yScale(barValue)
									: yScale(barValue);

								const barHeight = isValueZero
									? 1
									: isLinearRegression
									? isValueNegative
										? yScale(barValue) - yScale(0)
										: yScale(0) - yScale(barValue)
									: height - PADDING - AXIS_PADDING - startY;

								return [
									{
										x: [x],
										y: [startY],
										height: [barHeight],
										topBarHeight: [getTopBarHeight(d, barHeight)],
										barOpacity: [getBarOpacity(d, mergedData, selection, hoveredItem)],
										topBarOpacity: [getTopBarOpacity(d, mergedData, selection, hoveredItem)],
										textOpacity: [getTextOpacity(d, mergedData, selection, hoveredItem)],
										timing: {
											duration: Constants.AnimationDuration,
											ease: Constants.EasingFn,
										},
									},
								];
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<Fragment>
									{nodes.map(({ state, data, key }) => {
										if (
											d.isEqualityIndex ||
											d.isAttritionIndex ||
											data[dataProp] === Constants.InvalidValue
										) {
											return null;
										}

										let formattedText;
										let xText;
										let yText;
										let translateX;
										let showText = false;
										const isValueNegative = data[dataProp] < 0;
										if (barType !== 'small' || d.label === hoveredItem || d.selected) {
											showText = true;
											formattedText =
												yAxisType === YAxisType.Count
													? employeeCountHidden
														? ''
														: data.totalCount || data.includedCount
													: getFormattedValue(
															indexType === IndexType.Percentage
																? AnalysisTypes.Percentage
																: analysisType,
															data[dataProp]
													  );

											xText =
												isLinearRegression && isValueNegative
													? state.x + barWidth - 2
													: state.x + 2;
											yText =
												yScale(precisionRound(data[dataProp], 1)) +
												(isLinearRegression && isValueNegative ? 12 : -4);
											translateX = (barWidth + TEXT_FONT_SIZE) / 2 - 4;
										}
										const isActive = hoveredItem === d.label;
										const isSelected = selection?.includes(d.label);
										const barOpacity = isChartActive && !(isActive || isSelected) ? 0.2 : 1;

										const { height } = state;

										return (
											<Fragment key={key}>
												{height > 0 && (
													<rect
														width={barWidth - 2}
														x={state.x}
														y={state.y}
														height={height}
														fill={d.color}
														opacity={barOpacity}
													/>
												)}
												<Tooltip.Root
													delayDuration={0}
													open={internalHoverId === d.label + '-' + key}
												>
													<Tooltip.Trigger asChild>
														<g>
															<rect
																width={barWidth - 2}
																x={state.x}
																y={state.y}
																height={state.topBarHeight}
																fill={d.color}
																opacity={barOpacity}
																className={cn('transition-all duration-[75ms]')}
															/>

															<rect
																data-label="tooltip-trigger"
																width={barWidth - 2}
																x={state.x}
																y={state.y}
																height={height}
																fill="transparent"
																cursor="pointer"
																onMouseEnter={() => {
																	onHover(d.label);
																	setInternalHoverId(d.label + '-' + key.toString());
																}}
																onMouseLeave={() => {
																	onHoverEnd();
																	setInternalHoverId('');
																}}
																onClick={() => handleToggle(d.label)}
															/>
														</g>
													</Tooltip.Trigger>
													<Tooltip.Portal>
														<Tooltip.Content side="bottom" sideOffset={16}>
															<ChartTooltip
																dataPoint={d}
																seriesItem={data}
																columnProps={
																	d.isOverlay ? overlayColumnProps : columnProps
																}
																interval={interval}
															/>
														</Tooltip.Content>
													</Tooltip.Portal>
												</Tooltip.Root>

												{showText && (
													<text
														width={barWidth}
														x={xText}
														y={yText}
														textAnchor={
															isLinearRegression && isValueNegative ? 'end' : 'start'
														}
														height={height}
														transform={
															barType === 'small'
																? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
																: ''
														}
														style={{
															fontFamily: FontFamilies.Regular,
															fontSize:
																barType === 'large'
																	? LARGE_TEXT_FONT_SIZE
																	: TEXT_FONT_SIZE,
														}}
														className={cn('transition-all duration-[75ms]')}
														fill="var(--color-ui-100)"
														opacity={
															isChartActive && !(isActive || isSelected) ? 0 : barOpacity
														}
														cursor="pointer"
														onMouseEnter={() => {
															onHover(d.label);
															setInternalHoverId(d.label + '-' + key.toString());
														}}
														onMouseLeave={() => {
															onHoverEnd();
															setInternalHoverId('');
														}}
														onClick={() => handleToggle(d.label)}
													>
														{formattedText}
													</text>
												)}
											</Fragment>
										);
									})}
								</Fragment>
							)}
						</NodeGroup>
					</g>
				))}
				{!!overlayData2.length && overlayChartType === ChartTypes.Line && (
					<>
						<NodeGroup
							keyAccessor={d => d.label}
							data={paginatedOverlayData}
							start={d => {
								const initial = d.series.map(({ date }: { date: Date }) => ({
									[dataProp]: avgValue,
									date,
								}));
								return {
									opacity: 1,
									series: initial,
								};
							}}
							enter={d => {
								return {
									series: [d.series],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							update={d => {
								return {
									series: [d.series],
									opacity: [isFaded(d) ? 0.05 : 1],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<g className="linechart__lines">
									{nodes.map(
										({ state, data, key }) =>
											!data.isEqualityIndex &&
											!data.isAttritionIndex && (
												<g data-label="line" key={key}>
													<path
														className="linechart__lines__line"
														d={overlayLineFn?.(state.series) as string | undefined}
														strokeOpacity={
															hoveredItem === data.label ||
															selection?.includes(data.label) ||
															!isChartActive
																? 1
																: 0.2
														}
														stroke={data.color}
														clipPath={`url(#${reportId}_main)`}
														onMouseOver={() => {
															onHover(data.label);
															setInternalHoverId(data.label + '-' + key.toString());
														}}
														onMouseOut={() => {
															onHoverEnd();
															setInternalHoverId('');
														}}
														onClick={() => handleToggle(data.label)}
														data-label="line-primary"
													/>
													<path
														className="linechart__lines__line"
														d={overlayLineFn?.(state.series) as string | undefined}
														strokeOpacity={0.05}
														stroke={data.color}
														clipPath={`url(#${reportId}_fade)`}
														data-label="line-secondary"
													/>
												</g>
											)
									)}
								</g>
							)}
						</NodeGroup>
						<NodeGroup
							keyAccessor={d => d.label}
							data={paginatedOverlayData}
							start={d => {
								const initial = d.series.map(({ date }: { date: Date }) => ({
									[dataProp]: avgValue,
									date,
								}));
								return {
									opacityCircle: 0,
									opacityText: 0,
									series: initial,
								};
							}}
							enter={d => {
								return {
									opacityCircle: [1],
									opacityText: [1],
									series: [d.series],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							update={d => {
								return {
									opacityCircle: isFaded(d) ? [0.05] : [1],
									opacityText: isFaded(d) ? [0.24] : [1],
									timing: {
										duration: Constants.AnimationDuration,
										ease: Constants.EasingFn,
									},
								};
							}}
							interpolation={getInterpolator}
						>
							{nodes => (
								<g className="linechart__circles">
									{nodes.map(({ state, data, key }) => {
										if (data.isEqualityIndex || data.isAttritionIndex) {
											return null;
										}
										const currentDate = getFormattedDate(dates[currentIndex], interval);
										const item = state.series.find(
											(s: any) => getFormattedDate(s.date, interval) === currentDate
										);
										let anotherItem;
										if (currentIndex !== dates.length - 1) {
											const lastDate = getFormattedDate(dates[dates.length - 1], interval);
											anotherItem = state.series.find(
												(s: any) => getFormattedDate(s.date, interval) === lastDate
											);
										}
										const cx = (item: any) => xScale(item.date)! + xScale.bandwidth() / 2;
										const cy = (item: any) =>
											overlayYScale(precisionRound(item[dataOverlayProp], 1));

										return (
											<Fragment key={key}>
												{item && (
													<g data-label="data-point-current-index">
														<circle
															className="linechart__circles__circle"
															stroke="var(--color-shade-h4)"
															fill="var(--color-shade-h4)"
															style={{
																pointerEvents: 'none',
															}}
															cx={cx(item)}
															cy={cy(item)}
															r={RADIUS}
															data-label="data-point-mask"
														/>
														<circle
															className="linechart__circles__circle"
															fill={data.color}
															cx={cx(item)}
															cy={cy(item)}
															r={RADIUS}
															opacity={
																hoveredItem === data.label ||
																selection?.includes(data.label) ||
																!isChartActive
																	? 1
																	: 0.2
															}
															style={{
																pointerEvents: 'none',
															}}
															data-label="data-point-primary"
														/>
														<Tooltip.Root
															delayDuration={0}
															open={
																data.label === hoverLabel && hoverIndex === currentIndex
															}
														>
															<Tooltip.Trigger asChild>
																<circle
																	className="linechart__circles__circle"
																	fill={data.color}
																	cx={cx(item)}
																	cy={cy(item)}
																	r={RADIUS * 2}
																	cursor="pointer"
																	opacity={0}
																	onMouseOver={() => {
																		handleMouseOverOnPoint(
																			data.label,
																			currentIndex
																		);
																		onHover(data.label);
																	}}
																	onMouseOut={() => {
																		handleMouseOutOnPoint();
																		onHoverEnd();
																	}}
																	onClick={() => {
																		handleToggle(data.label);
																	}}
																	data-label="data-point-hover-trigger"
																/>
															</Tooltip.Trigger>
															<Tooltip.Portal>
																<Tooltip.Content side="bottom" sideOffset={16}>
																	<ChartTooltip
																		dataPoint={data}
																		seriesItem={item}
																		columnProps={overlayColumnProps}
																		interval={interval}
																	/>
																</Tooltip.Content>
															</Tooltip.Portal>
														</Tooltip.Root>
													</g>
												)}
												{anotherItem && (
													<g data-label="data-point-another-item">
														<circle
															className="linechart__circles__circle"
															fill="var(--color-shade-h4)"
															cx={cx(anotherItem)}
															cy={cy(anotherItem)}
															r={RADIUS}
														/>
														<circle
															className="linechart__circles__circle"
															stroke={data.color}
															strokeWidth={1}
															fill="var(--color-shade-h4)"
															cx={cx(anotherItem)}
															cy={cy(anotherItem)}
															strokeOpacity={0.05}
															r={RADIUS}
															cursor="pointer"
															onClick={handleToggle.bind(null, data.label)}
														/>
													</g>
												)}
												{state.series.map((s: any, i: number) =>
													i !== currentIndex ? (
														<Tooltip.Root
															open={data.label === hoverLabel && hoverIndex === i}
															key={i}
														>
															<Tooltip.Trigger asChild>
																<g data-label="data-point-tooltip-trigger">
																	<circle
																		className="linechart__circles__circle"
																		data-label="tooltip-item-big"
																		stroke={data.color}
																		strokeWidth={1}
																		fill="var(--color-shade-h4)"
																		cx={xScale(s.date)! + xScale.bandwidth() / 2}
																		cy={cy(s)}
																		r={RADIUS * 2}
																		cursor="pointer"
																		opacity={0}
																		onMouseOver={() => {
																			handleMouseOverOnPoint(data.label, i);

																			onHover(data.label);
																		}}
																		onMouseOut={() => {
																			handleMouseOutOnPoint();
																			onHoverEnd();
																		}}
																		onClick={e =>
																			handleClickOnPoint(data.label, i, e)
																		}
																	/>
																	<circle
																		className="linechart__circles__circle"
																		data-label="tooltip-item"
																		stroke={data.color}
																		strokeWidth={1}
																		fill="var(--color-shade-h4)"
																		cx={xScale(s.date)! + xScale.bandwidth() / 2}
																		cy={cy(s)}
																		r={RADIUS}
																		cursor="pointer"
																		opacity={
																			!isChartActive ||
																			hoveredItem === data.label ||
																			selection.includes(data.label)
																				? 1
																				: 0
																		}
																		style={{
																			pointerEvents: 'none',
																		}}
																	/>
																</g>
															</Tooltip.Trigger>
															<Tooltip.Portal>
																<Tooltip.Content side="bottom" sideOffset={16}>
																	<ChartTooltip
																		dataPoint={data}
																		seriesItem={s}
																		columnProps={overlayColumnProps}
																		interval={interval}
																	/>
																</Tooltip.Content>
															</Tooltip.Portal>
														</Tooltip.Root>
													) : null
												)}
											</Fragment>
										);
									})}
								</g>
							)}
						</NodeGroup>
					</>
				)}
				<Animate
					show={showIndexLine}
					start={() => ({
						opacity: 0,
					})}
					enter={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					update={() => ({
						opacity: [1],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
					leave={() => ({
						opacity: [0],
						timing: {
							duration: Constants.AnimationDuration,
							ease: Constants.EasingFn,
						},
					})}
				>
					{state => {
						const indexText = isLinearRegression ? 'Index (0%)' : 'Index (1x)';
						const yIndex = isLinearRegression ? getYScale(0) : getYScale(1);
						const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;

						return (
							<Fragment>
								<text
									className="barchart__index-text"
									x={PADDING}
									y={yIndex + 3}
									opacity={state.opacity}
								>
									{indexText}
								</text>
								<line
									className="barchart__index-line"
									x1={xIndex}
									y1={yIndex}
									x2={width - PADDING}
									y2={yIndex}
									opacity={state.opacity}
								/>
							</Fragment>
						);
					}}
				</Animate>
				<line
					className="barchart__axis-line"
					x1={PADDING}
					y1={height - AXIS_PADDING - PADDING}
					x2={width - PADDING}
					y2={height - AXIS_PADDING - PADDING}
				/>
				<NodeGroup
					keyAccessor={d => d}
					data={paginatedDates.slice()}
					start={data => {
						let x = xScale(data)! + xScale.bandwidth() / 2;
						return {
							opacity: 0,
							x,
						};
					}}
					enter={data => {
						const formattedDate = getFormattedDate(data, interval);
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							timing: {
								delay: Constants.AnimationDuration,
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					update={data => {
						const formattedDate = getFormattedDate(data, interval);
						const selected = dateSelection && selectedDate === formattedDate;

						return {
							opacity: [selected ? 1 : 0.7],
							x: [xScale(data)! + xScale.bandwidth() / 2],
							timing: {
								duration: Constants.AnimationDuration,
								ease: Constants.EasingFn,
							},
						};
					}}
					interpolation={getInterpolator}
				>
					{nodes => (
						<g className="barchart__x-labels">
							{nodes.map(({ state, data, key }, index) => {
								const formattedDate = getFormattedDate(data, interval);
								const selected = dateSelection && selectedDate === formattedDate;
								const clickable = dateSelection && selectedDate !== formattedDate;

								return (
									<text
										className={cn(
											'barchart__x-labels__text',
											selected && 'barchart__x-labels__text--selected'
										)}
										key={key}
										x={state.x}
										y={height - PADDING - 0.5 * AXIS_FONT_SIZE}
										textAnchor="middle"
										opacity={state.opacity}
										onClick={clickable ? e => handleTimeClick(index, e) : e => e.stopPropagation()}
									>
										{formattedDate}
									</text>
								);
							})}
						</g>
					)}
				</NodeGroup>
				<YAxisBar
					analysisType={analysisType}
					indexType={indexType}
					xScale={PADDING}
					yAxisRangeValues={yAxisRangeValues}
					yAxisType={yAxisType}
					yScale={yScale}
				/>
				{(analysisType !== overlayAnalysisType ||
					(overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType !== yAxisType) ||
					(overlayAnalysisType === AnalysisTypes.Index && overlayIndexType !== indexType)) && (
					<YAxisBar
						analysisType={overlayAnalysisType!}
						indexType={overlayIndexType}
						xScale={width - PADDING - X_AXIS_PADDING}
						yAxisRangeValues={overlayYAxisRangeValues}
						yAxisType={
							overlayAnalysisType !== AnalysisTypes.Index ? overlayYAxisType : YAxisType.Percentage
						}
						yScale={overlayYScale}
					/>
				)}
				{pageSize > 0 && (
					<Fragment>
						<polygon
							points={getLeftArrowPoints(pageSize, height, width)}
							fill={startIndex === 0 ? arrowColorDisabled : arrowColor}
						/>
						<circle
							cx={getLeftCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex === 0 ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handlePrevious}
						/>
						<polygon
							points={getRightArrowPoints(pageSize, height, width)}
							fill={startIndex + pageSize === dates.length ? arrowColorDisabled : arrowColor}
						/>
						<circle
							cx={getRightCenterX(pageSize, width)}
							cy={getCenterY(height)}
							r={ARROW_CIRCLE_RADIUS}
							cursor={startIndex + pageSize === dates.length ? 'default' : 'pointer'}
							fill="transparent"
							onClick={handleNext}
						/>
					</Fragment>
				)}
			</svg>
		</Tooltip.Provider>
	);
}
