import { Template, TemplateGroup } from '../../../../templates/models';

export function getAttributesByAnalysises(
	analysises: string[],
	templates: TemplateGroup[],
	attributes: any[],
	typeId: 'mainNames' | 'filterNames' | 'breakdownNames'
) {
	if (!analysises || analysises.length === 0) {
		return [];
	}

	const variationSet = new Set();

	if (analysises.includes('Any')) {
		templates.forEach((templateGroup: TemplateGroup) => {
			const template = templateGroup?.templates?.[0];
			if (typeof template[typeId] === 'object') {
				// @ts-ignore
				template[typeId].forEach((name: string) => {
					variationSet.add(name);
				});
			}
		});
	} else {
		analysises.forEach((analysis: string) => {
			const templateGroup = templates.find((template: TemplateGroup) => template.name === analysis);

			const [firstTemplate, ...restTemplates] = templateGroup?.templates || [];

			let combinedTemplate = {
				...firstTemplate,
				variations: [...(firstTemplate?.variations ?? [])],
				mainNames: [...(firstTemplate?.mainNames ?? [])],
				filterNames: [...(firstTemplate?.filterNames ?? [])],
				breakdownNames: [...(firstTemplate?.breakdownNames ?? [])],
			};

			if (restTemplates.length > 0) {
				restTemplates.forEach((template: Template) => {
					combinedTemplate.variations.push(...(template.variations ?? []));
					combinedTemplate.mainNames.push(...(template.mainNames ?? []));
					combinedTemplate.filterNames.push(...(template.filterNames ?? []));
					combinedTemplate.breakdownNames.push(...(template.breakdownNames ?? []));
				});
			}

			if (combinedTemplate && combinedTemplate[typeId]) {
				combinedTemplate[typeId].forEach((name: string) => {
					variationSet.add(name);
				});
			}
		});
	}

	const newAttributes = attributes.filter((attr: any) => {
		return variationSet.has(attr.attributeName.replace(/ /g, ''));
	});

	return newAttributes;
}
