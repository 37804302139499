import axios from 'axios';
import { push } from 'connected-react-router';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import produce from 'immer';
import { parseInt } from 'lodash';
import { marked } from 'marked';
import moment from 'moment';
import * as uuid from 'uuid';

import ActionTypes from '../../constants/ActionTypes';
import ChartTypes from '../../constants/ChartTypes';
import Constants from '../../constants/Constants';
import GridConstants from '../../constants/GridConstants';
import MixPanel from '../../constants/MixPanel';
import Urls from '../../constants/Urls';
import { resetAiInsights } from '../../dashboard/aiInsights/reducer';
import { clearOverlayFilters } from '../../explore/filter/actions';
import errorHandler from '../../lib/errorHandler';
import { getReportPosition } from '../../lib/gridUtils';
import { track } from '../../lib/segment';
import { getReportDef } from '../../store/actionUtils';
import { getConfig } from '../../store/commonUtils';

function scroll(reportId, offset = -120) {
	return () => {
		const element = document.getElementById(`chart-${reportId}`);
		if (element) {
			element.scrollIntoView();
			window.scrollBy(0, offset);
		}
	};
}

function setReportData(reportId, reportData) {
	return {
		type: ActionTypes.SetReportData,
		reportId,
		reportData,
	};
}

function cleanUp(reportId, dashboardId) {
	return (dispatch, getState) => {
		const chart = getState().explore.chart;
		const {
			showSubtable,
			overlayAnalysisName,
			overlayAnalysisType,
			overlayChartType,
			overlayColumnProps,
			overlayDisplay,
			overlayFilterNames,
			overlayFilterSegment,
			overlayIndexType,
			overlayMainNames,
			overlayMainSegments,
			overlayYAxisType,
		} = getState().explore.filter;
		const subtable = getState().explore.subtable;

		let reportData;

		if (showSubtable) {
			reportData = subtable;
		} else {
			reportData = chart;
		}

		dispatch(
			setReportData(reportId, {
				...reportData,
				showSubtable,
				overlayAnalysisName,
				overlayAnalysisType,
				overlayChartType,
				overlayColumnProps,
				overlayDisplay,
				overlayFilterNames,
				overlayFilterSegment,
				overlayIndexType,
				overlayMainNames,
				overlayMainSegments,
				overlayYAxisType,
			})
		);
		dispatch(push(`/dashboard/${dashboardId}`));
		setTimeout(() => {
			dispatch(scroll(reportId));
			dispatch(clearOverlayFilters());
		}, 250);
	};
}

function textReportCleanUp(reportId) {
	return dispatch => {
		setTimeout(() => {
			dispatch(scroll(reportId, 0));
		}, 250);
	};
}

export function addDefaultTextReport(dashboardId, rowIndex) {
	return async (dispatch, getState) => {
		const { enterpriseId } = getState().account;

		if (!dashboardId) {
			return;
		}

		dispatch({ type: ActionTypes.SaveReportPending });

		const { interval, intervalCount, startDate, endDate } = getState().editor.filter;

		let report;

		const reportTitle = 'Default';

		return axios
			.post(`${Urls.DashboardsApi}dashboards/ids`, {
				enterpriseId,
				dashboardIds: [dashboardId],
			})
			.then(response => {
				const dashboard = response.data[0];
				dispatch({
					type: ActionTypes.SetDashboard,
					dashboard,
				});

				const newConfigs = [];

				const DEFAULT_ROW_HEIGHT = 200;

				const { reportRow, reportColumn } = getReportPosition(dashboard.reports, length);

				const dashboardRow = dashboard?.reports?.filter(
					r => r.configuration.find(c => c.name === 'rowIndex')?.value === rowIndex.toString()
				);

				const rowHeight =
					dashboardRow[0]?.configuration?.find(c => c.name === 'rowHeightPx')?.value || DEFAULT_ROW_HEIGHT;

				// consider zero indexing
				const columnIndex = dashboardRow.length || 0;

				newConfigs.push({
					name: 'rowIndex',
					value: rowIndex.toString(),
				});

				newConfigs.push({
					name: 'rowHeightPx',
					value: rowHeight.toString(),
				});

				newConfigs.push({
					name: 'columnIndex',
					value: columnIndex.toString(),
				});

				newConfigs.push({
					name: 'layoutVersion',
					value: 'v2',
				});

				report = {
					reportId: uuid.v4(),
					name: reportTitle,
					dateAdded: moment.utc().toISOString(),
					analysisName: 'Text',
					analysis: [],
					attributes: [],
					eventIds: [],
					configuration: [
						{
							name: 'chartType',
							value: ChartTypes.Text,
						},
						{
							name: 'contentState',
							value: '',
						},
						{
							name: 'textAlignment',
							value: 'flex-end',
						},
						{
							name: 'length',
							value: '6',
						},
						{
							name: 'row',
							value: reportRow.toString(),
						},
						{
							name: 'column',
							value: reportColumn.toString(),
						},
						{
							name: 'interval',
							value: interval,
						},
						...newConfigs,
					],
				};

				if (startDate) {
					report.configuration.push({
						name: 'startDate',
						value: startDate,
					});
					report.configuration.push({
						name: 'endDate',
						value: endDate,
					});
				} else {
					report.configuration.push({
						name: 'intervalCount',
						value: intervalCount.toString(),
					});
				}

				return axios.put(`${Urls.DashboardsApi}report`, {
					enterpriseId,
					dashboardId,
					report,
				});
			})
			.then(() => {
				const dashboard = getState().dashboards[dashboardId];
				const dashboardName = dashboard ? dashboard.name : '';
				track(MixPanel.Events.ReportEditorSaveNewReport, {
					'Dashboard Name': dashboardName,
					'Report Name': reportTitle,
				});
				dispatch({
					type: ActionTypes.SaveReportFulfilled,
					dashboardId,
					report,
					dashboardName,
				});
				dispatch(textReportCleanUp(report.reportId));
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveReportRejected,
				});
			});
	};
}

export function addInsightsTextReport(dashboardId) {
	return async (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		const { insights, reportIds, isSummary } = getState().dashboard.aiInsights;

		if (!dashboardId) {
			return;
		}

		dispatch({ type: ActionTypes.SaveReportPending });

		const { interval, intervalCount, startDate, endDate } = getState().editor.filter;

		let report;

		const dateAdded = moment.utc().toISOString();
		const reportTitle = 'Default InsightsText Report';

		return axios
			.post(`${Urls.DashboardsApi}dashboards/ids`, {
				enterpriseId,
				dashboardIds: [dashboardId],
			})
			.then(response => {
				const dashboard = response.data[0];
				dispatch({
					type: ActionTypes.SetDashboard,
					dashboard,
				});

				const newConfigs = [];

				const DEFAULT_ROW_HEIGHT = 500;

				const { reportRow, reportColumn } = getReportPosition(dashboard.reports, length);

				let rowIndex = 0;
				let dashboardRow;

				// if it's not a summary then find the max row index based on selected reports
				if (!isSummary) {
					reportIds.forEach(reportId => {
						let currentRowIndex = dashboard?.reports
							?.find(r => r.reportId === reportId)
							?.configuration?.find(c => c.name === 'rowIndex')?.value;

						if (!currentRowIndex) return;

						if (parseInt(currentRowIndex) > rowIndex) {
							rowIndex = parseInt(currentRowIndex);
						}
					});
				}

				dashboardRow = dashboard?.reports?.filter(
					r => r.configuration.find(c => c.name === 'rowIndex')?.value === rowIndex.toString()
				);

				if (dashboardRow.length > 3) {
					rowIndex++;
					dashboardRow = dashboard?.reports?.filter(
						r => r.configuration.find(c => c.name === 'rowIndex')?.value === rowIndex.toString()
					);
				}

				const rowHeight =
					dashboardRow[0]?.configuration?.find(c => c.name === 'rowHeightPx')?.value || DEFAULT_ROW_HEIGHT;

				// consider zero indexing
				const columnIndex = dashboardRow.length || 0;

				newConfigs.push({
					name: 'rowIndex',
					value: rowIndex.toString(),
				});

				newConfigs.push({
					name: 'rowHeightPx',
					value: rowHeight.toString(),
				});

				newConfigs.push({
					name: 'columnIndex',
					value: columnIndex.toString(),
				});

				newConfigs.push({
					name: 'layoutVersion',
					value: 'v2',
				});

				const plainTextRenderer = {
					heading(text) {
						return `<h3>${text}</h3><h3></h3>`;
					},
					list(body) {
						return `<span>${body}</span><h3></h3>`;
					},
					listitem(text) {
						return `<h3>${text}</h3>`;
					},
					strong(text) {
						return `<span>${text}</span>`;
					},
				};

				marked.use({ renderer: plainTextRenderer });

				const markdown = marked.parse(insights);

				report = {
					reportId: uuid.v4(),
					name: reportTitle,
					dateAdded: dateAdded,
					analysisName: 'Text',
					analysis: [],
					attributes: [],
					eventIds: [],
					configuration: [
						{
							name: 'chartType',
							value: ChartTypes.InsightsText,
						},
						{
							name: 'contentState',
							value: JSON.stringify(
								convertToRaw(ContentState.createFromBlockArray(convertFromHTML(markdown)))
							),
						},
						{
							name: 'textAlignment',
							value: 'flex-end',
						},
						{
							name: 'length',
							value: '6',
						},
						{
							name: 'row',
							value: reportRow.toString(),
						},
						{
							name: 'column',
							value: reportColumn.toString(),
						},

						{
							name: 'interval',
							value: interval,
						},
						{
							name: 'reportIds',
							value: JSON.stringify(reportIds),
						},
						...newConfigs,
					],
				};

				if (startDate) {
					report.configuration.push({
						name: 'startDate',
						value: startDate,
					});
					report.configuration.push({
						name: 'endDate',
						value: endDate,
					});
				} else {
					report.configuration.push({
						name: 'intervalCount',
						value: intervalCount.toString(),
					});
				}

				return axios.put(`${Urls.DashboardsApi}report`, {
					enterpriseId,
					dashboardId,
					report,
				});
			})
			.then(async () => {
				const dashboard = getState().dashboards[dashboardId];
				const dashboardName = dashboard ? dashboard.name : '';

				track(MixPanel.Events.ReportEditorSaveNewReport, {
					'Dashboard Name': dashboardName,
					'Report Name': reportTitle,
				});
				dispatch({
					type: ActionTypes.SaveReportFulfilled,
					dashboardId,
					report,
					dashboardName,
				});

				if (isSummary) {
					const dashboard = getState().dashboards[dashboardId];
					try {
						dispatch({
							type: ActionTypes.UpdateDashboardPending,
						});

						const updatedReports = dashboard.reports.map(r => {
							if (r.reportId === report.reportId) {
								return r;
							} else {
								return {
									...r,
									configuration: r.configuration.map(c => {
										if (c.name === 'rowIndex') {
											return {
												...c,
												value: (parseInt(c.value) + 1).toString(),
											};
										} else if (c.name === 'row') {
											return {
												...c,
												value: (parseInt(c.value) + 1).toString(),
											};
										} else {
											return c;
										}
									}),
								};
							}
						});

						const updatedDashboard = {
							...dashboard,
							reports: updatedReports,
							lastUpdatedAt: moment.utc().toISOString(),
						};

						await axios.put(`${Urls.DashboardsApi}dashboard`, {
							dashboard: updatedDashboard,
						});

						dispatch({
							type: ActionTypes.UpdateDashboardFulfilled,
							dashboard,
						});
					} catch (error) {
						errorHandler.report(error);
						console.log(error);
						dispatch({
							type: ActionTypes.UpdateDashboardRejected,
						});
					}
				}

				// clean up
				dispatch(resetAiInsights());
				const element = document.getElementById(`chart-${report.reportId}`);
				if (element) {
					element.scrollIntoView({
						block: 'center',
					});
				}
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveReportRejected,
				});
			});
	};
}

export function updateInsightsReport(dashboardId) {
	return async (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		const { insights, reportIds, regenerateReportId: reportId } = getState().dashboard.aiInsights;

		if (!dashboardId) {
			return;
		}

		dispatch({ type: ActionTypes.UpdateReportPending });

		let report;

		const dateAdded = moment.utc().toISOString();
		const reportTitle = 'Default InsightsText Report';

		return axios
			.post(`${Urls.DashboardsApi}dashboards/ids`, {
				enterpriseId,
				dashboardIds: [dashboardId],
			})
			.then(response => {
				const dashboard = response.data[0];
				dispatch({
					type: ActionTypes.SetDashboard,
					dashboard,
				});

				report = dashboard.reports.find(r => r.reportId === reportId);

				const plainTextRenderer = {
					heading(text) {
						return `<h3>${text}</h3><h3></h3>`;
					},
					list(body) {
						return `<span>${body}</span><h3></h3>`;
					},
					listitem(text) {
						return `<h3>${text}</h3>`;
					},
					strong(text) {
						return `<span>${text}</span>`;
					},
				};

				marked.use({ renderer: plainTextRenderer });

				const markdown = marked.parse(insights);

				report = {
					...report,
					dateAdded,
					configuration: [
						...report.configuration,
						{
							name: 'contentState',
							value: JSON.stringify(
								convertToRaw(ContentState.createFromBlockArray(convertFromHTML(markdown)))
							),
						},
						{
							name: 'reportIds',
							value: JSON.stringify(reportIds),
						},
					],
				};

				return axios.put(`${Urls.DashboardsApi}report`, {
					enterpriseId,
					dashboardId,
					report,
				});
			})
			.then(async () => {
				const dashboard = getState().dashboards[dashboardId];
				const dashboardName = dashboard ? dashboard.name : '';

				track(MixPanel.Events.ReportEditorSaveNewReport, {
					'Dashboard Name': dashboardName,
					'Report Name': reportTitle,
				});
				dispatch({
					type: ActionTypes.UpdateReportFulfilled,
					dashboardId,
					dashboard,
					reportId,
				});

				// clean up
				dispatch(resetAiInsights());
				const element = document.getElementById(`chart-${report.reportId}`);
				if (element) {
					element.scrollIntoView({
						block: 'center',
					});
				}
			})
			.catch(error => {
				console.log(error);
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateReportRejected,
				});
			});
	};
}

export function saveReport(title, options) {
	return (dispatch, getState) => {
		const { enterpriseId } = getState().account;

		const { dashboardId, reportId, rowIndex, editMode } = getState().explore.dashboardReport;

		if (!dashboardId || !editMode) {
			return;
		}

		const {
			analysisName,
			subAnalyses,
			mainSegments,
			filterSegment,
			breakdownSegment,
			interval,
			intervalCount,
			startDate,
			endDate,
			showSubtable,
			overlayAnalysisName,
			overlayAnalysisType,
			overlayChartType,
			overlayColumnProps,
			overlayDisplay,
			overlayFilterNames,
			overlayFilterSegment,
			overlayIndexType,
			overlayMainNames,
			overlayMainSegments,
			overlayYAxisType,
			overlayAnalysisDisplayName,
		} = getState().explore.filter;

		const { benchmark, eventIds, chartType, indexType, yAxisType, metricOverlay } = getState().explore.chart;
		const { subtableChartType } = getState().explore.subtable;
		let { date } = getState().explore.chart;
		if (date) {
			date = moment.utc(date, Constants.ApiDateFormat).toISOString();
		}

		const analysis = [];

		let params = {
			reportId,
			analysisName,
			subAnalyses,
			analysis,
			mainSegments,
			filterSegment,
			breakdownSegment,
			interval,
			intervalCount,
			startDate,
			endDate,
			benchmark,
			date,
			chartType,
			reportName: title,
			length: 1,
			eventIds,
			additionalConfig: options,
			yAxisType,
			indexType,
			showSubtable,
			subtableChartType,
			overlayAnalysisName,
			overlayAnalysisDisplayName,
			overlayAnalysisType,
			overlayChartType,
			overlayColumnProps,
			overlayDisplay,
			overlayFilterNames,
			overlayFilterSegment,
			overlayIndexType,
			overlayMainNames,
			overlayMainSegments,
			overlayYAxisType,
			metricOverlay,
		};

		if (reportId) {
			return dispatch(updateReport(dashboardId, reportId, params));
		}
		dispatch({ type: ActionTypes.SaveReportPending });

		let report;

		return axios
			.post(`${Urls.DashboardsApi}dashboards/ids`, {
				enterpriseId,
				dashboardIds: [dashboardId],
			})
			.then(response => {
				const dashboard = response.data[0];
				dispatch({
					type: ActionTypes.SetDashboard,
					dashboard,
				});

				const DEFAULT_ROW_HEIGHT = 500;

				if (rowIndex !== undefined && rowIndex !== null) {
					params = {
						...params,
						rowIndex,
					};
				}

				const dashboardRow = dashboard.reports.filter(
					r => r.configuration.find(c => c.name === 'rowIndex')?.value === rowIndex
				);

				// consider Text charts may have less height then acceptable
				const rowHeight = Math.max(
					dashboardRow[0]?.configuration?.find(c => c.name === 'rowHeightPx')?.value || DEFAULT_ROW_HEIGHT,
					DEFAULT_ROW_HEIGHT
				);

				// consider zero indexing
				const columnIndex = dashboardRow.length || 0;

				params = {
					...params,
					rowIndex,
					columnIndex,
					rowHeight,
				};

				report = getReportDef(params);

				return axios.put(`${Urls.DashboardsApi}report`, {
					enterpriseId,
					dashboardId,
					report,
				});
			})
			.then(() => {
				const dashboard = getState().dashboards[dashboardId];
				const dashboardName = dashboard ? dashboard.name : '';
				track(MixPanel.Events.ReportEditorSaveNewReport, {
					'Dashboard Name': dashboardName,
					'Report Name': title,
				});
				dispatch({
					type: ActionTypes.SaveReportFulfilled,
					dashboardId,
					report,
					dashboardName,
				});
				dispatch(cleanUp(report.reportId, dashboardId));
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.SaveReportRejected,
				});
			});
	};
}

function updateReport(dashboardId, reportId, params) {
	return async (dispatch, getState) => {
		const { enterpriseId } = getState().account;
		let dashboard = getState().dashboards[dashboardId];
		try {
			if (!dashboard) {
				const dashboards = await axios.post(`${Urls.DashboardsApi}dashboards/ids`, {
					enterpriseId,
					dashboardIds: [dashboardId],
				});

				dashboard = dashboards?.data?.[0];
			}
		} catch (err) {
			console.log(err);
			errorHandler.report(err);
			return;
		}

		if (!dashboard) {
			return;
		}

		dispatch({
			type: ActionTypes.UpdateReportPending,
		});

		const { reportName } = params;

		dashboard = produce(dashboard, draftState => {
			draftState.lastUpdatedAt = moment.utc().toISOString();
			const reportIndex = draftState.reports.findIndex(r => r.reportId === reportId);
			if (reportIndex !== -1) {
				const { configuration } = draftState.reports[reportIndex];
				const prevLength = getConfig(configuration, 'length');
				const { length } = params;
				const rowIndex = getConfig(configuration, 'rowIndex');
				const columnIndex = getConfig(configuration, 'columnIndex');
				const rowHeight = getConfig(configuration, 'rowHeightPx');
				const columnWidthPerc = getConfig(configuration, 'columnWidthPerc');

				const report = getReportDef({
					...params,
					rowIndex,
					columnIndex,
					rowHeight,
					columnWidthPerc,
				});

				draftState.reports[reportIndex] = report;

				if (length > prevLength) {
					let rowConfig = report.configuration.find(c => c.name === 'row');
					let columnConfig = report.configuration.find(c => c.name === 'column');
					if (parseInt(columnConfig.value) + length - 1 > GridConstants.ColumnCount) {
						rowConfig.value = (parseInt(rowConfig.value) + 1).toString();
						columnConfig.value = '1';
					}

					const prevReportRow = parseInt(rowConfig.value);
					const prevReportColumn = parseInt(columnConfig.value);
					const prevReportPosition = (prevReportRow - 1) * GridConstants.ColumnCount + prevReportColumn;
					let expectedNextReportPosition = prevReportPosition + length;
					for (let i = reportIndex + 1; i < draftState.reports.length; i++) {
						const nextReport = draftState.reports[i];
						const nextReportRowConfig = nextReport.configuration.find(c => c.name === 'row');
						let nextReportRow = parseInt(nextReportRowConfig.value);
						const nextReportColumnConfig = nextReport.configuration.find(c => c.name === 'column');
						let nextReportColumn = parseInt(nextReportColumnConfig.value);
						const nextReportPosition = (nextReportRow - 1) * GridConstants.ColumnCount + nextReportColumn;
						if (nextReportPosition < expectedNextReportPosition) {
							nextReportColumn = expectedNextReportPosition % GridConstants.ColumnCount;
							nextReportRow = expectedNextReportPosition / GridConstants.ColumnCount + 1;
							const nextReportLength = parseInt(
								nextReport.configuration.find(c => c.name === 'length').value
							);
							if (nextReportColumn + nextReportLength - 1 > GridConstants.ColumnCount) {
								nextReportRow += 1;
								nextReportColumn = 1;
								expectedNextReportPosition =
									(nextReportRow - 1) * GridConstants.ColumnCount + nextReportColumn;
							}
							nextReportRowConfig.value = nextReportRow.toString();
							nextReportColumnConfig.value = nextReportColumn.toString();
							expectedNextReportPosition += nextReportLength;
						} else {
							break;
						}
					}
				}
			}
		});

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, { dashboard })
			.then(() => {
				track(MixPanel.Events.ReportEditorSaveExistingReport, {
					'Dashboard Name': dashboard.name,
					'Report Name': reportName,
				});
				dispatch({
					type: ActionTypes.UpdateReportFulfilled,
					dashboardId,
					dashboard,
					reportId,
				});

				dispatch(cleanUp(reportId, dashboardId));
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateReportRejected,
				});
			});
	};
}

export function saveText(dashboardId, reportId, title, newContentState, newTextAlignment) {
	return (dispatch, getState) => {
		let dashboard = getState().dashboards[dashboardId];

		if (!dashboard) return;

		dispatch({
			type: ActionTypes.UpdateDashboardPending,
		});

		const updatedDashboard = {
			...dashboard,
			lastUpdatedAt: moment.utc().toISOString(),
			reports: dashboard.reports.map(report => {
				if (report.reportId === reportId) {
					return {
						...report,
						configuration: report.configuration.map(config => {
							if (config.name === 'contentState') {
								return {
									...config,
									value: newContentState,
								};
							} else if (config.name === 'textAlignment') {
								return {
									...config,
									value: newTextAlignment,
								};
							}
							return config;
						}),
					};
				}
				return report;
			}),
		};

		return axios
			.put(`${Urls.DashboardsApi}dashboard`, {
				dashboard: updatedDashboard,
			})
			.then(() => {
				track(MixPanel.Events.ReportEditorSaveExistingReport, {
					'Dashboard Name': dashboard.name,
					'Report Name': title,
				});
				dispatch({
					type: ActionTypes.UpdateDashboardFulfilled,
					dashboardId,
					dashboard: updatedDashboard,
					reportId,
					hideMessage: true,
				});
			})
			.catch(error => {
				errorHandler.report(error);
				dispatch({
					type: ActionTypes.UpdateDashboardRejected,
				});
			});
	};
}
